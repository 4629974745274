import { useOrganization } from "@/context/OrganizationContext";
import Checkbox from "@/ui/Checkbox";
import LoadingSpin from "@/ui/LoadingSpin";
import Modal from "@/ui/Modal";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import InViewTrigger from "../InViewTrigger";

type Props = {
  proofs: RouterOutputs["invoices"]["invoiceById"]["proofs"];
  adding: boolean;
  setAdding: (value: boolean) => void;
  selected: Record<string, boolean>;
  setSelected: Dispatch<SetStateAction<Record<string, boolean>>>;
  handleAttach: () => void;
  handleDelete: (id: string) => void;
  isDeleting: boolean;
};

const AttachTransactions: FC<Props> = ({
  proofs,
  adding,
  setAdding,
  selected,
  setSelected,
  handleAttach,
  handleDelete,
  isDeleting,
}) => {
  const { organizationId = "" } = useOrganization();

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    fetchNextPage,
  } = api.transactions.transactions.useInfiniteQuery(
    {
      organizationId,
      filters: null,
      period: null,
      sorting: { date: "desc" },
    },
    { getNextPageParam: (p) => p.nextCursor }
  );

  const transactions = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  const attached = useMemo(
    () =>
      proofs
        .filter((p) => p.transaction)
        .map((p) => ({ ...p.transaction!, proofId: p.id })),
    [proofs]
  );

  const attachedSet = useMemo(
    () => new Set(attached.map((t) => t.id)),
    [attached]
  );

  const canSave = useMemo(() => Object.keys(selected).length > 0, [selected]);

  return (
    <div>
      <table className="table">
        <thead className="sticky top-0 z-10">
          <tr className="text-base-content bg-neutral">
            <th>Date & Description</th>
            <th>Amount</th>
          </tr>
        </thead>

        <tbody>
          {attached.map((t) => {
            const isSent = t.amount < 0;
            return (
              <tr
                key={t.id}
                className="btn table-row text-start slide-width_parent"
              >
                <td className="text-left w-full">
                  <p>{t.description}</p>
                  <p className="text-xs font-normal">
                    {format(t.date, "dd MMM yyyy' at' h:mm aaa")}
                  </p>
                </td>

                <td
                  className={`text-base flex items-center gap-3     ${
                    t.amount < 0 ? "text-error" : "text-success"
                  }`}
                >
                  {isSent ? <BsArrowUpRight /> : <BsArrowDownLeft />}
                  {formatCurrency(t.amount, t.currency)}
                  <div className="slide-width_item">
                    <button
                      onClick={() => handleDelete(t.proofId)}
                      className="btn-sm btn btn-outline btn-error"
                    >
                      <LoadingSpin loading={isDeleting} />
                      <MdDelete />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLoading ? (
        <Rows count={2} />
      ) : (
        !attached.length && (
          <p className="label label-text-alt w-full justify-center bg-base-200">
            No transaction attached
          </p>
        )
      )}
      <Modal isOpen={adding} onClose={setAdding}>
        <div className="h-[80vh] overflow-hidden ">
          <p className="label ">Attach Transaction</p>
          <div className="flex-1 overflow-auto">
            <table className="table">
              <thead className="sticky top-0 z-10">
                <tr className="text-base-content bg-base-300">
                  <th></th>
                  <th>Merchant & date</th>
                  <th>Bank & account</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                {transactions.map((t) => {
                  const isSent = t.amount < 0;
                  if (attachedSet.has(t.id)) return null;
                  return (
                    <tr
                      key={t.id}
                      className={`btn table-row text-start btn-neutral `}
                      onClick={() => {
                        setSelected((p) => {
                          if (p[t.id]) {
                            const { [t.id]: _, ...rest } = p;
                            return rest;
                          }
                          return { ...p, [t.id]: true };
                        });
                      }}
                    >
                      <th>
                        <Checkbox
                          checked={!!selected[t.id]}
                          onChange={() => {}}
                        />
                      </th>
                      <td className="text-left">
                        {t.merchant?.name || "N/A"}
                        <p className="text-xs font-normal">
                          {format(t.date, "dd MMM yyyy' at' h:mm aaa")}
                        </p>
                      </td>

                      <td>
                        {t.bankAccount.name}
                        <br />
                        <span className="text-xs">
                          ({t.bankAccount.bank.name})
                        </span>
                      </td>

                      <td
                        className={`text-base flex items-center gap-1      ${
                          t.amount < 0 ? "text-error" : "text-success"
                        }`}
                      >
                        {isSent ? <BsArrowUpRight /> : <BsArrowDownLeft />}
                        {formatCurrency(t.amount, t.currency)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading && <Rows />}

            {isLoading ? null : (
              <InViewTrigger
                onInView={fetchNextPage}
                children={<LoadingSpin loading={isFetching} />}
              />
            )}
          </div>
          {canSave && (
            <button
              className="btn sticky bottom-1 btn-sm btn-primary w-full"
              onClick={handleAttach}
            >
              Add
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AttachTransactions;
