import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { TaxCodeType } from "@/pages/organization/taxCode/TaxCodeList";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";

type Props = {
  transactionId: string;
  taxCode: TaxCodeType | null;
  cardId?: string;
  amount: number;
  currency: string;
  showLabel?: boolean;
};

const AssignTaxCode: FC<Props> = ({
  transactionId,
  taxCode,
  cardId,
  showLabel,
}) => {
  const [enabled, setEnabled] = useState(false);
  const { organizationId, organization } = useOrganization<true>();

  const { data = [], isLoading } = api.organizations.taxCode.list.useQuery(
    organizationId,
    { enabled }
  );

  const add = api.swan.transfers.taxCode.add.useMutation();
  const remove = api.swan.transfers.taxCode.remove.useMutation();
  const utils = api.useUtils();

  const handleAdd = (taxCodeId: string) => async () => {
    const { taxCode } = await add.mutateAsync({ taxCodeId, transactionId });

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id !== transactionId) return t;
              return { ...t, taxCode };
            }),
          })),
        };
      });

    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) => {
          if (t.id !== transactionId) return t;
          return { ...t, taxCode };
        });
      });
  };

  const handleRemove = async () => {
    await remove.mutateAsync(transactionId);

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id !== transactionId) return t;
              return { ...t, taxCode: null };
            }),
          })),
        };
      });
    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) => {
          if (t.id !== transactionId) return t;
          return { ...t, taxCode: null };
        });
      });
  };

  const loading = add.isLoading || remove.isLoading;

  return (
    <div>
      {showLabel && (
        <label className="text-sm divider divider-start">Tax Code</label>
      )}
      <Dropdown
        onClick={(e) => {
          e.stopPropagation();
          setEnabled(true);
        }}
        dropdownClass="w-full min-w-max z-10 "
        buttonClass="bg-base-300"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : taxCode ? (
            <span>
              {taxCode.name} ({taxCode.rate}%)
            </span>
          ) : (
            "N/A"
          )
        }
        footer={
          taxCode && (
            <button
              onClick={handleRemove}
              className="btn btn-error btn-sm w-full text-xs"
            >
              Remove ({taxCode.name})
            </button>
          )
        }
        content={
          isLoading
            ? ["Loading..."]
            : data.map((t) => (
                <button
                  onClick={handleAdd(t.id)}
                  onTouchEnd={handleAdd(t.id)}
                  key={t.id}
                >
                  {t.name} ({t.rate}%)
                </button>
              ))
        }
      />
    </div>
  );
};

export default AssignTaxCode;
