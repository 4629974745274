import { copyToClipboard } from "@/utils/helper";
import { FC, ReactNode, useState } from "react";
import { LuCheckCircle } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";

type Props = {
  hideIcon?: boolean;
  label: string;
  value: string;
  text: ReactNode;
  className?: string;
};

const CopyToClipboard: FC<Props> = (props) => {
  const [copied, setCopied] = useState(false);
  const { label, text, value, className = "", hideIcon } = props;

  return (
    <button
      className={`btn ${className}`}
      onClick={() => {
        copyToClipboard(value, label);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    >
      {!hideIcon && (
        <label className="swap swap-rotate">
          <input type="checkbox" checked={copied} />

          <MdContentCopy className="swap-off current" />
          <LuCheckCircle
            size={copied ? 20 : undefined}
            className="swap-on text-success"
          />
        </label>
      )}
      {text}
    </button>
  );
};

export default CopyToClipboard;
