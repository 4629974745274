import Checkbox from "@/ui/Checkbox";
import { Dispatch, FC, SetStateAction } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaSort } from "react-icons/fa";

const SortIcon = {
  asc: <BsSortUp size={16} />,
  desc: <BsSortDown size={16} />,
  undefined: <FaSort size={16} />,
} as const;

export type Sorting = {
  total?: "asc" | "desc";
  createdAt?: "asc" | "desc";
  date?: "asc" | "desc";
};

type Props = {
  setSorting: Dispatch<SetStateAction<Sorting>>;
  sorting: Sorting;
  data: any[];
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selectedArray: any[];
};

const ExpenseHeaders: FC<Props> = ({
  setSorting,
  sorting,
  data,
  setSelection,
  selectedArray,
}) => {
  const handleSorting = (key: keyof Sorting) => () => {
    setSorting((p) => ({
      [key]: p[key] === "asc" ? "desc" : p[key] === "desc" ? "asc" : "desc",
    }));
  };

  return (
    <thead>
      <tr>
        <th className="md:hidden"></th>
        <th className=" hidden md:table-cell">
          <Checkbox
            {...{
              checked: data.length
                ? selectedArray.length >= data.length
                : false,
              onChange: (e) => {
                if (!e) setSelection({});
                else {
                  setSelection(
                    data.reduce(
                      (acc, curr) => ({ ...acc, [curr.id]: curr }),
                      {}
                    )
                  );
                }
              },
            }}
          />
        </th>
        <th>Staff</th>
        <th className="text-center">Event</th>
        <th>
          <button
            className="flex w-full text-right justify-end text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("total")}
          >
            <p>Total</p>
            {SortIcon[sorting.total!]}
          </button>
        </th>
        <th>
          <button
            className="flex justify-end w-max text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("createdAt")}
          >
            <p>Created At</p>
            {SortIcon[sorting.createdAt!]}
          </button>
        </th>
        <th>
          <button
            className="flex w-max text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("date")}
          >
            <p>Date</p>
            {SortIcon[sorting.date!]}
          </button>
        </th>
        <th></th>
      </tr>
    </thead>
  );
};

export default ExpenseHeaders;
