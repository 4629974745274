import InViewTrigger from "@/components/InViewTrigger";
import { useOrganization } from "@/context/OrganizationContext";
import { accountingStatuses } from "@/lib";
import Checkbox from "@/ui/Checkbox";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency, formatLabel, saveFile } from "@/utils/helper";
import { api, RouterOutputs } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { json2csv } from "json-2-csv";
import { FC, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { HiDownload } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import TransactionRow from "./TransactionRow";

type Props = {
  showAccountingOverview?: boolean;
};

export type SwanTransaction =
  RouterOutputs["swan"]["transfers"]["list"]["data"][0];

const TransactionList: FC<Props> = ({ showAccountingOverview }) => {
  const { organizationId, organization } = useOrganization<true>();
  const [selection, setSelection] = useState<Record<string, SwanTransaction>>(
    {}
  );

  const { data: overview } = api.swan.transfers.accountingOverview.useQuery(
    organizationId,
    { enabled: showAccountingOverview }
  );

  const {
    data = { pages: [] },
    isLoading,
    refetch,
    isRefetching,
    fetchNextPage,
    isFetching,
  } = api.swan.transfers.list.useInfiniteQuery(
    { organizationId },
    { getNextPageParam: (p) => p.nextCursor }
  );

  const transactions = useMemo(() => data.pages.flatMap((p) => p.data), [data]);

  const accountingOverview = useMemo(() => {
    if (!overview) return [];
    return accountingStatuses
      .map((s) => ({ status: s, count: 0, amount: 0, ...overview[s] }))
      .sort((a, b) => b.count - a.count);
  }, [overview]);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  const selectionArray = useMemo(() => Object.values(selection), [selection]);

  const handleDownload = async () => {
    if (!selectionArray.length) return;

    const csv = json2csv(selectionArray);
    const blob = new Blob([csv], { type: "text/csv" });

    saveFile(
      blob,
      `Dynt_transactions(${selectionArray.length})_${format(new Date(), "dd-MM-yy")}.csv`
    );
  };

  const isAllSelected = selectionArray.length === transactions.length;

  if (isLoading) return <Rows count={4} />;

  return (
    <div className="grid gap-4">
      <div className="flex items-end justify-between ">
        <div className="flex gap-4">
          {showAccountingOverview &&
            accountingOverview.map((s) => (
              <button className={`badge badge-secondary   badge-m text-xs `}>
                <p className="capitalize flex items-center gap-2">
                  {formatLabel(s.status)}
                  <p>{s.count}</p>
                  <span className="text-xs">
                    ({formatCurrency(s.amount, organization.defaultCurrency)})
                  </span>
                </p>
              </button>
            ))}
          {!!selectionArray.length && (
            <button className="flex text-primary text-sm px-4 gap-4">
              <Checkbox
                checked={isAllSelected}
                onChange={() => {
                  if (isAllSelected) return setSelection({});
                  setSelection(
                    Object.fromEntries(transactions.map((t) => [t.id, t]))
                  );
                }}
              />
              Select all
            </button>
          )}
        </div>
        <div className="flex gap-2">
          <button
            className="btn btn-sm btn-primary"
            onClick={handleDownload}
            disabled={!selectionArray.length}
          >
            <HiDownload />
          </button>
          <button
            className="btn btn-secondary justify-self-end btn-outline btn-sm"
            onClick={handleRefetch}
          >
            <RiRefreshLine
              size={20}
              className={isRefetching ? "animate-spin" : ""}
            />
          </button>
        </div>
      </div>

      <table className="table bg-base-200">
        <thead>
          <tr>
            <th></th>
            <th>Transaction</th>
            <th>Type</th>
            <th className="text-right">Amount</th>
            <th className="text-center">Date</th>
            <th className="text-center">Status</th>
            {showAccountingOverview && (
              <th className="text-center">Accounting Status</th>
            )}
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction, i, { length }) => (
            <TransactionRow
              key={transaction.id}
              {...{
                isSelected: !!selection[transaction.id],
                setSelection,
                transaction,
                showAccountingOverview,
                style: { zIndex: length - i },
              }}
            />
          ))}
        </tbody>
      </table>
      <InViewTrigger
        onInView={fetchNextPage}
        children={<LoadingSpin loading={isFetching} />}
      />
    </div>
  );
};

export default TransactionList;
