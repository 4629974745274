import LoadingSpin from "@/ui/LoadingSpin";
import {
  fileToBase64,
  formatCurrency,
  formatDataRows,
  formatFileSize,
  logError,
  promiseHandler,
} from "@/utils/helper";
import useForm from "@/utils/useForm";
import { createId } from "@paralleldrive/cuid2";
import addDays from "date-fns/addDays";

import DataRow, { DataRowType } from "@/components/DataRow";
import FileInput from "@/components/FileInput";
import SelectDate from "@/ui/SelectDate";
import { uploadFile } from "@/utils/supabase";
import { api } from "@/utils/trpc";
import { useMutation } from "@tanstack/react-query";
import { FC, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { BsMagic } from "react-icons/bs";

export type CreateExpenseRow = {
  amount: number;
  vat: number;
  description: string | null;
  subTotal: number;
  quantity: number;
  id: string;
};

export type CreateExpenseProps = {
  rows: CreateExpenseRow[];
  customId?: string | null;
  date: Date;
  currency: string;
  files: { name: string; mimeType: string; size: string; link: string }[];
};

const defaultRow: DataRowType = {
  amount: "",
  description: null,
  vat: "",
  quantity: 1,
};

type Props = {
  handleCreate: (arg: CreateExpenseProps) => Promise<void>;
  prevRows?: Record<string, DataRowType>;
  data?: {
    date: Date;
    customId?: string | null;
    currency: string;
  };
};

const ExpenseDetails: FC<Props> = ({
  handleCreate,
  prevRows = { [createId()]: defaultRow },
  data = {
    date: addDays(new Date(), 30),
    customId: null,
    currency: "EUR",
    terms: null as number | null,
  },
}) => {
  const [rows, setRows] = useState<Record<string, DataRowType>>(prevRows);
  const [loading, setLoading] = useState(false);

  const upload = useMutation(uploadFile);
  const ocr = api.utils.OCR.scan.useMutation();

  const { inputs, handleChange, setValue, setInputs } = useForm(data);
  const [confidenceLevel, setConfidenceLevel] = useState<
    Partial<Record<keyof typeof data, number>>
  >({});

  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const [files, setFiles] = useState<File[]>([]);

  const rowsArray = useMemo(() => Object.entries(rows), [rows]);

  const { subTotal, totalVat } = useMemo(() => {
    return rowsArray.reduce(
      (acc, el) => {
        const [_, { amount, vat, quantity }] = el;
        acc.subTotal += +amount * quantity;
        acc.totalVat += +amount * (+vat / 100) * quantity;

        return acc;
      },
      { totalVat: 0, subTotal: 0 }
    );
  }, [rowsArray]);

  const handleSubmit = async () => {
    const { customId, date, currency } = inputs;

    const { errors, formattedRows } = formatDataRows(rowsArray);

    if (!date) errors.dueDate = "error";
    if (!currency) errors.currency = "error";

    if (Object.keys(errors).length) {
      toast.error("Please fill all the required fields");
      return setErrors(errors);
    }

    setLoading(true);

    const promises = files.map(async (file) => {
      const link = await upload.mutateAsync(file);

      return {
        link,
        name: file.name,
        mimeType: file.type,
        size: formatFileSize(file.size),
      };
    });

    const documents = await Promise.all(promises);

    await handleCreate({
      rows: formattedRows,
      customId: customId || null,
      date,
      files: documents,
      currency,
    }).catch((e) => {
      toast("Something went wrong, please try again");
      logError(e);
    });
    setLoading(false);
  };

  const handleMagicUpload = async (file?: File) => {
    if (!file) return;
    setFiles((p) => [...p, file]);

    const base64 = await fileToBase64(file);
    const data = await ocr.mutateAsync(base64);

    const { totalAmount, currency, customId, date, items } = data;

    setInputs((p) => ({
      ...p,
      currency,
      customId,
      date: new Date(date),
    }));

    setRows(
      Object.fromEntries(
        items.map((el) => [
          createId(),
          {
            amount: el.unitPrice.toString(),
            description: el.description,
            quantity: el.quantity,
            vat: "0",
          },
        ])
      )
    );

    toast.success("We have automatically filled the details for you!");
  };

  return (
    <div
      className={`w-full my-4 grid content-start gap-5 ${
        loading ? "pointer-events-none opacity-70" : ""
      } `}
    >
      <div className="flex items-center gap-2">
        <p className="label label-text">Choose Expense details or</p>
        <button className="btn btn-primary">
          <input
            onChange={(e) =>
              promiseHandler(handleMagicUpload, e.target.files?.[0])
            }
            type="file"
            className="opacity-0 w-full h-full z-10 cursor-pointer absolute"
          />
          <LoadingSpin loading={ocr.isLoading} />
          <BsMagic /> Magic Upload
        </button>
      </div>

      <div className="flex items-center gap-1">
        <p className="label label-text">Choose Currency:</p>
        <select
          value={inputs.currency || ""}
          className={`select select-bordered ${
            errors.currency ? "select-error" : ""
          } `}
          onChange={(e) => setValue("currency", e.target.value)}
        >
          <option value="" disabled>
            Select currency
          </option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
        </select>
      </div>

      <DataRow
        {...{
          rows: rowsArray,
          setRows,
          errors,
          setErrors,
          currency: inputs.currency,
        }}
      />

      <div className="flex md:flex-row flex-col gap-5">
        <div className="flex-1">
          <p className="label label-text">Date & Time</p>
          {!!confidenceLevel.date && (
            <span className="btn btn-secondary absolute btn-xs top-5 z-20 rounded-full -right-2">
              confidence: {confidenceLevel.date}%
            </span>
          )}
          <div
            className={`w-full rounded-lg h-13 relative ${
              errors.date ? "border border-error" : ""
            }`}
          >
            <SelectDate
              showFutureDates
              value={{ startDate: inputs.date, endDate: inputs.date }}
              onChange={(v) => {
                if (!v?.startDate) return;
                setValue("date", new Date(v.startDate));
              }}
              asSingle
            />
          </div>
        </div>
        <div className="flex-1 w-full ">
          <p className="label label-text">Custom identifier</p>
          {!!confidenceLevel.customId && (
            <span className="btn btn-secondary absolute btn-xs top-5 z-20 rounded-full -right-2">
              confidence: {confidenceLevel.customId}%
            </span>
          )}
          <input
            type="text"
            className="flex-1 input input-bordered w-full"
            placeholder="e.g. 1234/2021"
            onChange={handleChange("customId")}
            value={inputs.customId || ""}
            name="customId"
          />
        </div>
      </div>
      <div>
        <p className="label label-text">Upload Expense</p>
        <FileInput setFiles={setFiles} files={files} />
      </div>

      <div className="flex gap-20 ml-auto">
        <div>
          <p className="label label-text">Subtotal:</p>
          <p className="label label-text">Vat:</p>
          <p className="label label-text">Total:</p>
        </div>
        <div>
          <p className="label label-text">
            {formatCurrency(subTotal, inputs.currency)}
          </p>
          <p className="label label-text">
            {formatCurrency(totalVat, inputs.currency)}
          </p>
          <p className="label label-text">
            {formatCurrency(totalVat + subTotal, inputs.currency)}
          </p>
        </div>
      </div>

      <button onClick={handleSubmit} className="btn btn-primary ml-auto w-52">
        Submit
        <LoadingSpin loading={loading} />
      </button>
    </div>
  );
};

export default ExpenseDetails;
