import DisplayName from "@/components/DisplayName";
import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { BaseType } from "@/types";
import LoadingSpin from "@/ui/LoadingSpin";
import { RouterInputs, RouterOutputs, api } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";

type Props = {
  merchant: BaseType | null;
  transactionId: string;
  bankAccountId: string;
  amount: number;
  params: RouterInputs["transactions"]["transactions"];
};

const EditMerchant: FC<Props> = ({
  transactionId,
  merchant,
  amount,
  params,
}) => {
  const { organizationId = "" } = useOrganization();
  const [merchantName, setMerchantName] = useState("");

  const { data = [] } = api.transactions.merchants.useQuery(organizationId);

  const upsert = api.transactions.upsertMerchantToTransaction.useMutation();
  const utils = api.useUtils();

  const handleUpdate = (
    res: RouterOutputs["transactions"]["upsertMerchantToTransaction"]
  ) => {
    utils.transactions.transactions.setInfiniteData(params, (p) => {
      if (!p) return p;
      return {
        ...p,
        pages: p.pages.map((page) => ({
          ...page,
          list: page.list.map((t) => {
            if (t.id === transactionId) return { ...t, ...res };
            return t;
          }),
        })),
      };
    });
    setMerchantName("");
  };

  const merchantsList = useMemo(() => {
    if (!merchantName) return data;

    return data.filter((m) =>
      m.name.toLowerCase().includes(merchantName.trim().toLowerCase())
    );
  }, [merchantName, data]);

  return (
    <Dropdown
      dropdownClass="w-full min-w-max"
      buttonClass="btn-neutral w-full min-w-max justify-between"
      title={
        upsert.isLoading ? (
          <LoadingSpin loading />
        ) : merchant ? (
          <DisplayName
            className="text-sm"
            text={merchant.name}
            maxLength={22}
          />
        ) : (
          <>+ Add merchant</>
        )
      }
      header={
        <div className="items-end bg-base-300 pb-3 w-full flex flex-1 justify-between border-b border-neutral rounded-b-none">
          <label htmlFor="" className="label-text flex-1 grid">
            Create new
            <input
              type="text"
              className="input flex-1 input-bordered 2xl:input-sm input-xs w-full placeholder:text-neutral-600"
              placeholder="Merchant name"
              value={merchantName}
              onChange={(e) => setMerchantName(e.target.value)}
            />
          </label>

          <button
            onClick={async () => {
              if (!merchantName) return;
              const res = await upsert.mutateAsync({
                transactionId,
                organizationId,
                merchantName,
                categoryId: null,
                subCategoryId: null,
              });
              handleUpdate(res);
            }}
            disabled={!merchantName}
            className="btn 2xl:btn-sm btn-xs btn-primary"
          >
            Create
          </button>
        </div>
      }
      content={merchantsList.map((m) => {
        if (m.id === merchant?.id) return null;
        return (
          <button
            onClick={async () => {
              const res = await upsert.mutateAsync({
                transactionId,
                organizationId,
                merchantId: m.id,
                categoryId: amount > 0 ? m.creditCategoryId : m.debitCategoryId,
                subCategoryId:
                  amount > 0 ? m.creditSubCategoryId : m.debitSubCategoryId,
              });

              handleUpdate(res);
            }}
            className="items-center justify-between w-full  flex"
          >
            <p className="flex-wrap 2xl:text-sm text-xs overflow-hidden  font-semibold">
              {m.name}
            </p>

            <button className="btn 2xl:btn-sm btn-xs btn-secondary">Add</button>
          </button>
        );
      })}
      footer={
        !!merchant && (
          <button
            onClick={async () => {
              const res = await upsert.mutateAsync({
                transactionId,
                organizationId,
                remove: true,
                categoryId: null,
                subCategoryId: null,
              });
              handleUpdate(res);
            }}
            className="btn btn-error w-full btn-sm"
          >
            <MdClose />
            Remove
          </button>
        )
      }
    />
  );
};

export default EditMerchant;
