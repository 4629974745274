import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { openFile, saveFile } from "@/utils/helper";
import { downloadFile } from "@/utils/supabase";
import { RouterOutputs, api } from "@/utils/trpc";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { FC, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { CiFileOn } from "react-icons/ci";
import { FiMoreVertical } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";
import EditFile from "./EditFile";

type Props = {
  file: RouterOutputs["organizations"]["files"]["list"][number];
  folderId: string | null;
};

const SingleFile: FC<Props> = ({ file, folderId }) => {
  const { organizationId = "" } = useOrganization();
  const download = useMutation(downloadFile);
  const deleteFile = api.organizations.deleteFile.useMutation();

  const [loading, setLoading] = useState<"download" | "view" | null>(null);

  const [editing, setEditing] = useState(false);

  const utils = api.useUtils();

  const handleView = async (e: any) => {
    e.stopPropagation();
    setLoading("view");
    const blob = await download.mutateAsync(file.link);

    const _file = new Blob([blob], { type: file.mimeType });
    setLoading(null);
    openFile(_file);
  };

  const handleDownload = async (e: any) => {
    e.stopPropagation();
    setLoading("download");
    const blob = await download.mutateAsync(file.link);

    const _file = new Blob([blob], { type: file.mimeType });

    setLoading(null);

    saveFile(_file, file.name);
  };

  const handleDelete = async (e: any) => {
    e.stopPropagation();

    await deleteFile.mutateAsync(file.id);

    utils.organizations.files.setInfiniteData(
      { organizationId, folderId },
      (p) => {
        if (!p) return;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            list: page.list.filter((d) => d.id !== file.id),
          })),
        };
      }
    );
  };
  return (
    <tr key={file.id} className="bg-base-100">
      <td>
        <div
          className="tooltip flex gap-2 items-center text-left tooltip-info"
          data-tip={file.description}
        >
          <CiFileOn />
          <p className="break-words break-all">
            {file.name}

            <span className="text-xs text-neutral-400 ml-2">({file.size})</span>
          </p>
        </div>
      </td>
      <td>{file.type}</td>
      <td>{format(file.createdAt, "dd MMM yyy")}</td>
      <th>
        <Dropdown
          title={<FiMoreVertical size={22} />}
          hideArrow
          buttonClass="btn-xs"
          dropdownClass="dropdown-left dropdown-bottom "
          listClass="w-44"
          content={[
            <button
              className="flex items-center gap-2  z-10 w-full"
              onClick={handleDownload}
            >
              <BsDownload className="text-primary" />
              <LoadingSpin loading={loading === "download"} />
              Download
            </button>,
            <button
              className="flex items-center gap-2  z-10 w-full"
              onClick={handleView}
            >
              <HiOutlineExternalLink className="text-secondary" />
              <LoadingSpin loading={loading === "view"} />
              View
            </button>,
            !file.readOnly && (
              <button
                className="flex items-center gap-2  z-10 w-full"
                onClick={handleDelete}
              >
                <MdDelete className="text-error" />
                <LoadingSpin loading={deleteFile.isLoading} />
                Delete
              </button>
            ),
            <button className="z-10" onClick={() => setEditing(true)}>
              <MdEdit className="text-accent" />
              Edit
            </button>,
          ]}
        />
      </th>

      <EditFile
        {...{
          file,
          folderId,
          editing,
          setEditing,
        }}
      />
    </tr>
  );
};

export default SingleFile;
