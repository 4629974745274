import { countries } from "@/lib/countriesWithBanks";
import {
  UpsertManualAccount,
  UpsertManualAccountType,
} from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { TOAST } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect, useRef, useState } from "react";
import { AiTwotoneBank } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";

type Props = { vendorId: string };

const currencies = ["USD", "EUR", "GBP"] as const;

const fields: {
  name: keyof UpsertManualAccountType;
  title: string;
  placeholder: string;
  currencies: string[];
}[] = [
  {
    name: "name",
    title: "Account Name",
    placeholder: "Enter Account name",
    currencies: ["USD", "EUR", "GBP"],
  },
  {
    name: "type",
    title: "Account Type",
    placeholder: "Enter Account Type",
    currencies: ["USD"],
  },
  {
    name: "bankAddress",
    title: "Bank Address",
    placeholder: "Enter Bank Address",
    currencies: ["USD", "EUR", "GBP"],
  },
  {
    name: "IBAN",
    title: "IBAN",
    placeholder: "Enter IBAN",
    currencies: ["EUR", "GBP"],
  },
  {
    name: "SWIFT",
    title: "SWIFT",
    placeholder: "Enter SWIFT",
    currencies: ["EUR"],
  },
  {
    name: "ACCOUNT_NUMBER",
    title: "Account Number",
    placeholder: "Enter Account Number",
    currencies: ["USD", "GBP"],
  },
  {
    name: "ROUTING_NUMBER",
    title: "Routing Number",
    placeholder: "Enter Routing Number",
    currencies: ["USD"],
  },
  {
    name: "SORT_CODE",
    title: "Sort Code",
    placeholder: "Enter Sort Code",
    currencies: ["GBP"],
  },
];

const VendorAccounts: FC<Props> = ({ vendorId }) => {
  const { data = [] } = api.manualAccounts.vendorAccounts.useQuery(vendorId);
  const upsert = api.manualAccounts.upsertVendorAccount.useMutation();
  const deleteAccount = api.manualAccounts.delete.useMutation();
  const utils = api.useUtils();
  const ref = useRef<HTMLDivElement>(null);

  const { handleChange, inputs, setValue, errors, setErrors, setInputs } =
    useForm<Partial<UpsertManualAccountType>>({ currency: "EUR" });
  const [editingAccount, setEditingAccount] = useState(false);

  const handleUpsert = async () => {
    const valid = UpsertManualAccount.safeParse(inputs);

    if (!valid.success) return setErrors(valid.error.formErrors.fieldErrors);

    const res = (await upsert.mutateAsync({
      ...valid.data,
      vendorId,
    })) as (typeof data)[number];

    utils.manualAccounts.vendorAccounts.setData(vendorId, (p) => {
      if (!p) return [res];
      return [res, ...p.filter((c) => c.id !== res.id)];
    });
    setEditingAccount(false);
  };

  useEffect(() => {
    if (editingAccount) return;
    setInputs({ currency: "EUR" });
  }, [editingAccount]);

  const handleDelete = async () => {
    if (!inputs.id) return;

    const _confirm = confirm(
      `Are you sure you want to delete the account: ${
        inputs.name || ""
      }? This action cannot be undone.`
    );

    if (!_confirm) return;

    await deleteAccount.mutateAsync(inputs.id);

    utils.manualAccounts.vendorAccounts.setData(vendorId, (p) => {
      if (!p) return [];
      return p.filter((c) => c.id !== inputs.id);
    });

    setEditingAccount(false);

    TOAST("Account deleted successfully", "success");

    await utils.manualAccounts.vendorAccounts.refetch(vendorId);
  };

  return (
    <div className=" border-t border-base-300 py-6">
      <div className="collapse border border-base-300 bg-base-200">
        <input
          type="checkbox"
          checked={editingAccount}
          onChange={(e) => setEditingAccount(e.target.checked)}
        />
        <div className="collapse-title flex justify-between font-medium">
          <p className="label">Accounts</p>
          <label className="btn btn-sm  btn-primary swap swap-rotate">
            <input
              type="checkbox"
              checked={editingAccount}
              onChange={(e) => setEditingAccount(e.target.checked)}
            />

            <HiPlus size={20} className="swap-off fill-current" />
            <MdClose size={20} className="swap-on fill-current" />
          </label>
        </div>
        <div className="grid gap-6 collapse-content">
          <div>
            <p className="label">Country</p>
            <select
              value={inputs.countryId || ""}
              className={`select select-bordered w-full ${
                errors.countryId ? "select-error" : ""
              } `}
              onChange={(e) => setValue("countryId", +e.target.value)}
            >
              <option disabled value={""}>
                Select country
              </option>
              {countries.map((c) => (
                <option value={c.id}>{c.name}</option>
              ))}
            </select>
          </div>
          <div>
            <p className="label">Currency</p>
            <div className="flex gap-2 items-center justify-between">
              {currencies.map((c) => (
                <button
                  onClick={() => {
                    setInputs({ currency: c });
                    setErrors({});
                  }}
                  className={`btn flex-1 btn-sm ${
                    inputs.currency === c ? "btn-primary" : ""
                  } `}
                >
                  {c}
                </button>
              ))}
            </div>
          </div>
          <div ref={ref}>
            {fields.map((f, i) => {
              if (!f.currencies.includes(inputs.currency!)) return null;
              return (
                <div key={f.name}>
                  <label className="label">
                    <span className="label-text">{f.title}</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    placeholder={f.placeholder}
                    className={`input  w-full shadow-sm ${
                      errors[f.name] ? "input-error" : "input-bordered"
                    }`}
                    value={inputs[f.name] || ""}
                    onChange={handleChange(f.name)}
                  />
                </div>
              );
            })}
          </div>
          <div className="grid gap-3">
            <button onClick={handleUpsert} className="btn btn-sm btn-primary">
              <LoadingSpin loading={upsert.isLoading} />
              Save Account
            </button>
            {!!inputs.id && (
              <button
                onClick={handleDelete}
                className="btn btn-sm btn-error btn-outline"
              >
                <LoadingSpin loading={deleteAccount.isLoading} />
                Delete Account
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="grid gap-1 my-4">
        {data.map((b) => (
          <button
            className="btn h-fit  w-full justify-between"
            key={b.id}
            onClick={() => {
              setInputs(b);
              setEditingAccount(true);
            }}
          >
            <AiTwotoneBank />
            <div className="flex-1 w-1/3 break-words text-left">
              <p className="lg:text-sm text-xs font-semibold">{b.name}</p>
              <p className="text-xs">
                {b.IBAN || b.ACCOUNT_NUMBER || b.ROUTING_NUMBER}
              </p>
            </div>

            <FaEdit />
          </button>
        ))}
      </div>
    </div>
  );
};

export default VendorAccounts;
