import LoadingSpin from "@/ui/LoadingSpin";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC, useState } from "react";
import { HiPlus } from "react-icons/hi";
import AttachDocuments from "./AttachDocuments";
import AttachTransactions from "./AttachTransactions";

type Props = {
  proofs: RouterOutputs["invoices"]["invoiceById"]["proofs"];
} & (
  | { invoiceId: string; billId?: never; expenseId?: never }
  | { billId: string; invoiceId?: never; expenseId?: never }
  | { expenseId: string; billId?: never; invoiceId?: never }
);

const AttachProof: FC<Props> = ({ proofs, billId, expenseId, invoiceId }) => {
  const [type, setType] = useState<"document" | "transaction">("document");
  const [adding, setAdding] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    Record<string, boolean>
  >({});
  const [selectedTransactions, setSelectedTransactions] = useState<
    Record<string, boolean>
  >({});

  const utils = api.useUtils();

  const attachToInvoice = api.invoices.attachProof.useMutation();
  const attachToBill = api.bills.attachProof.useMutation();
  const attachToExpense = api.expenses.attachProof.useMutation();

  const deleteProof = api.utils.deleteProof.useMutation();

  const handleAttach = async () => {
    setSelectedDocuments({});
    setSelectedTransactions({});
    setAdding(false);
    const documents = Object.keys(selectedDocuments).map((fileId) => ({
      fileId,
    }));

    const transactions = Object.keys(selectedTransactions).map(
      (transactionId) => ({ transactionId })
    );

    const proofs = [...documents, ...transactions];

    if (invoiceId) {
      const res = await attachToInvoice.mutateAsync({ proofs, invoiceId });

      utils.invoices.invoiceById.setData(invoiceId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }

    if (billId) {
      const res = await attachToBill.mutateAsync({ proofs, billId });

      utils.bills.billById.setData(billId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }

    if (expenseId) {
      const res = await attachToExpense.mutateAsync({ proofs, expenseId });

      utils.expenses.expenseById.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteProof.mutateAsync(id);

    if (invoiceId) {
      utils.invoices.invoiceById.setData(invoiceId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }

    if (billId) {
      utils.bills.billById.setData(billId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }

    if (expenseId) {
      utils.expenses.expenseById.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }
  };

  const content = {
    document: (
      <AttachDocuments
        {...{
          adding,
          setAdding,
          selected: selectedDocuments,
          setSelected: setSelectedDocuments,
          proofs,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    ),
    transaction: (
      <AttachTransactions
        {...{
          adding,
          setAdding,
          selected: selectedTransactions,
          setSelected: setSelectedTransactions,
          proofs,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    ),
  };

  const loading =
    attachToInvoice.isLoading ||
    attachToBill.isLoading ||
    attachToExpense.isLoading;
  return (
    <div className="flex-1  bg-base-100 border border-base-300  p-4 rounded-lg">
      <p className="label">Proofs</p>
      <div className="flex mb-6 items-center justify-between">
        <div className="tabs w-fit  p-1 tabs-boxed">
          <button
            className={`tab  ${type === "document" ? "tab-active" : ""}`}
            onClick={() => setType("document")}
          >
            Documents
          </button>
          <button
            className={`tab  ${type === "transaction" ? "tab-active" : ""}`}
            onClick={() => setType("transaction")}
          >
            Transactions
          </button>
        </div>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setAdding(true)}
        >
          <LoadingSpin loading={loading} />
          <HiPlus />
        </button>
      </div>

      {content[type]}
    </div>
  );
};

export default AttachProof;
