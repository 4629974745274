import CopyToClipboard from "@/components/CopyToClipboard";
import Tabs from "@/components/Tabs";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import toast from "react-hot-toast";
import { BiLinkExternal } from "react-icons/bi";
import { PiInfoBold } from "react-icons/pi";
import { useParams } from "react-router-dom";
import Account from "./Account";
import CardsList from "./cards/CardsList";
import KYCWarning from "./components/KYCWarning";
import MembersList from "./members/MembersList";

type Props = {};

const tabs = ["account", "members", "cards"] as const;

const badgeColor = {
  Finalized: "badge-success",
  Invalid: "badge-error",
  Valid: "badge-secondary",
};

type Tab = (typeof tabs)[number];

const Cards_EU = (props: Props) => {
  const { organizationId = "" } = useOrganization();

  const { tab = "account" } = useParams<{ tab?: Tab }>();

  const { data, isLoading } = api.swan.onboarding.get.useQuery(organizationId);
  const onboard = api.swan.onboarding.onboard.useMutation();

  if (isLoading) return <Rows count={3} />;

  const handleOnboard = async () => {
    const onboarding = await onboard.mutateAsync(organizationId);

    if (!onboarding) {
      return toast.error(
        "Failed to onboard, please check your organization details and try again"
      );
    }

    window.open(onboarding.onboardingUrl, "_blank");
  };

  if (!data)
    return (
      <>
        <KYCWarning />

        <div role="alert" className="alert">
          <PiInfoBold className="text-accent" />
          <span className="ml-2">
            Start to create your account today and issue cards!
          </span>

          <div>
            <p className="text-xs">
              By continuing, you agree to{" "}
              <a
                target="_blank"
                className="link link-primary"
                href="https://www.swan.io/terms-of-use"
              >
                Swan terms and agreement
              </a>
              .
            </p>
          </div>
          <button onClick={handleOnboard} className="btn btn-sm btn-primary">
            <LoadingSpin loading={onboard.isLoading} />
            Onboard
          </button>
        </div>
      </>
    );

  if (data.status !== "Finalized") {
    return (
      <>
        <KYCWarning />

        <div className="card w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">
              Swan Onboarding{" "}
              <span className={`badge ${badgeColor[data.status]} `}>
                {data.status}
              </span>{" "}
            </h2>
            <p>Please complete your Swan onboarding and finalize it.</p>
            <div className="card-actions justify-end">
              <a
                href={data.onboardingURL}
                target="_blank"
                className="btn btn-primary"
              >
                Complete <BiLinkExternal />
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!data.accountHolder?.account?.id) {
    return (
      <>
        <KYCWarning />

        <div className="card w-full bg-base-100 shadow-xl">
          <div className="card-body">
            <h2 className="card-title">
              Swan Onboarding{" "}
              <span className={`badge ${badgeColor[data.status]} `}>
                {data.status}
              </span>{" "}
            </h2>
            <p>
              There seems to be an issue with your onboarding account. Please
              contact us.
            </p>
            <div className="card-actions justify-end">
              <CopyToClipboard
                label="Onboarding ID"
                text="Copy Onboarding ID"
                value={data.id}
                className="btn-secondary btn-sm"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  const content: Record<Tab, JSX.Element> = {
    account: <Account />,
    members: <MembersList accountId={data.accountHolder.account.id} />,
    cards: <CardsList accountId={data.accountHolder.account.id} />,
  };

  return (
    <div>
      <KYCWarning />

      <Tabs
        className="mb-6 z-20 w-fit"
        tabs={tabs}
        tab={tab}
        paths={["/", "/members", "/cards"]}
        parentRoute="/pro-account"
        type="boxed"
        tabsClass="!hidden xl:!tabs"
        dropdownClass="xl:!hidden !block"
      />

      {content[tab]}
    </div>
  );
};

export default Cards_EU;
