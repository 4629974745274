import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { RouterInputs, api } from "@/utils/trpc";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import InViewTrigger from "@/components/InViewTrigger";
import Drawer from "@/components/drawer/Drawer";
import Rows from "@/ui/skeletons/Rows";
import { handleSelectFromList } from "@/utils/helper";
import { format } from "date-fns/esm";
import { toast } from "react-hot-toast";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DatePeriod } from "../../components/PaymentsWrapper";
import ConvertToExpense from "./ConvertToExpense";
import SingleTransaction, { type Transaction } from "./SingleTransaction";
import TransactionDetails from "./TransactionDetails";
import SelectTransactionFilters from "./TransactionFilters";
import TransactionHeader from "./TransactionHeader";

type Sorting = {
  amount?: "asc" | "desc";
  date?: "asc" | "desc";
};

export type TransactionFilter =
  RouterInputs["transactions"]["transactions"]["filters"];

type Props = {
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
};

const Transactions: FC<Props> = ({ selection, setSelection, period }) => {
  const { organizationId = "" } = useOrganization();

  const [param] = useSearchParams();
  const showFilters = param.has("filters");
  const nav = useNavigate();

  const [sorting, setSorting] = useState<Sorting>({
    date: "desc",
  });

  const [filters, setFilters] = useState<TransactionFilter | null>(null);

  const {
    data: banks = [],
    refetch: refetchBanks,
    remove: removeBank,
  } = api.banks.banks.useQuery(organizationId, {
    enabled: !!organizationId,
  });

  const expiredBanks = useMemo(() => {
    return banks.filter((b) => b.isSyncError);
  }, []);

  const lastSyncedAt = useMemo(() => {
    return banks.reduce<Date | null>((acc, curr) => {
      if (!curr.lastSyncedAt) return acc;
      if (!acc) return curr.lastSyncedAt;
      return curr.lastSyncedAt > acc ? curr.lastSyncedAt : acc;
    }, null);
  }, [banks]);

  const params = {
    organizationId,
    sorting,
    filters,
    period,
  };

  const filterParams = {
    organizationId,
    filters,
    period,
  };

  const {
    data: filtersData = {
      total: 0,
      missingAttachments: 0,
      unCategorized: 0,
      ranges: { max: 0, min: 0 },
      hidden: 0,
      recurring: 0,
    },
  } = api.transactions.filtersData.useQuery(filterParams);

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    remove: removeTransaction,
    refetch: refetchTransactions,
    fetchNextPage,
  } = api.transactions.transactions.useInfiniteQuery(params, {
    getNextPageParam: (p) => p.nextCursor,
  });

  const transactions = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  const hasNextPage = transactions.length < filtersData.total;

  const sync = api.banks.sync.useMutation();
  const selectAll = api.transactions.selectAllTransactions.useMutation();

  const selectedArray = useMemo(
    () => Object.values(selection).map((a) => a.id),
    [selection]
  );

  const handleSelectAll = async () => {
    if (allSelected) return setSelection({});

    const res = await selectAll.mutateAsync({
      organizationId,
      sorting,
      filters,
      period,
    });
    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const handleSync = async () => {
    const res = await sync.mutateAsync(banks.map((b) => b.id));

    const unSyncedBanks = res.filter((r) => r.isSyncError);

    if (!unSyncedBanks.length) return toast.success("Synced successfully!");

    toast.error(
      `The following banks could not be synced, ${unSyncedBanks
        .map((b) => `${b.name} (${b.institution_name})`)
        .join(", ")}`
    );

    removeBank();
    removeTransaction();
    await refetchBanks();
    await refetchTransactions();
  };

  const fullListSelected = transactions.length
    ? selectedArray.length >= transactions.length
    : false;

  const hasMoreToSelect =
    fullListSelected && filtersData.total > selectedArray.length;

  const allSelected =
    filtersData.total > 0 && filtersData.total === selectedArray.length;

  const handleSelect = useCallback((t: (typeof transactions)[number]) => {
    setSelection(handleSelectFromList(t, transactions, selectedArray.length));
  }, []);

  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [convertingToExpense, setConvertingToExpense] = useState(false);

  return (
    <div id="s-4" className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={!!transaction}
        onClose={() => setTransaction(null)}
        title="Transaction"
        unMountOnClose
      >
        {transaction && (
          <TransactionDetails
            {...{ params, transaction, setConvertingToExpense, setTransaction }}
          />
        )}
      </Drawer>
      <Drawer
        content={
          transaction && <ConvertToExpense {...{ transaction: transaction }} />
        }
        isOpen={convertingToExpense}
        onClose={setConvertingToExpense}
        title="Convert to expense"
        unMountOnClose
      />

      <button
        onClick={handleSync}
        className="btn w-full ml-auto lg:w-fit btn-primary text-left text-xs"
      >
        <LoadingSpin loading={sync.isLoading} />
        <span className="">
          <span className="font-bold">Synchronize</span>
          <p>
            {!!lastSyncedAt && format(lastSyncedAt, "dd MMM yyyy' at' h:mm a")}
          </p>
        </span>

        {!!expiredBanks.length && (
          <div
            className={`tooltip z-10 before:w-60 tooltip-bottom before:-left-20 before:text-sm tooltip-warning before:whitespace-pre-wrap before:[--tw-content:'line1_\a_line2']`}
            data-tip={`These banks need your attention: \n ${expiredBanks
              .map((b) => `${b.name} (${b.institution_name})`)
              .join("\n")}`}
          >
            <span className="btn btn-sm btn-neutral">
              <TbAlertTriangleFilled className="text-warning" />
            </span>
          </div>
        )}
      </button>
      <div className="my-4">
        <button
          className={`btn btn-secondary btn-sm btn-outline ${
            hasMoreToSelect || allSelected ? "" : "hidden"
          } `}
          onClick={handleSelectAll}
        >
          <LoadingSpin loading={selectAll.isLoading} />
          {allSelected ? (
            <p>All {filtersData.total} transactions selected</p>
          ) : (
            <p>Select all {filtersData.total} transactions</p>
          )}
        </button>
      </div>
      <div>
        <table className="table table-zebra">
          <TransactionHeader
            {...{
              setSorting,
              sorting,
              data: transactions,
              setSelection,
              selectedArray,
            }}
          />

          <tbody>
            {transactions.map((t, i) => (
              <SingleTransaction
                key={t.id}
                {...{
                  transaction: t,
                  index: i,
                  isSelected: !!selection[t.id],
                  showHidden: !!filters?.showHidden,
                  handleSelect,
                  total: transactions.length,
                  setTransaction,
                }}
              />
            ))}
          </tbody>
        </table>
        {isLoading && <Rows count={10} />}
        {!isLoading && !transactions.length && <NotFound title="Transaction" />}

        {isLoading ? null : hasNextPage ? (
          <InViewTrigger
            onInView={fetchNextPage}
            children={<LoadingSpin loading={isFetching} />}
          />
        ) : (
          <p className="text-center text-sm my-4 text-gray-500">
            {transactions.length
              ? "No more transactions for the period selected"
              : ""}
          </p>
        )}
      </div>

      <Drawer
        isOpen={showFilters}
        onClose={() => nav("/transactions/banks")}
        title={"Filters"}
        content={
          <SelectTransactionFilters
            {...{
              setShowFilters: () => nav("/transactions/banks"),
              banks,
              showFilters,
              ...filtersData,
              setFilters,
            }}
          />
        }
      />
    </div>
  );
};

export default Transactions;
