import { AccountingStatusType, accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { FC } from "react";
import Dropdown from "../../../components/Dropdown";

type Props = {
  status: AccountingStatusType;
  transactionId: string;
  params: RouterInputs["transactions"]["transactions"];
};

const AccountingStatus: FC<Props> = ({ status, transactionId, params }) => {
  const update = api.transactions.updateAccountingStatus.useMutation();
  const utils = api.useUtils();

  const handleUpdate = (status: AccountingStatusType) => async () => {
    const res = await update.mutateAsync({ transactionId, status });

    utils.transactions.transactions.setInfiniteData(params, (p) => {
      if (!p) return p;
      return {
        ...p,
        pages: p.pages.map((page) => ({
          ...page,
          list: page.list.map((t) => {
            if (t.id === transactionId) return { ...t, ...res };
            return t;
          }),
        })),
      };
    });
  };
  return (
    <Dropdown
      dropdownClass="w-max min-w-full"
      buttonClass="capitalize"
      title={update.isLoading ? <LoadingSpin loading /> : formatLabel(status)}
      content={accountingStatuses.map((s) => (
        <button onClick={handleUpdate(s)} className="capitalize">
          {formatLabel(s)}{" "}
        </button>
      ))}
    />
  );
};

export default AccountingStatus;
