import { months } from "@/lib";
import { RouterOutputs } from "@/utils/trpc";
import { ChartDataCustomTypesPerDataset, ChartOptions } from "chart.js";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import NotFound from "../utils/notfound";

export const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    x: { grid: { display: false }, stacked: true },
    y: {
      type: "linear",
      display: true,
      stacked: true,
      position: "left",
      grid: { color: "rgb(45,54,70)" },
    },
    y1: {
      ticks: {
        callback(tickValue) {
          return tickValue + "%";
        },
      },
      type: "linear",
      display: true,
      position: "right",
      grid: { display: false },
    },
  },
};

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["categorization"];
};
const Categorization: FC<Props> = ({ data }) => {
  if (!data) return <NotFound title="Data" />;

  const barData: ChartDataCustomTypesPerDataset<"bar" | "line"> = {
    labels: months.map((m) => m.name),
    datasets: [
      {
        label: "Categorization percentage",
        data: months.map((m) => data[m.name]?.categorizationPercentage),
        backgroundColor: "orange",
        type: "line",
        yAxisID: "y1",
        borderColor: "orange",
      },
      {
        xAxisID: "x",
        yAxisID: "y",
        label: "Categorized",
        data: months.map((m) => data[m.name]?.categorized),
        type: "bar",
        backgroundColor: "cyan",
      },
      {
        backgroundColor: "lightgreen",
        label: "Transactions",
        data: months.map((m) => data[m.name]?.total),
        type: "bar",
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };

  return (
    <div className="w-full" style={{ height: "400px" }}>
      <Bar options={options as any} data={barData as any} />
    </div>
  );
};

export default Categorization;
