import PaymentsWrapper from "@/components/PaymentsWrapper";
import Tabs from "@/components/Tabs";
import { useParams } from "react-router-dom";
import TransactionList from "../swan/Transactions/TransactionList";

type Props = {};

const tabs = ["dynt", "banks"] as const;
type Tab = (typeof tabs)[number];

const Transactions = (props: Props) => {
  const { tab = "dynt" } = useParams<{ tab?: Tab }>();

  const content: Record<Tab, JSX.Element> = {
    dynt: <TransactionList />,
    banks: <PaymentsWrapper tab="transactions" />,
  };

  return (
    <div>
      <Tabs
        className="mb-6 z-20 w-fit"
        tabs={tabs}
        tab={tab}
        paths={["/dynt", "/banks"]}
        parentRoute="/transactions"
        type="boxed"
        tabsClass="!hidden xl:!tabs"
        dropdownClass="xl:!hidden !block"
      />

      {content[tab]}
    </div>
  );
};

export default Transactions;
