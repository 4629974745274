import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";

import Drawer from "@/components/drawer/Drawer";
import Spinner from "@/components/utils/spinner";
import ImageFromPath from "@/ui/ImageFromPath";
import { RouterOutputs, api } from "@/utils/trpc";
import format from "date-fns/format";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../../context/OrganizationContext";
import CustomerDetails from "./CustomerDetails";

export type Customer = RouterOutputs["partners"]["customersByOrgId"][number];

const Customers: React.FC = () => {
  const [customer, setCustomer] = useState<Customer | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const { organizationId: orgId = "" } = useOrganization();
  const nav = useNavigate();
  const { data = [], isLoading } = api.partners.customersByOrgId.useQuery(
    orgId,
    { enabled: !!orgId }
  );

  useEffect(() => {
    if (isOpen) return;
    setCustomer(null);
  }, [isOpen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _tab = queryParams.get("modal");

    if (_tab == "createCustomer") setIsOpen(true);
  }, [location.search]);

  return (
    <div className="my-6">
      <Drawer
        isOpen={isOpen}
        onClose={(e) => {
          setIsOpen(e);
          nav("/partners?tab=customers");
        }}
        title={customer?.name || "New Customer"}
        content={
          <CustomerDetails
            {...{
              customer,
              setIsOpen,
            }}
          />
        }
      />

      <div className="grid gap-1">
        {isLoading && <Spinner />}
        {data.map((c) => (
          <button
            key={c.id}
            onClick={() => {
              setCustomer(c);
              setIsOpen(true);
            }}
            className="btn"
          >
            <ImageFromPath
              className="w-10 aspect-square object-cover rounded-sm"
              path={c.photo}
            />
            <div className="flex-1 text-left">
              <div className="font-medium">{c.name}</div>
              <div className="text-xs md:text-sm   text-neutral-500">
                {c.email}
              </div>
            </div>
            <div className="flex-1 sm:block hidden">
              <div className="   text-xs md:text-sm   text-gray-500">
                Customer Since
              </div>
              <div className="  text-sm text-base-content font-medium">
                {format(new Date(c.createdAt), "MMM dd, yyyy")}
              </div>
            </div>
            <HiChevronRight size={22} className=" " />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Customers;
