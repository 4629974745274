import DisplayName from "@/components/DisplayName";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, formatId, handleSelectFromList } from "@/utils/helper";
import { RouterInputs, RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import AccountingStatus from "./AccountingStatus";

type Props = {
  list: RouterOutputs["bills"]["bills"];
  params: RouterInputs["bills"]["bills"];
  isLoading: boolean;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
};

const BillsListing: FC<Props> = ({
  list,

  selection,
  setSelection,
  totalSelected,
  params,
}) => {
  const nav = useNavigate();

  const view = (id: string) => () => nav(`/bill/${id}`);

  return (
    <tbody>
      {list.map((i) => (
        <tr key={i.id} className="hover cursor-pointer ">
          <th className="hidden md:table-cell">
            <Checkbox
              checked={!!selection[i.id]}
              onChange={() => {
                setSelection(handleSelectFromList(i, list, totalSelected));
              }}
              className="sm:block hidden -ml-1 mr-2"
            />
          </th>

          <td className="md:hidden" onClick={view(i.id)}>
            <div className=" flex text-center flex-col">
              <span className="text-sm font-semibold">
                {format(new Date(i.dueDate), "dd")}
              </span>
              <span className="text-sm text-gray-500  ">
                {format(new Date(i.dueDate), " MMM")}
              </span>
            </div>
          </td>
          <td onClick={view(i.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <DisplayName text={i.vendor.name} className="" />
              <p className="text-[12px]">{formatId(i.bill_number, "BILL")}</p>
            </div>
            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {i.status}
            </div>
          </td>
          <td onClick={view(i.id)}>
            <div className="w-full text-right pr-2">
              <p className="text-right">
                {formatCurrency(i.total, i.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(i.totalVat, i.currency)}/
                {formatCurrency(i.totalAmount, i.currency)}
              </p>
            </div>
          </td>
          <td onClick={view(i.id)}>
            <p className="flex md:flex-row text-right flex-col w-full justify-end pr-2 gap-1">
              {format(new Date(i.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(i.createdAt), "yyyy")}
              </span>
            </p>
          </td>
          <td onClick={view(i.id)}>
            <p className="flex md:flex-row flex-col  w-full text-center pr-2 gap-1">
              {format(new Date(i.dueDate), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(i.dueDate), "yyyy")}
              </span>
            </p>
          </td>
          <td>
            <AccountingStatus
              {...{ billId: i.id, status: i.accountingStatus, params }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default BillsListing;
