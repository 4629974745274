import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import AccountingCategories from "./AccountingCategories";
import Categories from "./RegularCategories";

type Props = {};

const CategoryContainer = (props: Props) => {
  const [showAccounting, setShowAccounting] = useState(false);

  const { organizationId = "", organization } = useOrganization();

  const { data: regularCategories = [], isLoading: rLoading } =
    api.organizations.categories.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const { data: accountingCategories = [], isLoading: aLoading } =
    api.accounting.categories.useQuery(organization?.accountingApp?.id || "", {
      enabled: !!organization?.accountingApp,
    });

  return (
    <div className="grid gap-6">
      <div className="flex items-center justify-between w-full">
        <Switch
          onChange={setShowAccounting}
          value={showAccounting}
          text="Show accounting categories"
          className="mb-4"
        />

        <Link to={"?new"} className="btn btn-secondary btn-sm">
          {" "}
          <HiPlusCircle size={22} /> New
        </Link>
      </div>

      {!showAccounting ? (
        <Categories
          {...{
            isLoading: rLoading,
            regularCategories,
            accountingCategories,
          }}
        />
      ) : (
        <AccountingCategories
          {...{
            isLoading: aLoading,
            categories: regularCategories,
            accountingCategories,
          }}
        />
      )}
    </div>
  );
};

export default CategoryContainer;
