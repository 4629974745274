import Tabs from "@/components/Tabs";
import { useOrganization } from "@/context/OrganizationContext";
import { BsPatchExclamation } from "react-icons/bs";
import { useParams } from "react-router-dom";
import CategoryContainer from "../categories/CategoryContainer";
import OrganizationDetails from "./OrganizationDetails";
import TeamsListing from "./Team/TeamsListing";
import TaxCodeList from "./taxCode/TaxCodeList";

const tabs = ["general", "team", "categories", "tax-code"] as const;

type Tab = (typeof tabs)[number];

const Organization = () => {
  const { organization } = useOrganization();
  const { tab = "general" } = useParams<{ tab?: Tab }>();

  if (organization?.role !== "admin") {
    return (
      <div className="badge badge-error">
        <BsPatchExclamation size={22} />
        <p>You don't have permission to view/edit this organization</p>
      </div>
    );
  }

  const content: Record<Tab, JSX.Element> = {
    general: <OrganizationDetails organization={organization} />,
    team: <TeamsListing key={organization.id} organization={organization} />,
    categories: <CategoryContainer />,
    "tax-code": <TaxCodeList organizationId={organization.id} />,
  };
  return (
    <div className="">
      <Tabs
        className="mb-6 z-20 w-fit"
        tabs={tabs}
        tab={tab}
        paths={["/", "/team", "/categories", "/tax-code"]}
        parentRoute="/organization"
        type="boxed"
        tabsClass="!hidden xl:!tabs"
        dropdownClass="xl:!hidden !block"
      />

      {content[tab]}
    </div>
  );
};

export default Organization;
