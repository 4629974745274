import Drawer from "@/components/drawer/Drawer";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import Pagination from "@/ui/Pagination";
import { formatCurrency, formatLabel } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { DatePeriod } from "../Accounting";
import SelectInvoiceFilters from "./InvoiceFilters";
import InvoiceHeaders, { Sorting } from "./InvoiceHeaders";
import InvoiceListing from "./InvoiceListing";

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
};

export type InvoiceFilter = RouterInputs["invoices"]["invoices"]["filters"];

const Invoices: FC<Props> = ({
  setShowFilters,
  showFilters,
  selection,
  setSelection,
  period,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationId = "", organization } = useOrganization();

  const [filters, setFilters] = useState<InvoiceFilter>(null);

  const [sorting, setSorting] = useState<Sorting>({
    createdAt: "desc",
  });

  const params = { organizationId, currentPage, filters, sorting, period };

  const { data = [], isLoading } = api.invoices.invoices.useQuery(params, {
    enabled: !!organizationId,
  });

  const { data: filtersData } = api.invoices.filtersData.useQuery(
    { organizationId, filters, period, groupBy: "accountingStatus" },
    { enabled: !!organizationId }
  );

  const total = filtersData?.total ?? 0;

  const selectAll = api.invoices.selectAllInvoices.useMutation();

  const selectedArray: string[] = useMemo(
    () => Object.values(selection).map((a) => a.id),
    [selection]
  );

  const handleSelectAll = async () => {
    if (allSelected) {
      setSelection({});
      return;
    }
    const res = await selectAll.mutateAsync({
      organizationId,
      filters,
      sorting,
      period,
    });

    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const fullListSelected = data.length
    ? selectedArray.length >= data.length
    : false;

  const hasMoreToSelect = fullListSelected && total > selectedArray.length;

  const allSelected = total > 0 && total === selectedArray.length;

  const statusFilters = useMemo(() => {
    return accountingStatuses
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData?.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={showFilters}
        onClose={setShowFilters}
        title={"Filters"}
        content={
          <SelectInvoiceFilters
            {...{
              ranges: filtersData?.ranges ?? { max: 0, min: 0 },
              setFilters,
              setShowFilters,
              showFilters,
              unCategorized: filtersData?.unCategorized ?? 0,
            }}
          />
        }
      />

      <div className="lg:flex hidden gap-2 items-center my-4">
        <button
          onClick={() =>
            setFilters((p) =>
              p ? { ...p, accountingStatus: null } : { accountingStatus: null }
            )
          }
          className={`btn btn-outline ${
            filters?.accountingStatus ? "" : "btn-primary"
          } `}
        >
          <p>All</p>
          <p>{filtersData?.total}</p>
        </button>
        {statusFilters.map((s) => (
          <button
            onClick={() =>
              setFilters((p) =>
                p
                  ? { ...p, accountingStatus: s.status }
                  : { accountingStatus: s.status }
              )
            }
            className={`btn btn-outline btn-ghost h-full ${
              filters?.accountingStatus !== s.status ? "" : "btn-primary "
            } `}
          >
            <p className="capitalize flex items-center gap-2">
              {formatLabel(s.status)}
              <span className="text-xs">
                ({formatCurrency(s.totalAmount, organization?.defaultCurrency)})
              </span>
            </p>
            <p>{s.totalCount}</p>
          </button>
        ))}
      </div>

      <div className="flex items-center gap-4 h-12">
        <button
          className={`btn-sm btn-outline btn btn-secondary text-xs ${
            hasMoreToSelect || allSelected ? "" : "hidden"
          } `}
          onClick={handleSelectAll}
        >
          <LoadingSpin loading={selectAll.isLoading} />
          {allSelected ? (
            <p>All {total} invoices selected</p>
          ) : (
            <p>Select all {total} invoices</p>
          )}
        </button>
      </div>

      <div className="overflow-x-auto overflow-auto h-full">
        <table className="table table-zebra">
          <InvoiceHeaders
            {...{
              setSorting,
              sorting,
              data: data,
              setSelection,
              selectedArray,
            }}
          />

          <InvoiceListing
            {...{
              list: data,
              params,
              isLoading,
              setSelection,
              selection,
              totalSelected: selectedArray.length,
            }}
          />
        </table>
        {isLoading && <Spinner />}
        {!isLoading && !data.length && <NotFound title="Invoices" />}
        <Pagination
          {...{
            currentPage,
            setCurrentPage,
            total: total,
          }}
        />
      </div>
    </div>
  );
};

export default Invoices;
