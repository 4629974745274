import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { toast } from "react-hot-toast";

import FormInput from "@/components/FormInput";
import PhoneInput from "@/components/PhoneInput";
import SelectCountry from "@/components/SelectCountry";
import { Vendor } from "@/types";
import { UpsertPartner, UpsertPartnerType } from "@/types/validation";
import ImageFromPath from "@/ui/ImageFromPath";
import { uploadFile } from "@/utils/supabase";
import { useMutation } from "@tanstack/react-query";
import VendorAccounts from "./VendorAccounts";

const fields: {
  name: keyof UpsertPartnerType;
  placeholder: string;
  title: string;
}[] = [
  {
    name: "name",
    placeholder: "Enter Vendor name",
    title: "Vendor Name",
  },
  {
    name: "email",
    placeholder: "Enter Vendor email",
    title: "Email address",
  },
  {
    name: "business_tax_number",
    placeholder: "Enter business tax number",
    title: "Tax Number",
  },
  {
    name: "business_number",
    placeholder: "Enter business number",
    title: "Business Number",
  },
  {
    name: "city",
    placeholder: "Enter city name",
    title: "City name",
  },
  {
    name: "postCode",
    placeholder: "Enter postal code",
    title: "Postal Code",
  },
  {
    name: "address",
    placeholder: "Enter address",
    title: "Address",
  },
];

type Props = {
  vendor?: Vendor | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const VendorDetails: FC<Props> = ({ vendor, setIsOpen }) => {
  const {
    handleChange,
    inputs,
    setInputs,
    errors,
    changed,
    setValue,
    setErrors,
  } = useForm<Partial<UpsertPartnerType>>({});

  useEffect(() => {
    if (!vendor) return;
    setInputs(vendor);
  }, [vendor]);

  const utils = api.useUtils();
  const { organizationId = "" } = useOrganization();

  const upsert = api.partners.upsertVendor.useMutation();
  const upload = useMutation(uploadFile);

  const handleUpdate = async () => {
    if (!organizationId) return;

    const valid = UpsertPartner.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);

      return toast.error("Please check the details and try again");
    }

    const res = await upsert.mutateAsync({
      ...valid.data,
      organizationId: organizationId,
    });

    utils.partners.vendorsByOrgId.setData(organizationId, (p) => {
      if (!p) return [res];
      return [res, ...p.filter((c) => c.id !== res.id)];
    });
    setIsOpen(false);
  };

  const handleUpload = async (file?: File) => {
    if (!file) return;
    const url = await upload.mutateAsync(file);
    setValue("photo", url);
  };
  return (
    <div className="grid gap-6">
      <div className="w-full  rounded-md">
        <ImageFromPath
          key={inputs.photo}
          {...{ path: inputs.photo }}
          className="mx-auto w-24 border mb-4 p-2 aspect-square object-cover h-24  rounded-full "
        />
        <div className="flex  justify-center ">
          <input
            type="file"
            accept="image/*"
            className="file-input file-input-sm file:text-xs mx-auto w-full"
            onChange={(e) => handleUpload(e.target.files?.[0])}
          />
          <LoadingSpin loading={upload.isLoading} />
        </div>
      </div>
      <div className="">
        <div className="grid gap-x-2 grid-cols-2">
          {fields.map((p) => (
            <FormInput
              {...p}
              key={p.name}
              className="last:col-span-2"
              onChange={handleChange(p.name)}
              isError={errors[p.name]}
              value={inputs[p.name]}
            />
          ))}
        </div>

        <PhoneInput
          onChange={(e) => setValue("phone", e)}
          value={inputs.phone}
          isError={errors.phone}
        />
        <SelectCountry
          onChange={(e) => setValue("countryId", +e)}
          className="col-span-2"
          value={inputs.countryId}
          error={errors.countryId}
        />
        <button
          className={`btn sticky bottom-1 mt-6 w-full ${
            changed ? "btn-primary" : ""
          } `}
          onClick={() => handleUpdate()}
        >
          <LoadingSpin loading={upsert.isLoading} />
          {vendor?.id ? "Update" : "Create"}
        </button>
      </div>
      {vendor && <VendorAccounts vendorId={vendor.id} />}
    </div>
  );
};

export default VendorDetails;
