import MultiSelect from "@/components/MultiSelect";
import { useOrganization } from "@/context/OrganizationContext";
import { TransactionFilters } from "@/types/validation";
import { decodeBase64ToObject } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import MultiRangeSlider from "multi-range-slider-react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { MdDone, MdOutlineClear } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { TransactionFilter } from "./TransactionsList";

type Props = {
  setShowFilters: (showFilters: boolean) => void;
  hidden: number;
  recurring: number;
  ranges: {
    min: number;
    max: number;
  };
  missingAttachments: number;
  unCategorized: number;
  setFilters: Dispatch<SetStateAction<TransactionFilter>>;
  banks: RouterOutputs["banks"]["banks"];
};

type Filter = {
  missingAttachments?: boolean;
  unCategorized?: boolean;
  type?: "debit" | "credit" | null;
  merchants?: string;
  merchantsId?: string[];
  showHidden?: boolean;
  onlyRecurring?: boolean;
  categories?: string[];
  subCategories?: string[];
  accounts?: string[];
  banks?: string[];
  currencies?: string[];
};

const SelectTransactionFilters: FC<Props> = ({
  ranges: { max, min },
  setShowFilters,
  missingAttachments,
  unCategorized,
  setFilters,
  hidden,
  recurring,
  banks,
}) => {
  const { organizationId: orgId = "" } = useOrganization();
  const { data = [] } = api.utils.categories.useQuery(orgId, {
    enabled: !!orgId,
  });

  const { inputs, setInputs, setValue } = useForm<Filter>({});

  const [minMax, setMinMax] = useState([0, max]);
  const [values, setValues] = useState<number[]>([0, max]);

  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (inputs.type === "debit") {
      setMinMax([0, Math.abs(min)]);
    } else if (inputs.type === "credit") {
      setMinMax([0, max]);
    }
  }, [inputs.type, min, max]);

  useEffect(() => {
    const q = new URLSearchParams(location.search);

    const _filters = q.get("filters");

    if (!_filters) return;

    const filters = decodeBase64ToObject(_filters);

    const validate = TransactionFilters.safeParse(filters);

    if (validate.success) {
      const { amount } = validate.data;

      if (amount) setValues([amount.min, amount.max]);

      setInputs((p) => ({ ...p, ...validate.data }));
      setFilters((p) => ({ ...p, ...validate.data }));
    }
  }, [location.search]);

  const handleApply = () => {
    const { type, ...rest } = inputs;
    let [min, max] = values;

    if (type === "debit") {
      [min, max] = [-max, -min];
    }

    const validRange = (!!min || !!max) && type;

    setFilters({
      amount: validRange ? { max, min } : null,
      ...rest,
    });
    setShowFilters(false);
  };

  const handleClear = () => {
    setFilters(null);
    setShowFilters(false);
    setInputs({});
    setValues([0, max]);

    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("filters");

    const parentRoute = location.pathname + "?" + queryParams.toString();
    nav(parentRoute);
  };

  const availableCurrenices = [
    ...new Set(banks.flatMap((b) => b.accounts.map((a) => a.currency))),
  ].map((c) => ({ value: c }));

  const availableAccounts = useMemo(() => {
    return banks
      .filter((b) => inputs.banks?.includes(b.id))
      .flatMap((b) => b.accounts.map((a) => ({ ...a, bankName: b.name })));
  }, [inputs.banks]);

  return (
    <div className="grid gap-4">
      <div className="w-full z-[60]">
        <p className="label">Banks</p>

        <MultiSelect
          values={inputs.banks}
          placeholder="Select Banks"
          searchable
          title="Banks"
          viewMode="count"
          onChange={(e) => setValue("banks", e)}
          options={banks.map((c) => ({
            value: c.id,
            label: `${c.name} (${c.institution_name})`,
          }))}
        />
        <p className="label label-text-alt">*all banks selected by default</p>
      </div>
      <div className="w-full z-50">
        <p className="label">Accounts</p>

        <MultiSelect
          values={inputs.accounts}
          placeholder="Select accounts"
          searchable
          title="Accounts"
          viewMode="count"
          onChange={(e) => setValue("accounts", e)}
          options={availableAccounts.map((c) => ({
            value: c.id,
            label: `${c.name} (${c.bankName})`,
          }))}
        />
        <p className="label label-text-alt">
          *all accounts selected by default
        </p>
      </div>
      <div className="w-full z-40">
        <p className="label">Categories</p>

        <MultiSelect
          values={inputs.categories}
          searchable
          placeholder="Select categories"
          onChange={(e) => setValue("categories", e)}
          options={data.map((c) => ({ value: c.id, label: c.name }))}
        />
      </div>
      <div className="w-full z-30">
        <p className="label">Sub Categories</p>

        <MultiSelect
          searchable
          values={inputs.subCategories}
          placeholder="Select sub categories"
          onChange={(e) => setValue("subCategories", e)}
          options={data
            .flatMap((c) => c.subCategories)
            .map((c) => ({ value: c.id, label: c.name }))}
        />
      </div>

      <div className="w-full ">
        <p className="label">Currencies</p>

        <MultiSelect
          values={inputs.currencies}
          searchable
          placeholder="Select currencies"
          onChange={(e) => setValue("currencies", e)}
          options={availableCurrenices}
        />
      </div>
      <div>
        <div>
          <label className="label label-text">Type</label>
          <div className="flex  gap-2 w-full">
            <button
              onClick={() => {
                setInputs((p) => ({
                  ...p,
                  type: p.type === "debit" ? null : "debit",
                }));
                setValues([0, Math.abs(min)]);
              }}
              className={`btn btn-error flex btn-sm ${
                inputs.type === "debit" ? " " : "btn-outline"
              }`}
            >
              <BsArrowUpRight /> Debit
            </button>
            <button
              onClick={() => {
                setInputs((p) => ({
                  ...p,
                  type: p.type === "credit" ? null : "credit",
                }));
                setValues([0, max]);
              }}
              className={`btn btn-success btn-sm ${
                inputs.type === "credit" ? "" : "btn-outline"
              } `}
            >
              <BsArrowDownLeft />
              Credit
            </button>
          </div>
        </div>
        {!!inputs.type && (
          <MultiRangeSlider
            min={minMax[0]}
            max={minMax[1]}
            step={5}
            minValue={values[0]}
            maxValue={values[1]}
            onChange={(e) => setValues([e.minValue, e.maxValue])}
            ruler={false}
            barInnerColor="#475AFF"
          />
        )}
      </div>

      <div className="flex items-center flex-wrap gap-4 my-4">
        <button
          className={`btn btn-secondary ${
            inputs.missingAttachments ? "" : "btn-outline"
          }`}
          onClick={() =>
            setInputs((p) => ({
              ...p,
              missingAttachments: !p.missingAttachments,
            }))
          }
        >
          Missing Attachments ({missingAttachments})
        </button>
        <button
          className={`btn btn-secondary ${
            inputs.unCategorized ? "" : "btn-outline"
          }`}
          onClick={() =>
            setInputs((p) => ({
              ...p,
              unCategorized: !p.unCategorized,
            }))
          }
        >
          Un-Categorized ({unCategorized})
        </button>

        <button
          className={`btn btn-secondary ${
            inputs.onlyRecurring ? "" : "btn-outline"
          }`}
          onClick={() =>
            setInputs((p) => ({
              ...p,
              onlyRecurring: !p.onlyRecurring,
            }))
          }
        >
          Only Recurring ({recurring})
        </button>
        <button
          className={`btn btn-secondary ${
            inputs.showHidden ? "" : "btn-outline"
          } `}
          onClick={() =>
            setInputs((p) => ({
              ...p,
              showHidden: !p.showHidden,
            }))
          }
        >
          Show Hidden ({hidden})
        </button>
      </div>

      <div className="w-full">
        <p className="font-medium text-sm mb-1 text-base-content">Merchants </p>
        <input
          type="text"
          className="input input-bordered w-full"
          placeholder="Search for a merchant"
          value={inputs.merchants || ""}
          onChange={(e) =>
            setInputs((p) => ({ ...p, merchants: e.target.value }))
          }
        />
      </div>

      <div className="flex flex-row gap-3 w-full mt-3">
        <button onClick={handleApply} className="btn btn-primary flex-1">
          <MdDone />
          Apply
        </button>
        <button onClick={handleClear} className="btn btn-error btn-outline">
          <MdOutlineClear /> Reset
        </button>
      </div>
    </div>
  );
};

export default SelectTransactionFilters;
