import NotFound from "@/components/utils/notfound";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { format } from "date-fns";
import { FC } from "react";
import { BsArrowDownLeft } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  invoices: RouterOutputs["reports"]["analytics"]["invoices"];
};

export const RecentInvoices: FC<Props> = ({ invoices }) => {
  const nav = useNavigate();
  if (!invoices.length) {
    return <NotFound title={"Invoices"} />;
  }

  return (
    <div className="overflow-x-auto ">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th></th>
            <th>Customer</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((i) => {
            return (
              <tr
                className="hover cursor-pointer"
                onClick={() => nav(`/invoice/${i.id}`)}
              >
                <th>
                  <BsArrowDownLeft size="16" className="text-success" />
                </th>
                <td>
                  <div>
                    <p>{i.customer.name}</p>
                    <span className="text-neutral-500   text-xs">
                      {format(i.dueDate, "dd MMM yyyy")}
                    </span>
                  </div>
                </td>
                <td className="text-success font-medium text-xs">
                  {formatCurrency(i.total)}
                </td>

                <td>
                  <div className={`btn capitalize btn-sm text-xs `}>
                    {i.status}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center py-2">
        <Link to={"/invoices"} className="link link-hover link-primary text-sm">
          see more
        </Link>
      </div>
    </div>
  );
};
