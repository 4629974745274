import { AccountingStatusType, accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { FC } from "react";
import Dropdown from "../../../components/Dropdown";

type Props = {
  status: AccountingStatusType;
  invoiceId: string;
  params: RouterInputs["invoices"]["invoices"];
};

const AccountingStatus: FC<Props> = ({ status, invoiceId, params }) => {
  const update = api.invoices.updateAccountingStatus.useMutation();
  const utils = api.useUtils();

  const handleUpdate = (status: AccountingStatusType) => async () => {
    const res = await update.mutateAsync({ invoiceId, status });

    utils.invoices.invoices.setData(params, (p) => {
      if (!p) return p;
      return p.map((b) => {
        if (b.id === invoiceId) return { ...b, ...res };
        return b;
      });
    });
  };
  return (
    <Dropdown
      dropdownClass="w-full"
      buttonClass="capitalize"
      title={update.isLoading ? <LoadingSpin loading /> : formatLabel(status)}
      content={accountingStatuses.map((s) => (
        <button onClick={handleUpdate(s)} className="capitalize">
          {formatLabel(s)}{" "}
        </button>
      ))}
    />
  );
};

export default AccountingStatus;
