import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { RouterInputs, api } from "@/utils/trpc";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import InViewTrigger from "@/components/InViewTrigger";
import Drawer from "@/components/drawer/Drawer";
import { accountingStatuses } from "@/lib";
import Rows from "@/ui/skeletons/Rows";
import {
  formatCurrency,
  formatLabel,
  handleSelectFromList,
} from "@/utils/helper";
import { format } from "date-fns/esm";
import { toast } from "react-hot-toast";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { DatePeriod } from "../Accounting";
import SingleTransaction from "./SingleTransaction";
import SelectTransactionFilters from "./TransactionFilters";
import TransactionHeader from "./TransactionHeader";

type Sorting = {
  amount?: "asc" | "desc";
  date?: "asc" | "desc";
};

export type TransactionFilter =
  RouterInputs["transactions"]["transactions"]["filters"];

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
};

const Transactions: FC<Props> = ({
  setShowFilters,
  showFilters,
  selection,
  setSelection,
  period,
}) => {
  const utils = api.useUtils();

  const { organizationId = "", organization } = useOrganization();
  const [sorting, setSorting] = useState<Sorting>({
    date: "desc",
  });

  const [filters, setFilters] = useState<TransactionFilter | null>(null);

  const {
    data: banks = [],
    refetch: refetchBanks,
    remove: removeBank,
  } = api.banks.banks.useQuery(organizationId, {
    enabled: !!organizationId,
  });

  const expiredBanks = useMemo(() => {
    return banks.filter((b) => b.isSyncError);
  }, []);

  const lastSyncedAt = useMemo(() => {
    return banks.reduce<Date | null>((acc, curr) => {
      if (!curr.lastSyncedAt) return acc;
      if (!acc) return curr.lastSyncedAt;
      return curr.lastSyncedAt > acc ? curr.lastSyncedAt : acc;
    }, null);
  }, [banks]);

  const params = {
    organizationId,
    sorting,
    filters,
    period,
  };

  const filterParams = {
    organizationId,
    filters,
    period,
  };

  const {
    data: filtersData = {
      total: 0,
      missingAttachments: 0,
      unCategorized: 0,
      ranges: { max: 0, min: 0 },
      hidden: 0,
      recurring: 0,
      statusData: {},
    },
  } = api.transactions.filtersData.useQuery(filterParams);

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    remove: removeTransaction,
    refetch: refetchTransactions,
    fetchNextPage,
  } = api.transactions.transactions.useInfiniteQuery(params, {
    getNextPageParam: (p) => p.nextCursor,
  });

  const transactions = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  const hasNextPage = transactions.length < filtersData.total;

  const sync = api.banks.sync.useMutation();

  const update = api.transactions.updateProofStatus.useMutation();
  const selectAll = api.transactions.selectAllTransactions.useMutation();

  const selectedArray = useMemo(() => Object.values(selection), [selection]);

  const handleSelectAll = async () => {
    if (allSelected) return setSelection({});

    const res = await selectAll.mutateAsync({
      organizationId,
      sorting,
      filters,
      period,
    });
    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const handleUpdate = async () => {
    if (selectedArray.some((t) => t.proofStatus === "complete")) {
      return toast.error(
        "You cannot set a transaction as not needed if it has a proof attached"
      );
    }
    const promises = selectedArray.map((a) => {
      return update.mutateAsync({ id: a.id, proofStatus: "not_needed" });
    });

    await Promise.all(promises);
    setSelection({});

    await utils.transactions.transactions.refetch(params);
  };

  const handleSync = async () => {
    const res = await sync.mutateAsync(banks.map((b) => b.id));

    const unSyncedBanks = res.filter((r) => r.isSyncError);

    if (!unSyncedBanks.length) return toast.success("Synced successfully!");

    toast.error(
      `The following banks could not be synced, ${unSyncedBanks
        .map((b) => `${b.name} (${b.institution_name})`)
        .join(", ")}`
    );

    removeBank();
    removeTransaction();
    await refetchBanks();
    await refetchTransactions();

    toast.success("Synced successfully!");
  };

  const fullListSelected = transactions.length
    ? selectedArray.length >= transactions.length
    : false;

  const hasMoreToSelect =
    fullListSelected && filtersData.total > selectedArray.length;

  const allSelected =
    filtersData.total > 0 && filtersData.total === selectedArray.length;

  const handleSelect = useCallback((t: (typeof transactions)[number]) => {
    setSelection(handleSelectFromList(t, transactions, selectedArray.length));
  }, []);

  const statusFilters = useMemo(() => {
    return accountingStatuses
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <div className="lg:flex-row  my-3 items-center flex flex-col gap-4 z-50">
        <div className="lg:flex hidden gap-2 items-center my-4">
          <button
            onClick={() =>
              setFilters((p) =>
                p
                  ? { ...p, accountingStatus: null }
                  : { accountingStatus: null }
              )
            }
            className={`btn btn-outline ${
              filters?.accountingStatus ? "" : "btn-primary"
            } `}
          >
            <p>All</p>
            <p>{filtersData?.total}</p>
          </button>
          {statusFilters.map((s) => (
            <button
              onClick={() =>
                setFilters((p) =>
                  p
                    ? { ...p, accountingStatus: s.status }
                    : { accountingStatus: s.status }
                )
              }
              className={`btn btn-outline btn-ghost h-full ${
                filters?.accountingStatus !== s.status ? "" : "btn-primary "
              } `}
            >
              <p className="capitalize flex items-center gap-2">
                {formatLabel(s.status)}
                <span className="text-xs">
                  (
                  {formatCurrency(s.totalAmount, organization?.defaultCurrency)}
                  )
                </span>
              </p>
              <p>{s.totalCount}</p>
            </button>
          ))}
        </div>
        <button
          onClick={handleSync}
          className="btn w-full ml-auto lg:w-fit btn-primary text-left text-xs"
        >
          <LoadingSpin loading={sync.isLoading} />
          <span className="">
            <span className="font-bold">Synchronize</span>
            <p>
              {!!lastSyncedAt &&
                format(lastSyncedAt, "dd MMM yyyy' at' h:mm a")}
            </p>
          </span>

          {!!expiredBanks.length && (
            <div
              className={`tooltip z-10 before:w-60 tooltip-bottom before:-left-20 before:text-sm tooltip-warning before:whitespace-pre-wrap before:[--tw-content:'line1_\a_line2']`}
              data-tip={`These banks need your attention: \n ${expiredBanks
                .map((b) => `${b.name} (${b.institution_name})`)
                .join("\n")}`}
            >
              <span className="btn btn-sm btn-neutral">
                <TbAlertTriangleFilled className="text-warning" />
              </span>
            </div>
          )}
        </button>
      </div>
      <div className="flex items-center gap-4 my-4">
        <button
          onClick={handleUpdate}
          className={`btn btn-sm btn-outline btn-secondary  ${
            !!selectedArray.length ? "" : "hidden"
          } `}
        >
          <LoadingSpin loading={update.isLoading} />
          Set all {selectedArray.length} selected as not needed
        </button>

        <button
          className={`btn btn-sm btn-primary btn-outline ${
            hasMoreToSelect || allSelected ? "" : "hidden"
          } `}
          onClick={handleSelectAll}
        >
          <LoadingSpin loading={selectAll.isLoading} />
          {allSelected ? (
            <p>All {filtersData.total} transactions selected</p>
          ) : (
            <p>Select all {filtersData.total} transactions</p>
          )}
        </button>
      </div>
      <div>
        <table className="table table-zebra">
          <TransactionHeader
            {...{
              setSorting,
              sorting,
              data: transactions,
              setSelection,
              selectedArray,
            }}
          />

          <tbody>
            {transactions.map((t, i) => (
              <SingleTransaction
                key={t.id}
                {...{
                  transaction: t,
                  index: i,
                  isSelected: !!selection[t.id],
                  showHidden: !!filters?.showHidden,
                  handleSelect,
                  total: transactions.length,
                  setTransaction: () => {},
                  params,
                }}
              />
            ))}
          </tbody>
        </table>
        {isLoading && <Rows />}
        {!isLoading && !transactions.length && <NotFound title="Transaction" />}

        {isLoading ? null : hasNextPage ? (
          <InViewTrigger
            onInView={fetchNextPage}
            children={<LoadingSpin loading={isFetching} />}
          />
        ) : (
          <p className="text-center text-sm my-4 text-gray-500">
            {transactions.length
              ? "No more transactions for the period selected"
              : ""}
          </p>
        )}
      </div>

      <Drawer
        isOpen={showFilters}
        onClose={setShowFilters}
        title={"Filters"}
        content={
          <SelectTransactionFilters
            {...{
              setShowFilters,
              banks,
              showFilters,
              ...filtersData,
              setFilters,
            }}
          />
        }
      />
    </div>
  );
};

export default Transactions;
