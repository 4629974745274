import ThemeToggle from "@/components/ThemeToggle";
import { useTheme } from "@/context/ThemeContext";
import { FC } from "react";
import { RiMenu4Fill, RiUser3Fill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import Notifications from "./Notifications";

const Header: FC = () => {
  const { pathname } = useLocation();
  const { isDark } = useTheme();
  return (
    <header
      className={`items-center  flex justify-between py-2 sm:px-4 px-2 w-full`}
    >
      <label
        htmlFor="navbar"
        className="md:hidden btn btn-ghost btn-xs rounded-sm p-0 btn-square"
      >
        <RiMenu4Fill size={26} />
      </label>

      <div className="flex ml-auto items-center sm:gap-5 gap-3">
        <div className="hidden sm:block">
          <ThemeToggle />
        </div>
        <Notifications />
        <Link
          to={"/profile"}
          className={` ${pathname === "/profile" ? "link link-primary" : ""} `}
        >
          <RiUser3Fill size={22} />
        </Link>
      </div>
    </header>
  );
};

export default Header;
