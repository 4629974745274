import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { FinancialAccount } from "@/pages/accounting/financialAccount/FinancialAccountList";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";

type Props = {
  transactionId: string;
  financialAccount: FinancialAccount | null;
  cardId?: string;
  showLabel?: boolean;
};

const AssignFinancialAccount: FC<Props> = ({
  transactionId,
  financialAccount,
  cardId,
  showLabel,
}) => {
  const [enabled, setEnabled] = useState(false);
  const { organizationId } = useOrganization<true>();
  const { data = [], isLoading } =
    api.organizations.financialAccount.list.useQuery(organizationId, {
      enabled,
    });

  const add = api.swan.transfers.financialAccount.add.useMutation();
  const remove = api.swan.transfers.financialAccount.remove.useMutation();
  const utils = api.useUtils();

  const handleAdd = (financialAccountId: string) => async () => {
    const { financialAccount } = await add.mutateAsync({
      financialAccountId,
      transactionId,
    });

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id !== transactionId) return t;
              return { ...t, financialAccount };
            }),
          })),
        };
      });

    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) => {
          if (t.id !== transactionId) return t;
          return { ...t, financialAccount };
        });
      });
  };

  const handleRemove = async () => {
    await remove.mutateAsync(transactionId);

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id !== transactionId) return t;
              return { ...t, financialAccount: null };
            }),
          })),
        };
      });

    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) => {
          if (t.id !== transactionId) return t;
          return { ...t, financialAccount: null };
        });
      });
  };

  const loading = add.isLoading || remove.isLoading;

  return (
    <div>
      {showLabel && (
        <label className="text-sm divider divider-start">
          Financial Account
        </label>
      )}
      <Dropdown
        onClick={(e) => {
          e.stopPropagation();
          setEnabled(true);
        }}
        dropdownClass="w-full min-w-max z-10 "
        buttonClass="bg-base-300"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : financialAccount ? (
            <span>
              {financialAccount.name} ({financialAccount.code})
            </span>
          ) : (
            "N/A"
          )
        }
        footer={
          financialAccount && (
            <button
              onClick={handleRemove}
              className="btn btn-error btn-sm w-full text-xs"
            >
              Remove ({financialAccount.name})
            </button>
          )
        }
        content={
          isLoading
            ? ["Loading..."]
            : data.map((t) => (
                <button
                  onClick={handleAdd(t.id)}
                  onTouchEnd={handleAdd(t.id)}
                  key={t.id}
                >
                  {t.name} ({t.code})
                </button>
              ))
        }
      />
    </div>
  );
};

export default AssignFinancialAccount;
