import Dropdown from "@/components/Dropdown";
import Balances from "@/components/charts/Balances";
import CashFlow from "@/components/charts/CashFlow";
import PieChart from "@/components/charts/PieChart";
import Spending from "@/components/charts/Spending";
import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import { PageTitle } from "@/ui/PageTitle";
import SelectDate from "@/ui/SelectDate";
import { formatCurrency, formatLabel, setTitle } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { Chart, registerables } from "chart.js";

import Categorization from "@/components/charts/Categorization";
import MerchantCompletion from "@/components/charts/MerchantCompletion";
import DashboardSkeleton from "@/ui/skeletons/Dashboard";
import endOfDay from "date-fns/endOfDay";
import endOfYear from "date-fns/endOfYear";
import isBefore from "date-fns/isBefore";
import isToday from "date-fns/isToday";
import startOfDay from "date-fns/startOfDay";
import startOfYear from "date-fns/startOfYear";
import { ReactNode, useEffect, useState } from "react";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { FaBalanceScaleLeft, FaMoneyBillWave } from "react-icons/fa";
import { PiInfoBold } from "react-icons/pi";
import { TbFileInvoice, TbReceiptTax } from "react-icons/tb";
import { RecentBills } from "./RecentBills";
import { RecentInvoices } from "./RecentInvoices";
import { RecentTransactions } from "./RecentTransactions";

const tabs = [
  {
    name: "invoices" as const,
    icon: <TbFileInvoice size="18" />,
  },
  {
    name: "bills" as const,
    icon: <TbReceiptTax size="18" />,
  },
];
type Tab = (typeof tabs)[number]["name"];

const charts = [
  "cash flow",
  "spending",
  "close balance",
  "categorization",
  "merchantCompletion",
] as const;
type ChartType = (typeof charts)[number];

Chart.register(...registerables);

const pastThreeYears = [0, 1, 2].map((i) => new Date().getFullYear() - i);

const AdminDashboard = () => {
  useEffect(() => setTitle("Dashboard"), []);
  const { organizationId = "" } = useOrganization();
  const [tab, setTab] = useState<Tab>("invoices");
  const [chart, setChart] = useState<ChartType>("cash flow");

  const [period, setPeriod] = useState({
    startDate: startOfYear(new Date()),
    endDate: endOfDay(new Date()),
  });

  const [year, setYear] = useState(pastThreeYears[0]);

  const { data, isLoading } = api.reports.analytics.useQuery(
    { organizationId, ...period, isToday: isToday(period.endDate) },
    { enabled: !!organizationId }
  );

  const { data: chartData, isLoading: chartLoading } =
    api.reports.barChart.useQuery(
      {
        organizationId,
        endDate: endOfYear(new Date().setFullYear(year)),
        startDate: startOfYear(new Date().setFullYear(year)),
      },
      { enabled: !!organizationId }
    );

  if (!organizationId) return <NotFound title="Organization" />;

  if (isLoading) return <DashboardSkeleton />;
  if (!data) return <NotFound title="Data" />;

  const content: Record<Tab, ReactNode> = {
    bills: <RecentBills {...{ bills: data.bills }} />,
    invoices: <RecentInvoices {...{ invoices: data.invoices }} />,
  };

  const chartContent: Record<ChartType, ReactNode> = {
    spending: (
      <Spending
        {...{
          data: chartData?.spending,
          currency: data.currency,
          loading: chartLoading,
        }}
      />
    ),
    "cash flow": (
      <CashFlow
        {...{
          data: chartData?.cashFlow,
          currency: data.currency,
          loading: chartLoading,
        }}
      />
    ),
    "close balance": (
      <Balances
        {...{
          data: chartData?.balances,
          currency: data.currency,
          loading: chartLoading,
          year,
        }}
      />
    ),
    categorization: <Categorization {...{ data: chartData?.categorization }} />,
    merchantCompletion: (
      <MerchantCompletion {...{ data: chartData?.merchantCompletion }} />
    ),
  };

  const handleSetYear = (y: number) => () => {
    setYear(y);

    const startDate = startOfYear(new Date().setFullYear(y));
    const endDate = endOfYear(new Date().setFullYear(y));

    setPeriod({
      endDate: isBefore(endDate, new Date()) ? endDate : endOfDay(new Date()),
      startDate: startOfDay(startDate),
    });
  };

  return (
    <div id="s-1">
      <div className="flex z-50 flex-col lg:flex-row items-start gap-6">
        <PageTitle title="Dashboard" />

        <SelectDate
          value={period}
          className="w-fit mb-6"
          popoverDirection="down"
          onChange={(v) => {
            if (!v?.endDate || !v.startDate) return;

            setPeriod({
              endDate: isBefore(new Date(v.endDate), new Date())
                ? endOfDay(new Date(v.endDate))
                : new Date(),
              startDate: startOfDay(new Date(v.startDate)),
            });
          }}
        />
      </div>

      <div className="stats   min-w-fit grid grid-rows-4 lg:grid-rows-2 2xl:grid-rows-1 w-full shadow mb-12">
        <div className="stat">
          <div className="stat-figure text-primary">
            <FaMoneyBillWave size={36} />
          </div>
          <div className="stat-title">Total Balance</div>
          <div className="stat-value text-primary">
            {data.currentBalance
              ? formatCurrency(data.currentBalance, data.currency, true)
              : "N/A"}
          </div>
          <div className="stat-desc">
            {data.diffPercentage
              ? `${~~Math.abs(data.diffPercentage)}% ${
                  data.diffPercentage > 0 ? "more" : "less"
                } than previous month`
              : ""}
          </div>
        </div>

        <div className="stat">
          <div className="stat-figure text-success">
            <BsArrowDownLeft size={36} />
          </div>
          <div className="stat-title">Expected Payments</div>
          <div className="stat-value text-success">
            {formatCurrency(data.receivable, data.currency, true)}
          </div>
          <div className="stat-desc">To be received in this period</div>
        </div>
        <div className="stat">
          <div className="stat-figure text-error">
            <BsArrowUpRight size={36} />
          </div>
          <div className="stat-title">Outgoing Bills</div>
          <div className="stat-value text-error">
            {formatCurrency(data.payable, data.currency, true)}
          </div>
          <div className="stat-desc">To be paid in this period</div>
        </div>
        <div className="stat">
          <div className="stat-figure text-info">
            <FaBalanceScaleLeft size={36} />
          </div>
          <div className="stat-title">
            Net {data.profitNLoss > 0 ? "Profit" : "Expense"}
          </div>
          <div className="stat-value text-info">
            {formatCurrency(data.profitNLoss, data.currency, true)}
          </div>
          <div className="stat-desc">Total profit - Total expense</div>
        </div>
      </div>

      <div className="flex  justify-between w-full flex-col lg:flex-row gap-5 mt-10 ">
        <div className="lg:w-[38vw] w-full">
          <div className="flex mb-4 items-center justify-between">
            <div className="flex sm:flex-row flex-col items-center z-20 lg:gap-3 gap-2">
              <h2 className="label font-semibold text-xs sm:text-sm">
                Insights
              </h2>
              {chart === "spending" && (
                <span
                  className="tooltip tooltip-bottom before:w-64 before:left-14   tooltip-info"
                  data-tip={`Only Category Group “Cost” \n  Hidden transactions excluded`}
                >
                  <PiInfoBold className="text-info" size={22} />
                </span>
              )}

              <Dropdown
                title={year}
                dropdownClass="w-fit"
                buttonClass="btn btn-neutral btn-xs sm:btn-sm rounded-sm capitalize"
                content={pastThreeYears.map((t) => (
                  <button
                    className="capitalize"
                    value={t}
                    onClick={handleSetYear(t)}
                    onTouchEnd={handleSetYear(t)}
                  >
                    {t}
                  </button>
                ))}
              />
            </div>

            <Dropdown
              dropdownClass="w-44 sm:w-52"
              title={formatLabel(chart)}
              buttonClass="capitalize text-xs sm:text-sm btn-sm sm:btn-md"
              content={charts.map((t) => (
                <button
                  className="capitalize"
                  value={t}
                  onClick={() => setChart(t)}
                  onTouchEnd={() => setChart(t)}
                >
                  {formatLabel(t)}
                </button>
              ))}
            />
          </div>
          {chartContent[chart]}
        </div>
        <div className="lg:w-[38vw] w-full">
          <PieChart
            {...{ categories: data.categories, currency: data.currency }}
          />
        </div>
      </div>
      <div className="w-full  mt-10 gap-5 flex-col md:flex-row  rounded-lg flex">
        <div className="flex-1">
          <div className="flex gap-2 lg:gap-3 mb-3 items-center">
            {tabs.map((t) => (
              <button
                onClick={() => setTab(t.name)}
                key={t.name}
                className={`btn ${t.name == tab ? "btn-primary" : ""}`}
              >
                {t.icon}
                Recent {t.name}
              </button>
            ))}
          </div>

          {content[tab]}
        </div>
        <div className="flex-1">
          <div className="mb-3 btn">Recent Transactions</div>
          <RecentTransactions {...{ transactions: data.transactions }} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
