import { FC } from "react";

type Props = {
  onChange: (value: boolean) => any | Promise<any>;
  value?: boolean;
  text?: string;
  className?: string;
};

const Switch: FC<Props> = ({
  onChange: handleChange,
  value,
  text,
  className = "",
}) => {
  return (
    <label className={`cursor-pointer label w-fit ${className} `}>
      {text && (
        <span className={`label-text  ${value ? "text-primary" : ""} `}>
          {text}
        </span>
      )}
      <input
        type="checkbox"
        className="toggle toggle-primary"
        checked={value}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </label>
  );
};

export default Switch;
