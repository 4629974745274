import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { colorPalette } from "@/lib";
import { Enums } from "@/types";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { RouterOutputs, api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import MapCategories from "./MapCategories";
import SubCategories from "./SubCagories";

const groups: Enums["CategoryGroup"][] = ["cost", "internal", "revenue"];

const defaultValue = {
  group: "cost" as Enums["CategoryGroup"],
  name: "",
  description: "" as string | null,
  color: "red",
  id: null as string | null,
};

type Props = {
  categories: RouterOutputs["organizations"]["categories"];
  accountingCategories: RouterOutputs["accounting"]["categories"];
  isLoading: boolean;
};

const AccountingCategories: FC<Props> = ({
  accountingCategories,
  categories,
  isLoading,
}) => {
  const { organization } = useOrganization();

  const nav = useNavigate();

  const { inputs, handleChange, setInputs, setValue } = useForm(defaultValue);

  const upsert = api.accounting.upsertCategory.useMutation();

  const utils = api.useUtils();
  const [params] = useSearchParams();
  const isOpen = params.has("new");

  useEffect(() => {
    if (!isOpen) setInputs(defaultValue);
  }, [isOpen]);

  const deleteCat = api.utils.deleteCategory.useMutation();

  if (!organization?.accountingApp) {
    return <p className="label">No accounting app available</p>;
  }

  if (isLoading) return <Rows />;

  const handleDelete = async (id: string) => {
    if (!organization?.accountingApp) return;

    const _confirm = confirm(`Are you sure you want to delete the`);
    if (!_confirm) return;

    await deleteCat.mutateAsync(id);
    utils.accounting.categories.setData(
      organization.accountingApp.id,
      (p) => p?.filter((c) => c.id !== id)
    );
  };

  const handleCreate = async () => {
    if (!organization?.accountingApp) return;

    const res = await upsert.mutateAsync({
      ...inputs,
      accountingAppId: organization.accountingApp.id,
    });

    const _cat = { ...res, subCategories: [], mappedTo: [] };

    utils.accounting.categories.setData(organization.accountingApp.id, (p) => {
      if (!p) return [_cat];
      return [_cat, ...p.filter((c) => c.id !== res.id)];
    });
    nav("/organization/categories");
  };

  const handleUpdate = async (cat: typeof inputs) => {
    setInputs(cat);
    nav("/organization/categories");
  };

  return (
    <div className="">
      <div className="gap-2 content-start grid">
        {accountingCategories.map((category) => (
          <Collapsible
            header={
              <div
                className="flex gap-6  items-center w-full"
                title={category.description || ""}
              >
                <div className="flex  w-full items-center gap-4">
                  <span
                    className="w-4 h-4 rounded-full"
                    style={{
                      backgroundColor: category.color,
                    }}
                  />
                  <div className=" flex items-center justify-between flex-1">
                    <div className="">
                      <p> {category.name}</p>
                      <p className="btn capitalize transform-none btn-xs">
                        {category.group}
                      </p>
                    </div>
                    <p className="text-xs">
                      {category.subCategories.length} Subcategories
                    </p>
                  </div>
                </div>

                <button
                  className="flex z-10 items-center gap-2 "
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={(e) => handleUpdate(category)}
                  >
                    <MdEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(category.id)}
                    className="btn btn-error btn-sm"
                  >
                    <LoadingSpin loading={deleteCat.isLoading} />
                    <MdDelete />
                  </button>
                </button>
              </div>
            }
          >
            <MapCategories {...{ category, categories, isRegular: false }} />
            <SubCategories
              mappableSubCategories={categories
                .filter((c) => category.mappedTo.some((ac) => ac.id === c.id))
                .map((c) => c.subCategories)
                .flat()}
              category={category}
              isRegular={false}
            />
          </Collapsible>
        ))}
      </div>

      <Drawer
        title={`${organization.accountingApp.name} Category`}
        isOpen={isOpen}
        onClose={() => nav("/organization/categories")}
      >
        <div className="grid gap-4">
          <div>
            <p className="label">Group</p>
            <div className="flex join">
              {groups.map((g) => (
                <button
                  onClick={() => setValue("group", g)}
                  key={g}
                  className={`btn flex-1 btn-sm join-item ${
                    inputs.group === g ? "btn-primary" : ""
                  }`}
                >
                  {g}
                </button>
              ))}
            </div>
          </div>

          <label htmlFor="name">
            <p className="label">Name</p>
            <input
              placeholder="eg. Food"
              type="text"
              className="input input-bordered w-full"
              onChange={handleChange("name")}
              value={inputs.name}
            />
          </label>
          <label htmlFor="name">
            <p className="label">Description</p>
            <textarea
              onChange={handleChange("description")}
              placeholder="eg. Food related expenses"
              name="description"
              value={inputs.description || ""}
              className="input input-bordered w-full"
            />
          </label>

          <div>
            <p className="label">Color</p>
            <div className="grid grid-cols-9 w-full gap-1">
              {colorPalette.map((h) => (
                <button
                  key={h}
                  className={`btn-square  btn-xs  ${
                    inputs.color === h ? "rounded-full" : "rounded-sm"
                  }`}
                  style={{ backgroundColor: h }}
                  onClick={() => setValue("color", h)}
                ></button>
              ))}
            </div>
          </div>
          <button className="btn btn-primary" onClick={handleCreate}>
            <LoadingSpin loading={upsert.isLoading} />
            Save
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default AccountingCategories;
