import { ChangeEventHandler, FC } from "react";

type Props = {
  placeholder: string;
  title?: string;

  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: any;
  isError?: any;
  className?: string;
};

const FormInput: FC<Props> = (props) => {
  const { placeholder, title, onChange, value, isError } = props;
  return (
    <div className={props.className}>
      {title && <p className="divider divider-start">{title}</p>}
      <input
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
        className={`input w-full shadow-sm  ${
          isError ? "input-error" : "input-bordered"
        }`}
      />
    </div>
  );
};

export default FormInput;
