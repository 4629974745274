import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import MultiRangeSlider from "multi-range-slider-react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { MdDone, MdOutlineClear } from "react-icons/md";

import { ExpenseStatusType, expenseStatuses } from "@/lib";
import { ExpenseFilters } from "@/types/validation";
import { decodeBase64ToObject } from "@/utils/helper";
import useForm from "@/utils/useForm";
import {
  MultiSelect,
  MultiSelectItem,
  SearchSelect,
  SearchSelectItem,
} from "@tremor/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpenseFilter } from "./Expenses";

type Props = {
  setShowFilters: (showFilters: boolean) => void;
  ranges: {
    min: number;
    max: number;
  };
  unCategorized: number;
  setFilters: Dispatch<SetStateAction<ExpenseFilter>>;
};

type Filter = {
  unCategorized?: boolean;
  team_members?: string;
  eventId?: string;
  assignedTo?: string;
  statuses?: ExpenseStatusType[];
  categories?: string[];
};

const SelectExpenseFilters: FC<Props> = ({
  setShowFilters,
  unCategorized,
  setFilters,
  ranges: { max, min },
}) => {
  const { organizationId = "" } = useOrganization();

  const { data = [] } = api.utils.categories.useQuery(organizationId);
  const { data: events = [] } = api.expenses.events.useQuery(organizationId);

  const { data: members = [] } =
    api.organizations.teamMembers.useQuery(organizationId);

  const nav = useNavigate();

  const location = useLocation();

  const { inputs, setInputs, setValue } = useForm<Filter>({});

  const [values, setValues] = useState<number[]>([min, max]);

  const handleApply = () => {
    let [min, max] = values;

    const validRange = !!min || !!max;

    setFilters({
      ...inputs,
      total: validRange ? { max, min } : null,
    });
    setShowFilters(false);
  };

  useEffect(() => {
    const q = new URLSearchParams(location.search);

    const _filters = q.get("filters");

    if (!_filters) return;

    const filters = decodeBase64ToObject(_filters);

    const validate = ExpenseFilters.safeParse(filters);

    if (validate.success) {
      const { total } = validate.data;

      if (total) setValues([total.min, total.max]);

      setInputs((p) => ({ ...p, ...validate.data }));
      setFilters((p) => ({ ...p, ...validate.data }));
    }
  }, [location.search]);

  const handleClear = () => {
    setFilters(null);
    setShowFilters(false);
    setInputs({});
    setValues([min, max]);

    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("filters");

    const parentRoute = location.pathname + "?" + queryParams.toString();
    nav(parentRoute);
  };

  return (
    <div>
      <div className="flex  rounded-md md:flex-col flex-col gap-2 md:gap-5 items-start">
        <div className="flex flex-col w-full  gap-3  items-center">
          <div className="flex items-center w-full">
            <button
              className={`btn btn-secondary w-full ${
                inputs.unCategorized ? "" : "btn-outline"
              }`}
              onClick={() =>
                setInputs((p) => ({
                  ...p,
                  unCategorized: !p.unCategorized,
                }))
              }
            >
              un-categorized ({unCategorized})
            </button>
          </div>
          <div className=" w-full">
            <p className="label label-text">Amount range</p>

            <MultiRangeSlider
              min={min}
              max={max}
              step={5}
              minValue={values[0] || (min === max ? 0 : min)}
              maxValue={values[1] || max}
              onChange={(e) => setValues([e.minValue, e.maxValue])}
              ruler={false}
              barInnerColor="#475AFF"
            />
          </div>
        </div>

        <div className="flex flex-col w-full gap-3 items-center">
          <div className="w-full">
            <p className="label label-text">Categories</p>

            <MultiSelect
              value={inputs.categories}
              onValueChange={(e) => setValue("categories", e)}
            >
              {data.map((e) => (
                <MultiSelectItem key={e.id} value={e.id}>
                  {e.name}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
          <div className="w-full">
            <p className="label label-text">Status</p>

            <MultiSelect
              value={inputs.statuses}
              onValueChange={(e) =>
                setValue("statuses", e as ExpenseStatusType[])
              }
            >
              {expenseStatuses.map((e) => (
                <MultiSelectItem key={e} value={e}>
                  {e}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
          <div className="w-full">
            <p className="label label-text">Assigned To</p>
            <SearchSelect
              value={inputs.assignedTo}
              onValueChange={(e) => setValue("assignedTo", e)}
            >
              {members.map((e) => (
                <SearchSelectItem key={e.user.id} value={e.user.id}>
                  {e.user.name}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>
          <div className="w-full">
            <p className="label label-text">Event</p>
            <SearchSelect
              value={inputs.eventId}
              onValueChange={(e) => setInputs((p) => ({ ...p, eventId: e }))}
            >
              {events.map((e) => (
                <SearchSelectItem key={e.id} value={e.id}>
                  {e.name}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>

          <div className="w-full">
            <p className="label label-text">Staffs </p>
            <input
              type="text"
              className="input input-bordered w-full"
              placeholder="Search for a customer"
              value={inputs.team_members}
              onChange={(e) =>
                setInputs((p) => ({ ...p, team_members: e.target.value }))
              }
            />
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-3 w-full mt-3">
        <button onClick={handleApply} className="btn btn-primary flex-1">
          <MdDone />
          Apply
        </button>
        <button onClick={handleClear} className="btn btn-error btn-outline">
          <MdOutlineClear /> Reset
        </button>
      </div>
    </div>
  );
};

export default SelectExpenseFilters;
