import { PageTitle } from "../PageTitle";

type Props = {};

const DashboardSkeleton = (props: Props) => {
  return (
    <div>
      <PageTitle title="Dashboard" />

      <div className="stats   min-w-fit grid grid-rows-4 lg:grid-rows-2 2xl:grid-rows-1 w-full shadow mb-12">
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
      </div>

      <div className="flex  justify-around w-full flex-col lg:flex-row gap-5 mt-10 ">
        <div className="flex-1 grid gap-6">
          <div className="grid w-full gap-2">
            <span className="skeleton h-4 w-1/3" />
            <span className="skeleton h-4 w-2/3" />
          </div>
          <div className="rounded-lg bg-base-100/10 h-96 items-end flex justify-center gap-4 flex-1">
            <span className="skeleton rounded-t-md rounded-none h-full w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[40%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[70%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[30%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[60%] w-8 " />

            <span className="skeleton rounded-t-md rounded-none h-[50%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[80%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[20%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[90%] w-8 " />
            <span className="skeleton rounded-t-md rounded-none h-[32%] w-8 " />
          </div>
        </div>
        <div className="flex-1 gap-6 grid">
          <div className="flex justify-between">
            <div className="grid w-full gap-2">
              <span className="skeleton h-4 w-1/3" />
              <span className="skeleton h-4 w-2/3" />
            </div>
            <span className="skeleton w-1/4" />
          </div>
          <div className="flex justify-center items-center">
            <span className="skeleton absolute rounded-full aspect-square bg-base-100  flex- h-1/2  z-10" />
            <span className="skeleton rounded-full aspect-square  flex- h-96 w-96" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
