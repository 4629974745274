import DisplayName from "@/components/DisplayName";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, formatId, handleSelectFromList } from "@/utils/helper";
import { RouterInputs, RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import AccountingStatus from "./AccountingStatus";

type Props = {
  list: RouterOutputs["invoices"]["invoices"];
  params: RouterInputs["invoices"]["invoices"];
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
};

const InvoiceListing: FC<Props> = ({
  list,
  selection,
  setSelection,
  totalSelected,
  params,
}) => {
  const nav = useNavigate();

  const view = (id: string) => () => nav(`/invoice/${id}`);

  return (
    <tbody>
      {list.map((invoice, i, { length }) => (
        <tr key={invoice.id} className="hover cursor-pointer ">
          <th className="hidden md:table-cell">
            <Checkbox
              checked={!!selection[invoice.id]}
              onChange={() => {
                setSelection(
                  handleSelectFromList(invoice, list, totalSelected)
                );
              }}
              className="sm:block hidden -ml-1 mr-2"
            />
          </th>

          <td className="md:hidden" onClick={view(invoice.id)}>
            <div className=" flex text-center flex-col">
              <span className="text-sm font-semibold">
                {format(new Date(invoice.dueDate), "dd")}
              </span>
              <span className="text-sm text-gray-500  ">
                {format(new Date(invoice.dueDate), " MMM")}
              </span>
            </div>
          </td>
          <td onClick={view(invoice.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <DisplayName text={invoice.customer.name} className="" />
              <p className="text-[12px]">
                {formatId(invoice.invoice_number, "INV")}
              </p>
            </div>
            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {invoice.status}
            </div>
          </td>
          <td onClick={view(invoice.id)}>
            <div className="w-full text-right pr-2">
              <p className="text-right">
                {formatCurrency(invoice.total, invoice.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(invoice.totalVat, invoice.currency)}/
                {formatCurrency(invoice.totalAmount, invoice.currency)}
              </p>
            </div>
          </td>
          <td onClick={view(invoice.id)}>
            <p className="flex md:flex-row text-right flex-col w-full justify-end pr-2 gap-1">
              {format(new Date(invoice.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(invoice.createdAt), "yyyy")}
              </span>
            </p>
          </td>
          <td onClick={view(invoice.id)}>
            <p className="flex md:flex-row flex-col  w-full text-center pr-2 gap-1 justify-center">
              {format(new Date(invoice.dueDate), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(invoice.dueDate), "yyyy")}
              </span>
            </p>
          </td>
          <td style={{ zIndex: length - i }}>
            <AccountingStatus
              {...{
                invoiceId: invoice.id,
                status: invoice.accountingStatus,
                params,
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default InvoiceListing;
