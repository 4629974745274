import { FC } from "react";

type Props = {
  count?: number;
  className?: string;
};

const Rows: FC<Props> = ({ count = 10, className = "" }) => {
  return (
    <div
      className={`flex w-full p-4  rounded-lg flex-col gap-4 bg-base-100 ${className} `}
    >
      {Array.from({ length: count }, (_, i) => i).map((i) => (
        <span key={i} className="skeleton rounded-lg w-full h-8" />
      ))}
    </div>
  );
};

export default Rows;
