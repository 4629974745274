import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import { BsThreeDots } from "react-icons/bs";
import { RiMastercardFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import CardDetails from "./CardDetails";

type Props = {
  card: RouterOutputs["swan"]["card"]["list"][number];
};

const getGradient = (spending: number, limit: number) => {
  const percentage = (spending / limit) * 100;
  if (percentage < 25) return "progress-success";
  if (percentage < 50) return "progress-info";
  if (percentage < 75) return "progress-warning";
  return "progress-error";
};

const Card: FC<Props> = ({ card }) => {
  const nav = useNavigate();

  return (
    <tr
      key={card.id}
      className="cursor-pointer border-white/5"
      onClick={() => nav(`/pro-account/cards/${card.id}`)}
    >
      <th className="flex items-center justify-between gap-2">
        <RiMastercardFill size={30} />
        <p className="btn btn-xs btn-outline btn-info">{card.type}</p>
      </th>
      <td>{card.membership.team_member.user.name}</td>
      <td>{card.name}</td>
      <td>{card.cardMaskedNumber}</td>
      <td>
        <div className="min-w-full w-max  text-right text-xs">
          {formatCurrency(+card.spending, card.currency)} /{" "}
          {formatCurrency(+card.limitAmount, card.currency)}
        </div>
        <progress
          className={`progress ${getGradient(
            +card.spending,
            +card.limitAmount
          )} w-full h-1`}
          value={(+card.spending / +card.limitAmount) * 100}
          max={100}
        />
      </td>
      <td>
        <span className="btn btn-success btn-outline btn-xs text-xs">
          {card.status}
        </span>
      </td>
      <th>
        <button>
          <BsThreeDots />
        </button>
      </th>

      <CardDetails card={card} />
    </tr>
  );
};

export default Card;
