import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as z from "zod";

type Props = {};

export const fields = ["name", "code", "type", "basis", "description"] as const;

const CreateFinancialAccount = (props: Props) => {
  const { inputs, setErrors, errors, setInputs, handleChange } = useForm<
    Partial<CreateFinancialAccount>
  >({});
  const { organizationId } = useOrganization<true>();

  const create = api.organizations.financialAccount.create.useMutation();
  const utils = api.useUtils();

  const [params] = useSearchParams();
  const nav = useNavigate();
  const isOpen = params.has("new");

  const handleCreate = async () => {
    const valid = ValidateFinancialAccount.safeParse(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await create.mutateAsync({
      ...valid.data,
      organizationId: organizationId,
    });

    utils.organizations.financialAccount.list.setData(
      organizationId,
      (p = []) => {
        return [res, ...p];
      }
    );
    nav("/accounting/financial-accounts");
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/accounting/financial-accounts")}
      title="Create Financial Account"
      className="grid gap-4"
    >
      <div className="grid grid-cols-2 gap-2">
        {fields.map((field) => (
          <FormInput
            key={field}
            placeholder={`Account ${field}`}
            title={field}
            value={inputs[field]}
            onChange={handleChange(field)}
            isError={errors[field]}
            className="last:col-span-2 capitalize"
          />
        ))}
      </div>
      <button onClick={handleCreate} className="btn btn-primary btn-sm mt-4">
        <LoadingSpin loading={create.isLoading} />
        Create
      </button>
    </Drawer>
  );
};

export default CreateFinancialAccount;

export const ValidateFinancialAccount = z.object({
  name: z.string(),
  code: z.string(),
  type: z.string(),
  basis: z.string(),
  description: z.string().optional().nullable(),
});

export type CreateFinancialAccount = z.infer<typeof ValidateFinancialAccount>;
