import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { AiFillApi, AiFillEdit, AiTwotoneBank } from "react-icons/ai";
import { HiChevronRight, HiLibrary } from "react-icons/hi";

type Props = {
  setAccountInfo: Dispatch<SetStateAction<AccountInfo | null>>;
};

const tabs = [
  {
    label: "Connected Banks",
    name: "connectedBanks",
    icon: <AiFillApi />,
  },
  {
    label: "Manual Banks",
    name: "manualBanks",
    icon: <AiFillEdit />,
  },
] as const;

type Tab = (typeof tabs)[number]["name"];

export type AccountInfo = {
  name: string;
  currency: string;
  countryCode: string;
  type: string | null;
  bankAddress: string | null;

  IBAN: string | null;
  SWIFT: string | null;
  ACCOUNT_NUMBER: string | null;
  ROUTING_NUMBER: string | null;
  SORT_CODE: string | null;
  BBAN: string | null;
};

const SelectOrganizationAccount: FC<Props> = ({ setAccountInfo }) => {
  const { organizationId = "" } = useOrganization();
  const [tab, setTab] = useState<Tab>("connectedBanks");

  const { data: accounts = [], isLoading: accountsLoading } =
    api.manualAccounts.organizationAccounts.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const { data: banks = [], isLoading: banksLoading } =
    api.banks.banks.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const count = {
    connectedBanks: banks.length,
    manualBanks: accounts.length,
  } as const;

  const isLoading = {
    connectedBanks: banksLoading,
    manualBanks: accountsLoading,
  } as const;

  if (banksLoading && accountsLoading) return <Spinner />;

  return (
    <div className="">
      <div className="flex mb-4 flex-1 gap-2 lg:gap-3 items-center">
        {tabs.map((t) => (
          <div
            key={t.name}
            onClick={() => setTab(t.name)}
            className={`btn ${t.name === tab ? "btn-primary" : ""}`}
          >
            {t.icon}
            {t.label} {!!count[t.name] && `(${count[t.name]})`}
            <LoadingSpin loading={isLoading[t.name]} />
          </div>
        ))}
      </div>

      {tab === "connectedBanks" && (
        <div className="grid gap-4">
          {banks.map((b) => {
            return (
              <div key={b.id} className="grid gap-1">
                <div className="btn transform-none  btn-neutral w-full justify-between">
                  {b.institution_logo ? (
                    <p className="flex items-center gap-2">
                      <img
                        src={b.institution_logo}
                        className="w-10  object-contain"
                      />
                      {b.name}
                    </p>
                  ) : (
                    <p className="flex items-center gap-2">
                      <HiLibrary /> {b.name}
                    </p>
                  )}
                </div>
                <div className="grid gap-1 ">
                  {b.accounts.map((a) => {
                    return (
                      <button
                        onClick={() =>
                          setAccountInfo({
                            ...a,
                            countryCode: b.country.iso2,
                          })
                        }
                        key={a.id}
                        className="btn bg-base-100 hover:bg-base-300 w-full justify-between text-sm"
                      >
                        <div className="text-left">
                          <p className="font-semibold">{a.name || "No Name"}</p>
                          <p className="text-xs">
                            {a.IBAN || a.ACCOUNT_NUMBER || a.ROUTING_NUMBER}
                          </p>
                        </div>
                        <div className="text-right items-center gap-3 flex">
                          <div>
                            <p className=" text-sm font-medium">
                              {formatCurrency(a.balance || 0, a.currency)}
                            </p>
                            <p className="text-xs font-medium">
                              {formatCurrency(a.converted || 0, b.currency)}
                            </p>
                          </div>

                          <HiChevronRight
                            size={22}
                            className="text-base-content"
                          />
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {tab === "manualBanks" && (
        <div className="grid gap-1 my-4">
          {accounts.map((a) => (
            <button
              className="btn h-fit  w-full justify-between"
              key={a.id}
              onClick={() =>
                setAccountInfo({
                  ...a,
                  countryCode: a.country.iso2,
                })
              }
            >
              <AiTwotoneBank />
              <div className="flex-1 w-1/3 break-words text-left">
                <p className="lg:text-sm text-xs font-semibold">{a.name}</p>
                <p className="text-xs">
                  {a.IBAN || a.ACCOUNT_NUMBER || a.ROUTING_NUMBER}
                </p>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectOrganizationAccount;
