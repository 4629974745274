import Dropdown from "@/components/Dropdown";
import Select from "@/components/Select";
import { months, pastThreeYears } from "@/lib";
import { PageTitle } from "@/ui/PageTitle";
import { ReactNode, useState } from "react";
import { FaMoneyBillWave } from "react-icons/fa";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";
import MerchantReports from "./MerchantReports";
import ProfitNLossReport from "./ProfitAndLossReport";
import Spending from "./spending/Spending";

const tabs = [
  {
    name: "merchantReports" as const,
    icon: <TbReportAnalytics size="20" />,
    title: "Merchant Reports",
  },
  {
    name: "profitNLoss" as const,
    icon: <TbReportMoney size="20" />,
    title: "Profit & Loss Report",
  },
  {
    name: "spending" as const,
    icon: <FaMoneyBillWave />,
    title: "Spending Report",
  },
];

type Tab = (typeof tabs)[number]["name"];

const Reports = () => {
  const [tab, setTab] = useState<Tab>("merchantReports");

  const [year, setYear] = useState(pastThreeYears[0]);
  const [month, setMonth] = useState(new Date().getMonth());

  const content: Record<Tab, ReactNode> = {
    merchantReports: <MerchantReports {...{ setYear, year }} />,
    profitNLoss: <ProfitNLossReport {...{ setYear, year }} />,
    spending: <Spending {...{ year, month }} />,
  };

  return (
    <div id="s-2">
      <PageTitle title="Reports" />
      <div className="flex gap-4 items-center justify-between">
        <div className=" lg:flex hidden  p-3 items-center w-fit tabs tabs-boxed">
          {tabs.map((t) => (
            <button
              key={t.name}
              onClick={() => setTab(t.name)}
              className={`tab gap-2 ${t.name === tab ? "tab-active" : ""}`}
            >
              {t.icon}
              {t.title}
            </button>
          ))}
        </div>

        <Dropdown
          dropdownClass="w-full max-w-sm z-50 lg:hidden"
          title={tab}
          buttonClass="capitalize"
          content={tabs.map((t) => (
            <button
              className="capitalize"
              onClick={() => setTab(t.name)}
              onTouchEnd={() => setTab(t.name)}
              key={t.name}
            >
              {t.icon}
              {t.title}
            </button>
          ))}
        />

        <div className="join ">
          <Select
            value={String(year)}
            options={pastThreeYears.map((y) => ({
              label: String(y),
              value: String(y),
            }))}
            onChange={(e) => setYear(+e)}
            className="z-20 w-fit"
            dropdownClass="join-item  dropdown-bottom"
            buttonClass="join-item"
          />
          {tab === "spending" && (
            <Select
              options={months.map((m) => ({
                value: m.value.toString(),
                label: m.fullName,
              }))}
              className="w-fit"
              buttonClass="join-item"
              dropdownClass="join-item  dropdown-bottom"
              value={month.toString()}
              onChange={(e) => setMonth(+e)}
            />
          )}
        </div>
      </div>

      <div className="my-6 mx-auto ">{content[tab]}</div>
    </div>
  );
};

export default Reports;
