import { DiGoogleAnalytics } from "react-icons/di";
import { FaReceipt } from "react-icons/fa";
import { MdAccountBalance, MdTaskAlt } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";

import { GiPayMoney } from "react-icons/gi";
import { GrConnect, GrTransaction } from "react-icons/gr";
import { HiDocumentDuplicate } from "react-icons/hi";
import { IoCardSharp } from "react-icons/io5";
import { PiFoldersFill } from "react-icons/pi";
import { RiContactsBookFill, RiHome4Fill, RiTeamFill } from "react-icons/ri";
import {
  TbFileInvoice,
  TbReceiptTax,
  TbSettingsAutomation,
} from "react-icons/tb";

export const publicRoutes = [
  "/login",
  "/forgot-password",
  "/reset-password",
  "/register/sign-up",
  "/register/invite",
  "/register/company-details",
  "/register/personal-details",
  "/invitation/:inviteId",
];

const nav = {
  accounts: (end?: boolean) => ({
    name: "Accounts",
    Icon: MdAccountBalance,
    path: "/accounts/dynt",
    end,
    routes: [
      "/accounts/:tab",
      // "/accounts/connect",
      // "/accounts/connect/auth",
      // "/accounts/connect/re-auth",
    ],
  }),
};

export const navigation = {
  starter: [
    {
      name: "Dashboard",
      Icon: RiHome4Fill,
      path: "/",
      routes: ["/", "/profile", "/reset-password"],
    },
    {
      name: "My Documents",
      Icon: HiDocumentDuplicate,
      path: "/documents",
      routes: ["/documents"],
    },
    nav.accounts(false),
    {
      name: "Reports",
      Icon: DiGoogleAnalytics,
      path: "/reports",
      routes: ["/reports"],
      end: true,
    },
    {
      name: "Invoices",
      Icon: TbFileInvoice,
      path: "/invoices",
      routes: [
        "/invoices",
        "/invoices/create",
        "/invoices/update/:invoiceId",
        "/invoice/:invoiceId",
      ],
    },
    {
      name: "Bills",
      Icon: TbReceiptTax,
      path: "/bills",
      routes: [
        "/bills",
        "/bills/create",
        "/bills/update/:billId",
        "/bill/:billId",
      ],
    },
    {
      name: "Transactions",
      Icon: GrTransaction,
      path: "/transactions/dynt",
      routes: ["/transactions/:tab", "/transactions/:tab/:id"],
    },
    {
      name: "Partners",
      Icon: RiContactsBookFill,
      path: "/partners",
      routes: ["/partners"],
    },
    {
      name: "Pro Account",
      Icon: IoCardSharp,
      path: "/pro-account",
      routes: [
        "/pro-account",
        "/pro-account/:tab",
        "/pro-account/:tab/:id",
        "/pro-account/:tab/:id/:subTab",
        "/pro-account/:tab/:id/:subTab/:subId",
      ],
    },
    {
      name: "Accounting",
      Icon: PiFoldersFill,
      path: "/accounting/invoices",
      end: true,
      routes: ["/accounting/:tab", "/accounting/:tab/:id"],
    },
    { name: "Tasks", Icon: MdTaskAlt, path: "/tasks", routes: ["/tasks"] },
    {
      name: "Integration",
      Icon: GrConnect,
      path: "/integration",
      routes: ["/integration"],
    },
    {
      name: "Settings",
      Icon: VscSettings,
      path: "/organization",
      routes: [
        "/organization",
        "/organization/:tab",
        "/organization/:tab/:id",
        "/organizations/create",
      ],
    },
  ],
  pro: [
    {
      name: "Dashboard",
      Icon: RiHome4Fill,
      path: "/",
      routes: ["/", "/profile", "/reset-password"],
    },
    {
      name: "My Documents",
      Icon: HiDocumentDuplicate,
      path: "/documents",
      routes: ["/documents"],
    },
    nav.accounts(false),
    {
      name: "Reports",
      Icon: DiGoogleAnalytics,
      path: "/reports",
      routes: ["/reports"],
      end: true,
    },
    {
      name: "Invoices",
      Icon: TbFileInvoice,
      path: "/invoices",
      routes: [
        "/invoices",
        "/invoices/create",
        "/invoices/update/:invoiceId",
        "/invoice/:invoiceId",
      ],
    },
    {
      name: "Bills",
      Icon: TbReceiptTax,
      path: "/bills",
      routes: [
        "/bills",
        "/bills/create",
        "/bills/update/:billId",
        "/bill/:billId",
      ],
    },

    {
      name: "Expenses",
      Icon: FaReceipt,
      path: "/expenses",
      routes: [
        "/expenses",
        "/expenses/create",
        "/expenses/update/:expenseId",
        "/expense/:expenseId",
      ],
    },
    {
      name: "Transactions",
      Icon: GrTransaction,
      path: "/transactions/dynt",
      routes: ["/transactions/:tab", "/transactions/:tab/:id"],
    },
    {
      name: "Payroll",
      Icon: GiPayMoney,
      path: "/payroll",
      routes: ["/payroll"],
    },

    {
      name: "Pro Account",
      Icon: IoCardSharp,
      path: "/pro-account",
      routes: [
        "/pro-account",
        "/pro-account/:tab",
        "/pro-account/:tab/:id",
        "/pro-account/:tab/:id/:subTab",
        "/pro-account/:tab/:id/:subTab/:subId",
      ],
    },
    {
      name: "Accounting",
      Icon: PiFoldersFill,
      path: "/accounting/invoices",
      end: true,
      routes: ["/accounting/:tab", "/accounting/:tab/:id"],
    },
    { name: "Tasks", Icon: MdTaskAlt, path: "/tasks", routes: ["/tasks"] },
    {
      name: "People",
      Icon: RiTeamFill,
      path: "/people",
      end: true,
      routes: ["/people"],
    },
    {
      name: "Partners",
      Icon: RiContactsBookFill,
      path: "/partners",
      routes: ["/partners"],
    },
    {
      name: "Integration",
      Icon: GrConnect,
      path: "/integration",
      routes: ["/integration"],
    },
    {
      name: "Automation",
      Icon: TbSettingsAutomation,
      path: "/automation",
      routes: ["/automation", "/rules/create", "/rules/:id"],
    },
    {
      name: "Settings",
      Icon: VscSettings,
      path: "/organization",
      routes: [
        "/organization",
        "/organization/:tab",
        "/organization/:tab/:id",
        "/organizations/create",
      ],
    },
  ],
};
