import { useOrganization } from "@/context/OrganizationContext";
import { Vendor } from "@/types";
import Collapsible from "@/ui/Collapsible";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { useState } from "react";

import { useUser } from "@/context/UserContext";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AccountInfo } from "../invoices/SelectOrganizationAccount";
import BillDetails, { CreateBillProps } from "./BillDetails";
import SelectVendor from "./SelectVendor";
import SelectVendorAccount from "./SelectVendorAccount";

const CreateBill = () => {
  const { organizationId } = useOrganization();
  const { userId } = useUser();
  const create = api.bills.createBill.useMutation();
  const nav = useNavigate();

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  const [accountInfo, setAccountInfo] = useState<AccountInfo>();

  const handleCreate = async ({
    rows,
    customId,
    dueDate,
    currency,
    files,
    terms,
  }: CreateBillProps) => {
    if (!selectedVendor || !organizationId || !userId) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    const bill = await create.mutateAsync({
      vendorId: selectedVendor.id,
      rows,
      dueDate,
      terms,
      total: totalAmount + totalVat,
      totalAmount,
      files,
      totalVat,
      customId,
      currency,
      organizationId,
      accountInfo,
    });

    toast.success("Bill created successfully");

    nav(`/bill/${bill.id}`);
  };

  return (
    <div className="grid content-start gap-4">
      <PageTitle title="Create Bill" />
      <Collapsible
        header={
          <div>
            {!selectedVendor ? (
              <p className="font-semibold ">Choose a Vendor:</p>
            ) : (
              <p className="text-sm flex items-center gap-2">
                Selected Vendor:{" "}
                <span className="font-bold text-base">
                  {selectedVendor.name}
                </span>{" "}
              </p>
            )}
          </div>
        }
        render={(toggle) => (
          <SelectVendor
            {...{
              setSelectedVendor: (a) => {
                setSelectedVendor(a);
                toggle(false);
              },
            }}
          />
        )}
      />

      {selectedVendor && (
        <>
          <Collapsible
            header={
              <div>
                {!accountInfo ? (
                  <p className="font-semibold ">Choose a bank account:</p>
                ) : (
                  <p className="text-sm flex items-center gap-2">
                    Selected account:{" "}
                    <span className="font-bold text-base">
                      {accountInfo.name} ({accountInfo.currency})
                    </span>
                  </p>
                )}
              </div>
            }
            render={(toggle) => (
              <SelectVendorAccount
                {...{
                  setAccountInfo: (a) => {
                    setAccountInfo(a);
                    toggle(false);
                  },
                  vendorId: selectedVendor.id,
                }}
              />
            )}
          />
          <BillDetails {...{ handleCreate }} />
        </>
      )}
    </div>
  );
};

export default CreateBill;
