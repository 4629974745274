import { ReactNode, useEffect, useMemo, useState } from "react";

import { json2csv } from "json-2-csv";
import { useLocation, useParams } from "react-router-dom";

import Bills from "@/pages/accounting/bills/Bills";
import Invoices from "@/pages/accounting/invoices/Invoices";

import Transactions from "@/pages/accounting/transactions/Transactions";
import { DatePeriod } from "@/types/validation";
import { PageTitle } from "@/ui/PageTitle";
import { decodeBase64ToObject, saveFile } from "@/utils/helper";
import format from "date-fns/format";
import { HiDownload } from "react-icons/hi";
import { MdFilterListAlt } from "react-icons/md";

import Tabs from "@/components/Tabs";
import OrgExpenses from "@/pages/accounting/expenses/Expenses";
import endOfYear from "date-fns/endOfYear";
import startOfYear from "date-fns/startOfYear";
import TransactionList from "../swan/Transactions/TransactionList";
import FinancialAccountList from "./financialAccount/FinancialAccountList";

const tabs = [
  "invoices",
  "bills",
  "banks",
  "expenses",
  "transactions",
  "financial-accounts",
] as const;

type Tab = (typeof tabs)[number];

export type DatePeriod = {
  startDate: Date;
  endDate: Date;
};

const Accounting = () => {
  const location = useLocation();
  const [selection, setSelection] = useState<Record<string, any>>({});
  const [showFilters, setShowFilters] = useState(false);
  const { tab = "invoices" } = useParams<{ tab?: Tab }>();

  const [period, setPeriod] = useState<DatePeriod | null>({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  });

  const props = {
    setShowFilters,
    showFilters,
    selection,
    setSelection,
    period,
  };

  const content: Record<Tab, ReactNode> = {
    invoices: <Invoices {...props} />,
    bills: <Bills {...props} />,
    banks: <Transactions {...props} />,
    expenses: <OrgExpenses {...props} />,
    transactions: <TransactionList showAccountingOverview />,
    "financial-accounts": <FinancialAccountList />,
  };

  useEffect(() => {
    setSelection([]);
    setShowFilters(false);
  }, [tab]);

  const selectionArray = useMemo(() => Object.values(selection), [selection]);

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const _period = q.get("period");

    if (_period) {
      const { startDate, endDate } = decodeBase64ToObject<DatePeriod>(_period);
      const validate = DatePeriod.safeParse({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });

      if (!validate.success) return;
      if (period) setPeriod(validate.data);
    }
  }, [location.search]);

  const handleDownload = async () => {
    if (!selectionArray.length) return;

    const csv = json2csv(selectionArray);
    const blob = new Blob([csv], { type: "text/csv" });

    saveFile(blob, `Dynt-${tab}-${format(new Date(), "dd MMM yyyy")}.csv`);
  };

  return (
    <div className="h-full flex-1 overflow-auto flex flex-col">
      <PageTitle title="Accounting" />
      <div className="flex items-center justify-between">
        <Tabs
          className="mb-6 z-20 w-fit"
          tabs={tabs}
          tab={tab}
          paths={[
            "/invoices",
            "/bills",
            "/banks",
            "/expenses",
            "/transactions",
            "/financial-accounts",
          ]}
          parentRoute="/accounting"
          type="boxed"
          tabsClass="!hidden xl:!tabs"
          dropdownClass="xl:!hidden !block"
        />

        <div className="flex  items-center gap-2">
          <button
            onClick={() => setShowFilters(true)}
            className="btn btn-primary  btn-sm"
          >
            <MdFilterListAlt />
          </button>
          <div
            className="tooltip tooltip-left  before:text-xs before:w-40"
            data-tip={
              selectionArray.length
                ? `Download ${selectionArray.length} ${tab}`
                : "Please select items to download"
            }
          >
            <button
              className={`btn btn-sm ${
                selectionArray.length ? " btn-primary" : "pointer-events-none"
              } `}
              onClick={handleDownload}
            >
              <HiDownload />
            </button>
          </div>
        </div>
      </div>

      {content[tab]}
    </div>
  );
};

export default Accounting;
