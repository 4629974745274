import DisplayName from "@/components/DisplayName";
import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import AssignCategories from "@/ui/AssignCategories";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, formatLabel } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { CgDanger } from "react-icons/cg";
import { RiMoreFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

type Props = {
  list: RouterOutputs["expenses"]["expensesByOrgId"];
  isLoading: boolean;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
};

const ExpensesListing: FC<Props> = ({
  list,
  isLoading,
  selection,
  setSelection,
  totalSelected,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const { organization } = useOrganization();

  const toggleDrawer = () => {
    setSelectedItemId(null);
  };

  const nav = useNavigate();

  const view = (id: string) => () => nav(`/expense/${id}`);

  return (
    <tbody>
      {list.map((e) => (
        <tr key={e.id} className="hover cursor-pointer ">
          <th className="hidden md:table-cell">
            <Checkbox
              checked={!!selection[e.id]}
              onChange={() => {
                setSelection((p) => {
                  let prev = { ...p };

                  if (totalSelected > list.length) {
                    prev = list.reduce(
                      (acc, curr) => ({ ...acc, [curr.id]: curr }),
                      {}
                    );
                  }

                  if (prev[e.id]) {
                    const { [e.id]: _, ...rest } = prev;
                    return rest;
                  } else return { ...prev, [e.id]: e };
                });
              }}
              className="sm:block hidden -ml-1 mr-2"
            />
          </th>

          <td className="md:hidden" onClick={view(e.id)}>
            <div className=" flex text-center flex-col">
              <span className="text-sm font-semibold">
                {format(new Date(e.date), "dd")}
              </span>
              <span className="text-sm text-gray-500  ">
                {format(new Date(e.date), " MMM")}
              </span>
            </div>
          </td>
          <td onClick={view(e.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <div className="flex items-center gap-4">
                <DisplayName text={e.team_member.user.name} className="" />
                {organization?.role === "admin" &&
                  e.approvalStatus === "pending" && (
                    <CgDanger
                      size={20}
                      className="text-yellow-600  rounded-full m-0   w-auto"
                    />
                  )}
              </div>
              <DisplayName
                className="text-xs mt-1"
                text={`@ ${e.merchant.name}`}
              />
            </div>

            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {e.status}
            </div>
          </td>
          <td onClick={view(e.id)}>
            <DisplayName
              className="text-xs w-full text-center"
              text={e.event?.name || "N/A"}
            />
          </td>
          <td onClick={view(e.id)}>
            <div className="w-full text-right pr-2">
              <p className="text-right">
                {formatCurrency(e.total, e.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(e.totalVat, e.currency)}/
                {formatCurrency(e.totalAmount, e.currency)}
              </p>
            </div>
          </td>
          <td onClick={view(e.id)}>
            <p className="flex 2xl:flex-row text-center flex-col w-full  pr-2 gap-1">
              {format(new Date(e.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(e.createdAt), "yyyy")}
              </span>
            </p>
          </td>
          <td onClick={view(e.id)}>
            <p className="flex 2xl:flex-row flex-col  w-full text-center pr-2 gap-1">
              {format(new Date(e.date), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(e.date), "yyyy")}
              </span>
            </p>
          </td>

          <th className="">
            <button
              className="btn btn-ghost btn-xs"
              onClick={() => setSelectedItemId(e.id)}
            >
              <RiMoreFill size="22" />
            </button>
          </th>
          <Drawer
            isOpen={selectedItemId == e.id}
            onClose={toggleDrawer}
            title={"Expense Details"}
            content={
              <div className="flex flex-col w-full gap-3 items-center justify-end">
                <div className="w-full">
                  <AssignCategories
                    {...{
                      assignedCategories: {
                        category: e.category,
                        subCategory: e.subCategory,
                      },
                      expenseId: e.id,
                      isList: false,
                    }}
                  />
                </div>
                <div className="flex flex-row gap-5 mt-3 items-center w-full">
                  <div className="w-full">
                    <span className="text-gray-500   text-sm">Status</span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm text-center mt-1  text-gray-200     capitalize   `}
                    >
                      {e.status || "Draft"}
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="text-gray-500   text-sm ">
                      Approval status
                    </span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm mt-1 text-gray-200 text-center    capitalize   `}
                    >
                      {formatLabel(e.approvalStatus)}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </tr>
      ))}
    </tbody>
  );
};

export default ExpensesListing;
