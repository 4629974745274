import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import { Vendor } from "@/types";
import ImageFromPath from "@/ui/ImageFromPath";
import { api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction } from "react";
import { HiChevronRight } from "react-icons/hi";
import Spinner from "../../components/utils/spinner";

type Props = {
  setSelectedVendor: Dispatch<SetStateAction<Vendor | null>>;
  prevSelection?: string;
};

const SelectVendor: FC<Props> = ({ setSelectedVendor, prevSelection }) => {
  const { organizationId = "" } = useOrganization();

  const { data: vendors = [], isLoading } =
    api.partners.vendorsByOrgId.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const handleSelect = (data: Vendor) => () => {
    setSelectedVendor(data);
  };

  if (isLoading) return <Spinner />;
  if (!vendors.length) return <NotFound title="Vendor" />;

  return (
    <div className="grid gap-2">
      {vendors.map((c) => (
        <button
          onClick={handleSelect(c)}
          key={c.id.toString()}
          className="capitalize text-left bg-base-300 rounded-lg px-4 py-2 hover:bg-base-300   text-base font-medium w-full"
        >
          <div className="flex justify-between items-center gap-5">
            <ImageFromPath
              {...{ path: c.photo }}
              className="w-10 aspect-square"
            />
            <div className="  flex-1">
              <p className=" text-base font-meddium">{c.name}</p>
            </div>
            <p className="text-xs italic">{c.business_tax_number}</p>
            <HiChevronRight size={22} className="text-base-content" />
          </div>
          {prevSelection === c.id && (
            <p className="text-sm italic text-blue-400 font-medium mt-2">
              ** Previously Selected **
            </p>
          )}
        </button>
      ))}
    </div>
  );
};

export default SelectVendor;
