import Switch from "@/components/Switch";
import Drawer from "@/components/drawer/Drawer";
import Spinner from "@/components/utils/spinner";
import { useUser } from "@/context/UserContext";
import { api } from "@/utils/trpc";
import formatDistance from "date-fns/formatDistance";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { BsRobot } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { TbBellFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import { socket } from "../utils/socket";
import LoadingSpin from "./LoadingSpin";

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { userId = "" } = useUser();
  const [params, setParams] = useState<{ read?: boolean; take: number }>({
    read: false,
    take: 20,
  });

  const markAllAsRead = api.utils.markAllNotificationAsRead.useMutation();
  const markAsRead = api.utils.markNotificationAsRead.useMutation();
  const utils = api.useUtils();

  const {
    data = [],
    isLoading,
    refetch,
  } = api.utils.notifications.useQuery(
    { ...params, userId },
    { enabled: !!userId }
  );

  useEffect(() => {
    socket.on("new-notification", refetch);
    return () => {
      socket.off("new-notification", refetch);
    };
  }, []);

  const unreadCount = useMemo(() => {
    if (params.read === false) return data.length;
    return data.filter((n) => !n.read).length;
  }, [params.read, data]);

  const handleClick = (id: string) => async () => {
    setIsOpen(false);

    utils.utils.notifications.setData({ ...params, userId }, (p) => {
      if (!p) return [];
      return p.filter((n) => n.id !== id);
    });
    await markAsRead.mutateAsync(id);
  };

  return (
    <>
      <Drawer title="Notifications" isOpen={isOpen} onClose={setIsOpen}>
        <Switch
          className="ml-auto"
          text="Only show unread"
          value={params.read === false}
          onChange={(e) =>
            setParams({ ...params, read: e === false ? undefined : false })
          }
        />
        <div className="grid gap-4">
          {isLoading && <Spinner />}
          {!!unreadCount && (
            <button
              onClick={async () => {
                await markAllAsRead.mutateAsync(userId);
                await refetch();
              }}
              className="btn btn-secondary btn-outline ml-auto btn-sm w-fit text-xs rounded-full my-2"
            >
              <LoadingSpin loading={markAllAsRead.isLoading} />
              Mark all as read
            </button>
          )}
          {data.map((n) => (
            <div
              className={`text-sm border border-dashed border-base-300  grid gap-3 rounded-md p-3 ${
                n.read ? "opacity-50" : "shadow-2xl"
              }`}
            >
              <p className="bg-base-300 shadow-lg rounded-lg p-2">{n.title}</p>
              <p className="text-xs text-primary font-semibold">
                {n.organization.name}
              </p>
              {n.user ? (
                <p className="flex items-center gap-2">
                  <AiOutlineUser className="rounded-full bg-primary text-primary-content w-5 h-5 p-0.5" />
                  {n.user.name} {n.user.id === userId && "(You)"}
                </p>
              ) : (
                <p className="flex items-center gap-2">
                  <BsRobot className="rounded-full bg-primary text-primary-content w-5 h-5 p-0.5" />
                  Automated System
                </p>
              )}
              <p className="flex text-xs font-normal gap-3 items-center">
                {n.message}
                <span className="text-xs text-gray-500">
                  {formatDistance(n.createdAt, new Date(), { addSuffix: true })}
                </span>
              </p>
              <Link
                className="btn btn-primary rounded-full btn-sm justify-between text-xs"
                to={n.actionLink}
                onClick={handleClick(n.id)}
              >
                {n.actionTitle} <FiChevronRight />
              </Link>
            </div>
          ))}
        </div>
      </Drawer>
      <button
        onClick={() => setIsOpen(true)}
        className="indicator btn btn-ghost btn-xs rounded-sm btn-square z-10"
      >
        {!!unreadCount && (
          <span className="indicator-item badge badge-warning rounded-full aspect-square text-xs">
            {unreadCount}
          </span>
        )}
        <TbBellFilled size={22} />
      </button>
    </>
  );
};

export default Notifications;
