import { useOrganization } from "@/context/OrganizationContext";
import Rows from "@/ui/skeletons/Rows";
import { api, RouterOutputs } from "@/utils/trpc";
import toast from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import CreateFinancialAccount from "./CreateFinancialAccount";
import FinancialAccountRow from "./FinancialAccountRow";

type Props = {};

export type FinancialAccount =
  RouterOutputs["organizations"]["financialAccount"]["list"][number];

const FinancialAccountList = (props: Props) => {
  const { organizationId } = useOrganization<true>();
  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.organizations.financialAccount.list.useQuery(organizationId);

  if (isLoading) return <Rows count={3} />;

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };
  return (
    <div className="grid gap-6">
      <div className="flex items-center w-fit justify-self-end gap-2">
        <button
          className="btn btn-secondary btn-outline btn-sm"
          onClick={handleRefetch}
        >
          <RiRefreshLine
            size={20}
            className={isRefetching ? "animate-spin" : ""}
          />
        </button>
        <Link to={"?new"} className="btn btn-secondary btn-sm">
          <HiPlusCircle /> New
        </Link>
      </div>

      <table className="table bg-base-200">
        <thead>
          <tr>
            <th>Name</th>
            <th>Code</th>
            <th>Type</th>
            <th>Last updated</th>
          </tr>
        </thead>
        <tbody>
          {data.map((t) => (
            <FinancialAccountRow key={t.id} account={t} />
          ))}
        </tbody>
      </table>

      <CreateFinancialAccount />
    </div>
  );
};

export default FinancialAccountList;
