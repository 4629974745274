import { FC } from "react";

import AssignCategories from "@/ui/AssignCategories";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import EditMerchant from "./EditMerchant";

import Collapsible from "@/ui/Collapsible";
import { RouterInputs, api } from "@/utils/trpc";
import toast from "react-hot-toast";
import { BiSolidCalendarX, BiSolidHide } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { FaReceipt } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { IoMdEye } from "react-icons/io";
import { SlCalender } from "react-icons/sl";
import { Transaction } from "./SingleTransaction";
import TransactionProof from "./TransactionProof";

const recurringTypes = [
  { value: "monthly", label: "Monthly", Icon: <SlCalender /> },
  { value: "yearly", label: "Yearly", Icon: <CiCalendarDate size={28} /> },
  { value: null, label: "Never", Icon: <BiSolidCalendarX size={24} /> },
] as const;

type RecurringType = (typeof recurringTypes)[number]["value"];

const Visibility = [
  { label: "visible", icon: <IoMdEye />, value: false },
  { label: "hidden", icon: <BiSolidHide />, value: true },
];

type Props = {
  transaction: Transaction;
  setTransaction: (t: Transaction | null) => void;
  params: RouterInputs["transactions"]["transactions"];
  setConvertingToExpense: (b: boolean) => void;
};

const TransactionDetails: FC<Props> = ({
  transaction,
  params,
  setConvertingToExpense,
  setTransaction,
}) => {
  const utils = api.useUtils();

  const update = api.transactions.updateDetails.useMutation();

  const handleUpdate = async (input: {
    recurringType?: RecurringType;
    isHidden?: boolean;
  }) => {
    const func = async () => {
      const res = await update.mutateAsync({
        id: transaction.id,
        ...input,
      });

      utils.transactions.transactions.setInfiniteData(params, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            list: page.list.map((t) => {
              if (t.id === transaction.id) return { ...t, ...res };
              return t;
            }),
          })),
        };
      });
      if (res.isHidden) setTransaction(null);
    };

    toast.promise(func(), {
      loading: "Updating transaction...",
      success: "Transaction updated!",
      error: "Error updating transaction",
    });
  };

  return (
    <div className="w-full flex flex-col gap-6 ">
      <div className="z-40">
        <p className="label label-text">Merchant</p>
        <EditMerchant
          {...{
            amount: transaction.amount,
            params,
            transactionId: transaction.id,
            merchant: transaction.merchant,
            bankAccountId: transaction.bankAccountId,
          }}
        />
      </div>
      <div className="z-30">
        <p className="label label-text">Category / Subcategory</p>

        <AssignCategories
          {...{
            assignedCategories: {
              category: transaction.category,
              subCategory: transaction.subCategory,
            },
            transactionId: transaction.id,
            params,
          }}
        />
      </div>

      <TransactionProof
        {...{
          transactionId: transaction.id,
          proofStatus: transaction.proofStatus,
          params,
        }}
      />
      <div>
        <p className="label">Recurring:</p>
        <div className="flex gap-2">
          {recurringTypes.map(({ Icon, value, label }) => (
            <button
              className={`btn capitalize btn-sm ${
                transaction.recurringType === value ? "btn-primary" : ""
              }`}
              onClick={() => handleUpdate({ recurringType: value })}
            >
              {Icon} {label}
            </button>
          ))}
        </div>
      </div>
      <div>
        <p className="label">Visblity:</p>
        <div className="flex gap-2">
          {Visibility.map(({ icon, value, label }) => (
            <button
              className={`btn capitalize btn-sm ${
                transaction.isHidden === value ? "btn-primary" : ""
              }`}
              onClick={() => handleUpdate({ isHidden: value })}
            >
              {icon} {label}
            </button>
          ))}
        </div>
      </div>

      {/* <button
        className="btn w-full justify-start text-left"
        onClick={toggleStatus("isHidden")}
      >
        {!statuses.isHidden ? (
          <>
            <AiFillEyeInvisible /> Hide transaction
          </>
        ) : (
          <>
            <AiFillEye /> Show transaction
          </>
        )}
        <HiChevronRight className="ml-auto" />
      </button> */}
      {transaction.amount < 0 && (
        <button
          onClick={() => setConvertingToExpense(true)}
          className="btn w-full"
        >
          <FaReceipt /> Set as expense
          <HiChevronRight className="ml-auto" />
        </button>
      )}

      <Collapsible title="Raw JSON">
        <JsonView
          data={transaction.rawJSON || {}}
          shouldExpandNode={allExpanded}
          style={{
            ...defaultStyles,
            container:
              "break-words w-full break-all rounded-xl text-xs bg-white",
          }}
        />
      </Collapsible>
    </div>
  );
};

export default TransactionDetails;
