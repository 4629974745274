import NotFound from "@/components/utils/notfound";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { format } from "date-fns";
import { FC } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  bills: RouterOutputs["reports"]["analytics"]["bills"];
};

export const RecentBills: FC<Props> = ({ bills }) => {
  const nav = useNavigate();
  if (!bills.length) {
    return <NotFound title={"bills & Bills"} />;
  }
  return (
    <div className="overflow-x-auto ">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th></th>
            <th>Customer</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {bills.map((bills) => {
            return (
              <tr
                className="hover cursor-pointer"
                onClick={() => nav(`/bill/${bills.id}`)}
              >
                <th>
                  <BsArrowUpRight size="16" className="text-error" />
                </th>
                <td>
                  <div>
                    <p>{bills.vendor.name}</p>
                    <span className="text-neutral-500   text-xs">
                      {format(bills.dueDate, "dd MMM yyyy")}
                    </span>
                  </div>
                </td>
                <td className="text-success font-medium text-xs">
                  {formatCurrency(bills.total)}
                </td>

                <td>
                  <div className={`btn capitalize btn-sm text-xs `}>
                    {bills.status}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center py-2">
        <Link to={"/bills"} className="link text-sm link-hover link-primary">
          see more
        </Link>
      </div>
    </div>
  );
};
