import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import SelectCategories, {
  Categories,
  CategoryData,
} from "@/ui/SelectCategories";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline, IoMdRemoveCircle } from "react-icons/io";

type Props = {
  category?: CategoryData | null;
  subCategory?: CategoryData | null;
  suggestedCategory?: CategoryData | null;
  suggestionAccepted?: boolean | null;

  transactionId: string;

  cardId?: string;
};

const ManageCategories: FC<Props> = ({
  category,
  subCategory,
  suggestedCategory,
  transactionId,
  suggestionAccepted,
  cardId,
}) => {
  const [categories, setCategories] = useState<Categories>({
    category,
    subCategory,
  });

  const { organizationId } = useOrganization<true>();

  const add = api.swan.transfers.category.add.useMutation();
  const utils = api.useUtils();

  const handleSave = async (accepted?: boolean) => {
    const { category, subCategory } = categories;

    const res = await add.mutateAsync({
      categoryId:
        accepted && suggestedCategory
          ? suggestedCategory.id
          : category
            ? category.id
            : null,
      subCategoryId: subCategory ? subCategory.id : null,
      suggestedCategoryId: suggestedCategory?.id,
      transactionId,
    });

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) =>
              t.id === transactionId ? { ...t, categoryInfo: res } : t
            ),
          })),
        };
      });

    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) =>
          t.id === transactionId ? { ...t, categoryInfo: res } : t
        );
      });

    setCategories(res);
  };

  if (suggestedCategory && suggestionAccepted === null) {
    return (
      <div className="btn group w-full max-w-xs min-w-max  btn-outline overflow-hidden btn-accent btn-sm">
        <LoadingSpin loading={add.isLoading} />
        <FaWandMagicSparkles />
        {suggestedCategory.name}
        <div className="btn absolute btn-neutral btn-xs translate-x-full group-hover:transform-none w-full h-full transition-all">
          <button
            className="btn btn-success btn-xs tooltip tooltip-left tooltip-success"
            onClick={() => handleSave(true)}
            data-tip="Approve"
          >
            <IoMdCheckmarkCircleOutline />
          </button>
          <button
            className="btn btn-error btn-outline btn-xs tooltip tooltip-right tooltip-error"
            data-tip="Reject"
            onClick={() => handleSave(false)}
          >
            <IoMdRemoveCircle />
          </button>
        </div>
      </div>
    );
  }
  return (
    <SelectCategories
      {...{
        categories,
        setCategories,
        handleSave: () => handleSave(false),
        categoryList: [],
        isLoading: add.isLoading,
      }}
    />
  );
};

export default ManageCategories;
