import AttachDocuments from "@/components/proof/AttachDocuments";
import { ProofStatusType } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { RouterInputs, api } from "@/utils/trpc";
import { FC, useState } from "react";
import { GoSkipFill } from "react-icons/go";
import { HiPlus } from "react-icons/hi";

type Props = {
  transactionId: string;
  proofStatus: ProofStatusType;
  params: RouterInputs["transactions"]["transactions"];
};

const TransactionProof: FC<Props> = ({
  transactionId,
  params,
  proofStatus,
}) => {
  const { data, isLoading } = api.transactions.proofs.useQuery(transactionId);
  const [adding, setAdding] = useState(false);
  const [selected, setSelected] = useState<Record<string, boolean>>({});

  const utils = api.useUtils();

  const attach = api.transactions.attachProof.useMutation();
  const deleteProof = api.utils.deleteProof.useMutation();

  const updateStatus = api.transactions.updateProofStatus.useMutation();

  const handleAttach = async () => {
    setSelected({});
    setAdding(false);

    const _proofs = attach.mutateAsync({
      proofs: Object.keys(selected).map((fileId) => ({ fileId })),
      transactionId: transactionId,
    });

    const _status = updateProofStatus("complete");

    const [proofs] = await Promise.all([_proofs, _status]);

    utils.transactions.proofs.setData(transactionId, (p) => {
      if (!p) return p;
      return [...proofs, ...p];
    });
  };

  const handleDelete = async (proofId: string) => {
    let isMissing = false;

    await deleteProof.mutateAsync(proofId);

    utils.transactions.proofs.setData(transactionId, (p) => {
      if (!p) return p;
      const proofs = p.filter((proof) => proof.id !== proofId);
      isMissing = proofs.length === 0;
      return proofs;
    });

    if (!isMissing) return;

    updateProofStatus("missing");
  };

  const updateProofStatus = async (proofStatus: ProofStatusType) => {
    utils.transactions.transactions.setInfiniteData(params, (p) => {
      if (!p) return p;
      return {
        ...p,
        pages: p.pages.map((page) => ({
          ...page,
          list: page.list.map((t) => {
            if (t.id !== transactionId) return t;
            return { ...t, proofStatus };
          }),
        })),
      };
    });

    await updateStatus.mutateAsync({
      id: transactionId,
      proofStatus,
    });
  };

  const canMarkNotNeeded =
    proofStatus === "missing" && !isLoading && !data?.length;

  return (
    <div>
      <div className="flex items-center justify-between my-3">
        <div className="flex items-center justify-between gap-4">
          <p className="label">Proofs</p>
          {canMarkNotNeeded && (
            <button
              onClick={async () => await updateProofStatus("not_needed")}
              className="btn tooltip tooltip-secondary btn-secondary btn-outline btn-sm"
              data-tip="Mark as not needed"
            >
              <GoSkipFill />
            </button>
          )}
        </div>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setAdding(true)}
        >
          <LoadingSpin loading={attach.isLoading} />
          <HiPlus />
        </button>
      </div>
      <AttachDocuments
        {...{
          proofs: data,
          adding,
          setAdding,
          selected,
          setSelected,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    </div>
  );
};

export default TransactionProof;
