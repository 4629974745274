import DisplayName from "@/components/DisplayName";
import NotFound from "@/components/utils/notfound";

import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { format } from "date-fns";
import { FC } from "react";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

type Props = {
  transactions: RouterOutputs["reports"]["analytics"]["transactions"];
};

export const RecentTransactions: FC<Props> = ({ transactions }) => {
  if (!transactions.length) {
    return <NotFound title={"Transactions"} />;
  }
  return (
    <div className="overflow-x-auto ">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th></th>
            <th>Customer</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((t) => {
            const isSent = t.amount < 0;
            return (
              <tr className="hover">
                <th>
                  {isSent ? (
                    <BsArrowUpRight size="16" className="text-error" />
                  ) : (
                    <BsArrowDownLeft size="16" className="text-success" />
                  )}
                </th>
                <td>
                  <div>
                    <DisplayName text={t.merchant?.name} />
                    <span className="text-neutral-500   text-xs">
                      {format(t.date, "dd MMM yyyy")}
                    </span>
                  </div>
                </td>
                <td
                  className={`font-medium text-xs ${
                    isSent ? "text-error" : "text-success"
                  } `}
                >
                  {formatCurrency(t.amount, t.currency)}
                </td>

                <td>
                  <div className={`btn capitalize btn-sm text-xs `}>
                    {t.status}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center py-2">
        <Link
          to={"/transactions"}
          className="link text-sm link-hover link-primary"
        >
          see more
        </Link>
      </div>
    </div>
  );
};
