import { useUser } from "@/context/UserContext";
import { reAuthErrorMessages, syncErrorMessages } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import { addDays } from "date-fns";
import isBefore from "date-fns/isBefore";
import { FC } from "react";
import { HiChevronRight, HiLibrary } from "react-icons/hi";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

type Props = {
  banks: RouterOutputs["banks"]["banks"];
  isLoading: boolean;
};

const OrganizationBanks: FC<Props> = ({ banks, isLoading }) => {
  const { userId } = useUser();

  const nav = useNavigate();
  const auth = api.yapily.accountAuthRequest.useMutation();

  const handleReAuth = (bank: (typeof banks)[number]) => async () => {
    if (!userId || !bank.institution_id) return;

    const data = await auth.mutateAsync({
      institutionId: bank.institution_id,
      applicationUserId: userId,
      callback: `${window.location.origin}//${window.location.host}/accounts/connect/re-auth?bankId=${bank.id}`,
    });

    if (!data) return false;

    window.open(data.authorisationUrl, "_self");
  };

  const showTerm = !banks.length && !isLoading;

  return (
    <div className="mt-3 grid gap-4">
      {showTerm && (
        <p className="text-sm text-right">
          By clicking Connect Bank, you agree with{" "}
          <a
            target="_blank"
            className="link link-primary"
            href="https://www.yapily.com/legal/end-user-terms"
          >
            terms and conditions{" "}
          </a>
          and{" "}
          <a
            target="_blank"
            className="link link-primary"
            href="https://www.yapily.com/legal/privacy-policy"
          >
            Privacy Policy
          </a>{" "}
          provided by Yapily.
        </p>
      )}
      <div className="grid gap-4">
        {banks.map((b) => {
          return (
            <div
              key={b.id}
              className="grid gap-1"
              onClick={() => nav(`/connected-banks/${b.id}`)}
            >
              <div className="btn transform-none  btn-neutral w-full justify-between">
                {b.institution_logo ? (
                  <p className="flex items-center gap-2">
                    <img
                      src={b.institution_logo}
                      className="w-10  object-contain"
                    />
                    {b.name}
                  </p>
                ) : (
                  <p className="flex items-center gap-2">
                    <HiLibrary /> {b.name}
                  </p>
                )}

                <div className="flex items-center gap-2">
                  {isBefore(b.authorizedOn, addDays(new Date(), -30)) && (
                    <div
                      className="tooltip before:w-60 tooltip-left before:text-sm tooltip-warning"
                      data-tip={reAuthErrorMessages}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        className="btn btn-sm btn-ghost bg-black"
                        onClick={handleReAuth(b)}
                      >
                        <LoadingSpin loading={auth.isLoading} />
                        <TbAlertTriangleFilled className="text-warning text-lg" />
                      </button>
                    </div>
                  )}
                  {b.isSyncError && (
                    <div
                      className="tooltip before:w-60 tooltip-left before:text-sm tooltip-warning"
                      data-tip={syncErrorMessages}
                    >
                      <button className="btn btn-sm bg-black">
                        <LoadingSpin loading={auth.isLoading} />
                        <TbAlertTriangleFilled className="text-warning text-lg" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid gap-1 ">
                {b.accounts.map((a) => {
                  return (
                    <button
                      key={a.id}
                      className={`btn w-full transform-none justify-between text-sm ${
                        a.isActive ? "" : "btn-disabled pointer-events-auto"
                      } `}
                    >
                      <div className="text-left">
                        <p className="font-semibold">{a.name || "No Name"}</p>
                        <p className="text-xs">
                          {a.IBAN || a.ACCOUNT_NUMBER || a.ROUTING_NUMBER}
                        </p>
                      </div>
                      <div className="text-right items-center gap-3 flex">
                        <div>
                          <p className=" text-sm font-medium">
                            {formatCurrency(a.balance || 0, a.currency)}
                          </p>
                          <p className="text-xs font-medium">
                            {formatCurrency(a.converted || 0, b.currency)}
                          </p>
                        </div>

                        <HiChevronRight
                          size={22}
                          className="text-base-content"
                        />
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrganizationBanks;
