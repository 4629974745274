import { Dispatch, FC, SetStateAction, memo, useEffect } from "react";
import "react-json-view-lite/dist/index.css";

import AccountingStatus from "@/pages/accounting/transactions/AccountingStatus";
import { Enums } from "@/types";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency } from "@/utils/helper";
import { RouterInputs, RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { LuFileCheck } from "react-icons/lu";
import { MdBlock } from "react-icons/md";
import { TbFileInfo, TbFileOff } from "react-icons/tb";

const proofStatusIcon: Record<Enums["ProofStatus"], JSX.Element> = {
  missing: <TbFileInfo className="text-error" size={28} />,
  not_needed: <TbFileOff className="text-gray" size={28} />,
  complete: <LuFileCheck className="text-success" size={28} />,
};

export type Transaction =
  RouterOutputs["transactions"]["transactions"]["list"][number];

type Props = {
  transaction: Transaction;
  showHidden: boolean;
  handleSelect: (t: Transaction) => void;
  isSelected: boolean;
  index: number;
  total: number;
  setTransaction: Dispatch<SetStateAction<Transaction | null>>;
  params: RouterInputs["transactions"]["transactions"];
};

const SingleTransaction: FC<Props> = ({
  transaction,
  isSelected,
  showHidden,
  index,
  handleSelect,
  total,
  setTransaction,
  params,
}) => {
  const isSent = transaction.amount < 0;

  const { category, subCategory, merchant, proofStatus, bankAccount } =
    transaction;

  useEffect(() => {
    setTransaction((p) => {
      if (!p) return p;

      if (p.id === transaction.id) return { ...transaction };
      return p;
    });
  }, [category?.id, subCategory?.id, merchant?.id, proofStatus]);

  return (
    <tr
      onClick={() => setTransaction(transaction)}
      className={`hover cursor-pointer ${
        transaction.isHidden && !showHidden ? "hidden" : ""
      } `}
    >
      <th className="sm:table-cell hidden">
        <button onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={isSelected}
            onChange={() => handleSelect(transaction)}
          />
        </button>
      </th>

      <td className="md:hidden p-0">
        <div className=" flex text-center flex-col pl-2">
          <span className="text-sm font-semibold">
            {format(new Date(transaction.date), "dd")}
          </span>
          <span className="text-sm text-gray-500  ">
            {format(new Date(transaction.date), " MMM")}
          </span>
        </div>
      </td>
      <td className="w-max">{merchant?.name || <MdBlock size={20} />}</td>
      <td className="w-max">
        <div className="flex items-center gap-2">
          <img
            src={bankAccount.bank.institution_logo}
            className="w-6 aspect-square object-contain"
          />
          <p>{bankAccount.name}</p>
        </div>
      </td>
      <td>
        <div className=" flex gap-2 items-center justify-center">
          {isSent ? (
            <BsArrowUpRight className="text-error" />
          ) : (
            <BsArrowDownLeft className="text-success" />
          )}
          {formatCurrency(transaction.amount, transaction.currency)}
        </div>
      </td>
      <td className="text-xs 2xl:text-sm hidden md:table-cell">
        <p className="text-right flex 2xl:flex-row flex-col  pr-2 gap-1">
          {format(new Date(transaction.date), "dd MMM")},
          <span className=" text-neutral-500">
            {format(new Date(transaction.date), "yyyy")}
          </span>
        </p>
      </td>
      <td className="hidden md:table-cell">
        <div className="btn btn-ghost gap-0 grid justify-items-center transform-none hover:bg-transparent">
          <div className="flex scale-90 items-center gap-2">
            {category && (
              <span
                className="btn scale-75 btn-circle btn-xs "
                style={{ background: category?.color }}
              />
            )}

            {category?.name || <MdBlock size={26} />}
          </div>
          {subCategory && (
            <div className="flex scale-75 items-center gap-2">
              <span
                className="btn btn-circle scale-75 btn-xs"
                style={{ background: subCategory?.color }}
              />

              {subCategory?.name || <MdBlock />}
            </div>
          )}
        </div>
      </td>
      <td>{proofStatusIcon[proofStatus]}</td>
      <td className="w-max" style={{ zIndex: total - index }}>
        <AccountingStatus
          {...{
            status: transaction.accountingStatus,
            params,
            transactionId: transaction.id,
          }}
        />
      </td>
    </tr>
  );
};

export default memo(SingleTransaction);
