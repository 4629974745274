import { months } from "@/lib";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { BarChart } from "@tremor/react";
import { FC } from "react";
import NotFound from "../utils/notfound";
import Spinner from "../utils/spinner";

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["spending"];
  currency: string;
  loading: boolean;
};

const Spending: FC<Props> = ({ data: data, currency, loading }) => {
  if (loading) return <Spinner />;
  if (!data) return <NotFound title="Data" />;

  return (
    <div className="mt-4" style={{ width: "100%", height: "400px" }}>
      <BarChart
        className="mt-6"
        data={months.map((m) => ({ value: data[m.value], name: m.name }))}
        index="name"
        categories={["value"]}
        colors={["blue"]}
        valueFormatter={(v) => formatCurrency(v, currency)}
        yAxisWidth={100}
      />
    </div>
  );
};

export default Spending;
