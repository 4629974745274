import { COLORS } from "@/constants";
import { useTheme } from "@/context/ThemeContext";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { ChartData, ChartOptions } from "chart.js";
import { FC, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { BiSolidDoughnutChart } from "react-icons/bi";
import { FaRegChartBar } from "react-icons/fa6";
import Dropdown from "../Dropdown";

type Props = {
  categories: RouterOutputs["reports"]["analytics"]["categories"];
  currency: string;
};
const options = ["spending", "invoices", "bills"] as const;
type Option = (typeof options)[number];

type Chart = "bar" | "doughnut";

const PieChart: FC<Props> = ({ categories, currency }) => {
  const [type, setType] = useState<Option>("spending");
  const [chart, setChart] = useState<Chart>("doughnut");
  const { theme } = useTheme();

  const doughnutOptions: ChartOptions<"doughnut"> = {
    interaction: {
      intersect: true,
    },
    cutout: "66%",
    elements: {
      arc: { borderRadius: 10 },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        bodyFont: { size: 16 },
        titleFont: { size: 16 },
        footerFont: { size: 16 },
        callbacks: {
          label: function (context) {
            return `${formatCurrency(
              +context.formattedValue.replace(/,/g, ""),
              currency
            )}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const dataset = categories[type];

  const total = dataset.reduce((acc, curr) => acc + curr.total, 0);

  const top5 = dataset
    .sort((a, b) => Math.abs(b.total) - Math.abs(a.total))
    .slice(0, 5);
  const othersTotal = total - top5.reduce((acc, curr) => acc + curr.total, 0);

  const doughnutData: ChartData<"doughnut"> = {
    labels: top5.map((c) => c.category.name).concat("others"),
    datasets: [
      {
        data: top5.map((c) => c.total).concat(othersTotal),
        backgroundColor: top5
          .map((c) => c.category.color)
          .concat(COLORS[theme].neutral),
      },
    ],
  };

  return (
    <div>
      <div className="flex items-center mb-4 justify-between">
        <h2 className="label font-semibold">Categories</h2>

        <Dropdown
          title={type}
          dropdownClass="w-36"
          buttonClass="capitalize"
          content={options.map((t) => (
            <button
              className="capitalize"
              key={t}
              onClick={() => setType(t)}
              onTouchEnd={() => setType(t)}
            >
              {t}
            </button>
          ))}
        />
      </div>

      <div className="grid justify-items-center w-fit">
        <label className="swap btn btn-ghost btn-circle swap-flip text-2xl">
          <input
            type="checkbox"
            checked={chart === "doughnut"}
            onChange={(e) => setChart(e.target.checked ? "doughnut" : "bar")}
          />

          <div className="swap-on">
            <BiSolidDoughnutChart />
          </div>
          <div className="swap-off 90">
            <FaRegChartBar />
          </div>
        </label>
        <p className="text-xs">swap</p>
      </div>

      <div style={{ width: "100%", height: "400px" }}>
        {chart === "doughnut" && (
          <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-semibold">
            {formatCurrency(total, currency)}
          </p>
        )}
        {chart === "doughnut" ? (
          <Doughnut data={doughnutData} options={doughnutOptions} />
        ) : (
          <div className="py-4 grid gap-1">
            {top5.map((t) => (
              <div key={t.category.id} className="btn">
                <span
                  className="btn btn-xs btn-circle scale-75"
                  style={{ backgroundColor: t.category.color }}
                />
                <p>{t.category.name}</p>
                <p className="ml-auto">{formatCurrency(t.total, currency)}</p>
              </div>
            ))}
            {!!othersTotal && (
              <div className="btn btn-">
                <span className="btn btn-xs btn-neutral btn-circle scale-75" />
                <p>others</p>
                <p className="ml-auto">
                  {formatCurrency(othersTotal, currency)}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChart;
