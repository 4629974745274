import Checkbox from "@/ui/Checkbox";
import { Dispatch, FC, SetStateAction } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaSort } from "react-icons/fa";

const SortIcon = {
  asc: <BsSortUp size={16} />,
  desc: <BsSortDown size={16} />,
  undefined: <FaSort size={16} />,
} as const;

export type Sorting = {
  amount?: "asc" | "desc";
  date?: "asc" | "desc";
};

type Props = {
  setSorting: Dispatch<SetStateAction<Sorting>>;
  sorting: Sorting;
  data: any[];
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selectedArray: any[];
};

const TransactionHeader: FC<Props> = ({
  setSorting,
  sorting,
  data,
  setSelection,
  selectedArray,
}) => {
  const handleSorting = (key: keyof Sorting) => () => {
    setSorting((p) => ({
      [key]: p[key] === "asc" ? "desc" : p[key] === "desc" ? "asc" : "desc",
    }));
  };

  return (
    <thead>
      <tr>
        <th className="table-cell md:hidden"></th>
        <th className="hidden md:table-cell">
          <Checkbox
            {...{
              checked: data.length
                ? selectedArray.length >= data.length
                : false,
              onChange: (e) => {
                if (!e) setSelection({});
                else {
                  setSelection(
                    data.reduce(
                      (acc, curr) => ({ ...acc, [curr.id]: curr }),
                      {}
                    )
                  );
                }
              },
            }}
          />
        </th>
        <th>Merchant</th>
        <th>Account</th>
        <th className="w-max">
          <button
            className="flex w-fit min-w-max mx-auto ml-auto text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("amount")}
          >
            <p>Amount</p>
            {SortIcon[sorting.amount!]}
          </button>
        </th>
        <th className="hidden md:table-cell w-max ">
          <button
            className="flex w-fit min-w-max justify-end text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("date")}
          >
            <p>Date</p>
            {SortIcon[sorting.date!]}
          </button>
        </th>
        <th className="text-center hidden md:table-cell">Category</th>
        <th className="hidden md:table-cell">Proof</th>
      </tr>
    </thead>
  );
};

export default TransactionHeader;
