import Drawer from "@/components/drawer/Drawer";
import Checkbox from "@/ui/Checkbox";
import { calculateTax, formatCurrency, formatLabel } from "@/utils/helper";
import { format } from "date-fns";
import { SetStateAction } from "jotai";
import { CSSProperties, Dispatch, FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssignFinancialAccount from "../components/AssignFinancialAccount";
import AssignTaxCode from "../components/AssignTaxCode";
import ManageAccountingStatus from "../components/ManageAccountingStatus";
import ManageCategories from "../components/ManageCategories";
import { SwanTransaction } from "./TransactionList";

type Props = {
  transaction: SwanTransaction;
  isSelected: boolean;
  setSelection: Dispatch<SetStateAction<Record<string, SwanTransaction>>>;
  showAccountingOverview?: boolean;
  style?: CSSProperties;
};

const statusColor = {
  Booked: "btn-success",
  Rejected: "btn-error",
  Pending: "btn-warning",
  Canceled: "btn-error",
  Upcoming: "btn-info",
  Released: "btn-neutral",
};

const paymentColor = {
  Rejected: "btn-error",
  ConsentPending: "btn-warning",
  Initiated: "btn-info",
};

const TransactionRow: FC<Props> = ({
  transaction: t,
  isSelected,
  setSelection,
  showAccountingOverview,
  style,
}) => {
  const { id } = useParams<{ id?: string }>();
  const nav = useNavigate();

  return (
    <tr
      style={style}
      className="border-white/5 cursor-pointer"
      onClick={() =>
        !showAccountingOverview && nav(`/transactions/dynt/${t.id}`)
      }
    >
      <th>
        <Checkbox
          onChange={() => {
            setSelection((p) => {
              if (!p[t.id]) return { ...p, [t.id]: t };

              const { [t.id]: _, ...rest } = p;
              return rest;
            });
          }}
          checked={isSelected}
        />
      </th>

      <td>{t.counterParty}</td>

      <td>
        <span className="badge badge-primary">
          {formatLabel(t.paymentProduct)}
        </span>
      </td>

      <td className="text-right">
        <p className="px-2">{formatCurrency(t.amount as any, t.currency)}</p>
        {t.taxCode && (
          <p className="text-xs badge badge-neutral">
            tax{" "}
            {formatCurrency(
              calculateTax(t.amount as any, t.taxCode.rate),
              t.currency
            )}{" "}
          </p>
        )}
      </td>

      <td className="text-right">
        <p>{format(t.executionDate, "dd/MM/yyyy")}</p>
        <p className="text-xs">{format(t.executionDate, " h:mm:ss a")}</p>
      </td>
      <td className="join w-full justify-center">
        <div className="tooltip" data-tip="Transaction Status">
          <span
            className={`btn join-item btn-xs btn-outline ${
              statusColor[t.status]
            }`}
          >
            {t.status}
          </span>
        </div>
        {t.payment && (
          <div className="tooltip" data-tip="Payment Status">
            <span
              className={`btn  btn-xs btn-outline join-item ${
                paymentColor[t.payment.status]
              } `}
            >
              {t.payment.status}
            </span>
          </div>
        )}
      </td>
      {showAccountingOverview && (
        <td className="text-center">
          <ManageAccountingStatus
            status={t.accountingStatus}
            transactionId={t.id}
          />
        </td>
      )}

      <Drawer
        title="Details"
        className="grid gap-4"
        isOpen={id === t.id}
        onClose={() => nav("/transactions/dynt")}
      >
        <AssignTaxCode
          {...{
            transactionId: t.id,
            taxCode: t.taxCode,
            amount: t.amount as any,
            currency: t.currency,
            showLabel: true,
          }}
        />
        <div>
          <div className="divider divider-start">Categories</div>
          <ManageCategories
            {...{
              ...t.categoryInfo,
              transactionId: t.id,
            }}
          />
        </div>
        <AssignFinancialAccount
          {...{
            transactionId: t.id,
            financialAccount: t.financialAccount,
            showLabel: true,
          }}
        />
      </Drawer>
    </tr>
  );
};

export default TransactionRow;
