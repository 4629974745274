import { formatLabel } from "@/utils/helper";
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";

type Props<T extends string, U extends string[]> = {
  tabs: Readonly<[...U]>;
  paths?: { [K in keyof U]: string };
  setTab?: React.Dispatch<React.SetStateAction<T>>;
  tab: T;
  className?: string;
  parentRoute?: string;
  type: "boxed" | "bordered";
  tabsClass?: string;
  dropdownClass?: string;
};

const Tabs = <T extends string, U extends any[]>({
  setTab,
  tab,
  tabs,
  className = "",
  parentRoute = "",
  dropdownClass = "xl:hidden block",
  tabsClass = "hidden xl:tabs",
  paths,
  type,
}: Props<T, U>) => {
  const nav = useNavigate();

  const handleAction =
    (tab: U[number], index: number) => (e: { stopPropagation(): void }) => {
      e.stopPropagation();
      if (typeof setTab === "function") {
        setTab(tab);
      }
      if (paths) {
        nav(parentRoute + paths[index]);
      }
    };
  return (
    <div className={className}>
      <div
        className={`z-10 w-full tabs ${
          type === "boxed" ? "tabs-boxed" : "tabs-bordered"
        } p-3 ${tabsClass} `}
      >
        {tabs.map((t, i) => (
          <button
            onClick={handleAction(t, i)}
            onTouchEnd={handleAction(t, i)}
            className={`tab capitalize gap-2 ${tab === t ? "tab-active" : ""}`}
          >
            {formatLabel(t)}
          </button>
        ))}
      </div>

      <Dropdown
        dropdownClass={`w-52 ${dropdownClass}`}
        buttonClass="capitalize"
        title={tab}
        content={tabs.map((t, i) => (
          <button
            onClick={handleAction(t, i)}
            onTouchEnd={handleAction(t, i)}
            className="capitalize"
          >
            {formatLabel(t)}
          </button>
        ))}
      />
    </div>
  );
};

export default Tabs;
