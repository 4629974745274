import Drawer from "@/components/drawer/Drawer";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import Pagination from "@/ui/Pagination";
import { copyToClipboard, formatCurrency, formatLabel } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import { PiInfoBold } from "react-icons/pi";
import { DatePeriod } from "../Accounting";
import SelectBillFilters from "./BillFilters";
import BillHeaders, { Sorting } from "./BillHeaders";
import BillsListing from "./BillsListing";

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
};

export type BillFilter = RouterInputs["bills"]["bills"]["filters"];

const Bills: FC<Props> = ({
  setShowFilters,
  showFilters,
  selection,
  setSelection,
  period,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationId = "", organization } = useOrganization();

  const [filters, setFilters] = useState<BillFilter>(null);

  const selectAll = api.bills.selectAllBills.useMutation();
  const load = api.emails.loadForwardedBills.useMutation();

  const selectedArray: string[] = useMemo(
    () => Object.values(selection).map((a) => a.id),
    [selection]
  );

  const [sorting, setSorting] = useState<Sorting>({
    createdAt: "desc",
  });

  const params = { organizationId, currentPage, filters, sorting, period };

  const {
    data = [],
    isLoading,
    refetch,
    remove,
  } = api.bills.bills.useQuery(params, { enabled: !!organizationId });

  const { data: filtersData } = api.bills.filtersData.useQuery(
    { organizationId, filters, period, groupBy: "accountingStatus" },
    { enabled: !!organizationId }
  );

  const total = filtersData?.total || 0;

  const handleSelectAll = async () => {
    if (allSelected) {
      setSelection({});
      return;
    }
    const res = await selectAll.mutateAsync({
      organizationId,
      filters,
      sorting,
      period,
    });

    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const fullListSelected = data.length
    ? selectedArray.length === data.length
    : false;

  const hasMoreToSelect = fullListSelected && total > selectedArray.length;

  const allSelected = total > 0 && total === selectedArray.length;

  const statusFilters = useMemo(() => {
    return accountingStatuses
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData?.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  const handleForwardedBills = async () => {
    if (!organization) return;

    await load.mutateAsync(organization.emailAlias);
    remove();
    await refetch();
  };

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={showFilters}
        onClose={setShowFilters}
        title={"Filters"}
        content={
          <SelectBillFilters
            {...{
              ranges: filtersData?.ranges ?? { max: 0, min: 0 },
              setFilters,
              setShowFilters,
              showFilters,
              unCategorized: filtersData?.unCategorized ?? 0,
            }}
          />
        }
      />

      <div className="lg:flex hidden gap-2 items-center mt-4 mb-2">
        <button
          onClick={() =>
            setFilters((p) =>
              p ? { ...p, accountingStatus: null } : { accountingStatus: null }
            )
          }
          className={`btn btn-outline ${
            filters?.accountingStatus ? "" : "btn-primary"
          } `}
        >
          <p>All</p>
          <p>{filtersData?.total}</p>
        </button>
        {statusFilters.map((s) => (
          <button
            onClick={() =>
              setFilters((p) =>
                p
                  ? { ...p, accountingStatus: s.status }
                  : { accountingStatus: s.status }
              )
            }
            className={`btn btn-outline btn-ghost h-full ${
              filters?.accountingStatus !== s.status ? "" : "btn-primary "
            } `}
          >
            <p className="capitalize flex items-center gap-2">
              {formatLabel(s.status)}
              <span className="text-xs">
                ({formatCurrency(s.totalAmount, organization?.defaultCurrency)})
              </span>
            </p>
            <p>{s.totalCount}</p>
          </button>
        ))}
      </div>

      <div role="alert" className="alert shadow-sm py-3 rounded-xl mb-2">
        <PiInfoBold size={26} className="text-info" />

        <div className="card-ui">
          <h3 className="font-bold cursor-pointer card-ui text-sm flex items-center gap-2">
            {organization?.emailAlias.toLowerCase()}
            <button
              onClick={() => copyToClipboard(organization?.emailAlias)}
              className="card-show_details opacity-0"
            >
              <MdOutlineContentCopy size={20} />
            </button>
          </h3>
          <p className="text-xs">Forward your bills here for automatic entry</p>
        </div>
        <button
          onClick={handleForwardedBills}
          className="btn btn-sm btn-neutral"
        >
          <LoadingSpin loading={load.isLoading} />
          Load
        </button>
      </div>

      <div className="flex items-center gap-4 h-12">
        <button
          className={`btn-secondary btn-sm text-xs btn-outline btn ${
            hasMoreToSelect || allSelected ? "" : "hidden"
          } `}
          onClick={handleSelectAll}
        >
          <LoadingSpin loading={selectAll.isLoading} />
          {allSelected ? (
            <p>All {total} bills selected</p>
          ) : (
            <p>Select all {total} bills</p>
          )}
        </button>
      </div>
      <div className="overflow-x-auto overflow-auto h-full">
        <table className="table table-zebra">
          <BillHeaders
            {...{
              setSorting,
              sorting,
              data,
              setSelection,
              selectedArray,
            }}
          />

          <BillsListing
            {...{
              list: data,
              isLoading,
              params,
              selection,
              setSelection,
              totalSelected: selectedArray.length,
            }}
          />
        </table>
        {isLoading && <Spinner />}
        {!isLoading && !data.length && <NotFound title="Bills" />}
        <Pagination {...{ currentPage, setCurrentPage, total: total }} />
      </div>
    </div>
  );
};

export default Bills;
