import Tabs from "@/components/Tabs";
import { useOrganization } from "@/context/OrganizationContext";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import Account from "../swan/Account";
import ManualAccounts from "./ManualAccounts";
import OrganizationBanks from "./OrganizationBanks";

const tabs = ["dynt", "connected", "manual"] as const;

type Tab = (typeof tabs)[number];

const Accounts = () => {
  const { tab = "dynt" } = useParams<{ tab?: Tab }>();
  const { organizationId } = useOrganization<true>();

  const { data: accounts = [], isLoading: accountsLoading } =
    api.manualAccounts.organizationAccounts.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const { data: banks = [], isLoading: banksLoading } =
    api.banks.banks.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const components: Record<Tab, ReactNode> = {
    connected: <OrganizationBanks {...{ banks, isLoading: banksLoading }} />,
    manual: (
      <ManualAccounts
        {...{
          accounts,
          isLoading: accountsLoading,
        }}
      />
    ),
    dynt: <Account />,
  };

  return (
    <div className="">
      <PageTitle title="Accounts" />

      <Tabs
        className="mb-6 z-20 w-fit"
        tabs={tabs}
        tab={tab}
        paths={["/dynt", "/connected", "/manual"]}
        parentRoute="/accounts"
        type="boxed"
      />

      {components[tab]}
    </div>
  );
};

export default Accounts;
