import { useUser } from "@/context/UserContext";
import { GoUnverified } from "react-icons/go";

type Props = {};

const KYCWarning = (props: Props) => {
  const { user } = useUser();
  if (!user?.swanUser) return null;

  const { idVerified, identificationStatus } = user.swanUser;
  if (idVerified) return null;

  return (
    <div className="alert mb-4">
      <p className="label">User Verification:</p>
      <div className="badge badge-error gap-1">
        <GoUnverified size={20} /> {identificationStatus}
      </div>
      <button
        className="btn btn-sm btn-primary"
        onClick={() => {
          window.open(
            "https://oauth.swan.io/oauth2/auth?response_type=code&client_id=LIVE_a9966bb5-1dfe-4b64-b21e-3ebda5b0b234&redirect_uri=https://api.dynt.ai/swan-oauth&scope=openid%20offline%20idverified&state=1234567890",
            "_blank"
          );
        }}
      >
        verify
      </button>
    </div>
  );
};

export default KYCWarning;
