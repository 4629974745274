import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { FC, useEffect, useMemo, useState } from "react";

import { useUser } from "@/context/UserContext";
import { BaseType } from "@/types";
import Checkbox from "@/ui/Checkbox";
import {
  MultiSelect,
  MultiSelectItem,
  SearchSelect,
  SearchSelectItem,
} from "@tremor/react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ExpenseDetails, { CreateExpenseProps } from "../expense/ExpenseDetails";
import { Transaction } from "./SingleTransaction";

type Props = {
  transaction: Transaction;
};

const ConvertToExpense: FC<Props> = ({ transaction }) => {
  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();
  const create = api.expenses.createExpense.useMutation();
  const { data: staffs = [] } = api.organizations.staffs.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: teamMembers = [] } = api.organizations.teamMembers.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: merchants = [], isLoading: mLoading } =
    api.expenses.merchants.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const { data: events = [] } = api.expenses.events.useQuery(organizationId, {
    enabled: !!organizationId,
  });
  const nav = useNavigate();

  const [teamMemberId, setTeamMemberId] = useState<string>();
  const [guestStaff, setGuestStaff] = useState<string[]>([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [merchantName, setMerchantName] = useState("");
  const [merchant, setMerchant] = useState<Partial<BaseType> | null>(null);
  const [externalGuest, setExternalGuest] = useState(false);

  useEffect(() => {
    if (!transaction.merchant || !merchants.length) return;

    const _merchant = merchants.find(
      (m) => m.name === transaction.merchant?.name
    );

    setMerchant(_merchant || { name: transaction.merchant.name });
  }, [transaction.merchant, merchants.length]);

  const teamMembersList = useMemo(() => {
    if (!organization?.role || !userId) return [];
    return teamMembers.filter(
      (s) => organization.role === "admin" || s.user.id === userId
    );
  }, [teamMembers.length, organization?.role, userId]);

  const handleCreate = async ({
    rows,
    customId,
    date,
    currency,
    documents,
  }: CreateExpenseProps) => {
    if (!organizationId || !merchant || !userId || !teamMemberId) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    const expense = await create.mutateAsync({
      userId,
      rows,
      date,
      total: totalAmount + totalVat,
      totalAmount,
      documents,
      totalVat,
      customId,
      currency,
      organizationId,
      guestStaff,
      eventId,
      transactionId: transaction.id,
      merchant,
      team_member_id: teamMemberId,
      externalGuest,
    });

    toast.success("Expense created successfully");

    nav(`/expenses/${expense.id}`);
  };

  return (
    <div className="grid content-start gap-4">
      <div className="">
        <p className="font-semibold mb-2">Choose a Merchant:</p>

        <Select
          isLoading={mLoading}
          options={[{ name: merchantName, id: undefined }, ...merchants]}
          value={merchant}
          onInputChange={(e) => setMerchantName(e)}
          getOptionLabel={(m) => m.name as string}
          onChange={(e) => setMerchant(e)}
          getOptionValue={(m) => m.id || (m.name as string)}
          formatOptionLabel={(m) => (
            <div
              className={`items-center justify-between w-full ${
                m.name ? "flex" : "hidden"
              }`}
            >
              <p
                title={m.name}
                className="flex-wrap text-xs overflow-hidden w-40 font-semibold"
              >
                {m.name}
              </p>

              <div
                className={`rounded-full px-3 py-0.5  text-xs w-fit text-white ${
                  !m.id ? "bg-primary" : "bg-secondary"
                } `}
              >
                {m.id ? "Add" : "Create new"}
              </div>
            </div>
          )}
        />
      </div>

      {!!merchant && (
        <div>
          <p className="font-semibold mb-2">Choose a team member:</p>
          <SearchSelect enableClear={false} onValueChange={setTeamMemberId}>
            {teamMembersList.map((s) => (
              <SearchSelectItem key={s.id} value={s.id}>
                {s.user.name}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        </div>
      )}
      {!!teamMemberId && (
        <>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <p
                className={`font-semibold ${
                  externalGuest ? "opacity-50 pointer-events-none" : ""
                }`}
              >
                Choose guest staffs:
              </p>
              <div className="flex items-center">
                <Checkbox
                  checked={externalGuest}
                  onChange={(c) => {
                    setExternalGuest(c);
                    setGuestStaff([]);
                  }}
                />
                <p className="text-sm">External guest</p>
              </div>
            </div>
            <MultiSelect
              className={externalGuest ? "opacity-50 pointer-events-none" : ""}
              onValueChange={setGuestStaff}
              value={guestStaff}
            >
              {staffs.map((s) => (
                <MultiSelectItem key={s.id} value={s.id}>
                  {s.name}
                </MultiSelectItem>
              ))}
            </MultiSelect>
          </div>
          <div>
            <p className="font-semibold mb-2">Select an event:</p>
            <SearchSelect onValueChange={setEventId}>
              {events.map((s) => (
                <SearchSelectItem key={s.id} value={s.id}>
                  {s.name}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>
          <ExpenseDetails
            {...{
              handleCreate,
              data: {
                date: transaction.date,
                currency: transaction.currency,
              },
              prevRows: {
                [Math.random()]: {
                  amount: Math.abs(transaction.amount),
                  vat: 0,
                  quantity: 1,
                  description: transaction.description,
                },
              },
            }}
          />{" "}
        </>
      )}
    </div>
  );
};

export default ConvertToExpense;
