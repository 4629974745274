import DisplayName from "@/components/DisplayName";
import { useOrganization } from "@/context/OrganizationContext";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, handleSelectFromList } from "@/utils/helper";
import { RouterInputs, RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction } from "react";
import { CgDanger } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import AccountingStatus from "./AccountingStatus";

type Props = {
  list: RouterOutputs["expenses"]["expensesByOrgId"];
  params: RouterInputs["expenses"]["expensesByOrgId"];
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
};

const ExpensesListing: FC<Props> = ({
  list,
  selection,
  setSelection,
  totalSelected,
  params,
}) => {
  const { organization } = useOrganization();

  const nav = useNavigate();

  const view = (id: string) => () => nav(`/expense/${id}`);

  return (
    <tbody>
      {list.map((expense, i, { length }) => (
        <tr key={expense.id} className="hover cursor-pointer ">
          <th className="hidden md:table-cell">
            <Checkbox
              checked={!!selection[expense.id]}
              onChange={() => {
                setSelection(
                  handleSelectFromList(expense, list, totalSelected)
                );
              }}
              className="sm:block hidden -ml-1 mr-2"
            />
          </th>

          <td className="md:hidden" onClick={view(expense.id)}>
            <div className=" flex text-center flex-col">
              <span className="text-sm font-semibold">
                {format(new Date(expense.date), "dd")}
              </span>
              <span className="text-sm text-gray-500  ">
                {format(new Date(expense.date), " MMM")}
              </span>
            </div>
          </td>
          <td onClick={view(expense.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <div className="flex items-center gap-4">
                <DisplayName
                  text={expense.team_member.user.name}
                  className=""
                />
                {organization?.role === "admin" &&
                  expense.approvalStatus === "pending" && (
                    <CgDanger
                      size={20}
                      className="text-yellow-600  rounded-full m-0   w-auto"
                    />
                  )}
              </div>
              <DisplayName
                className="text-xs mt-1"
                text={`@ ${expense.merchant.name}`}
              />
            </div>

            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {expense.status}
            </div>
          </td>
          <td onClick={view(expense.id)}>
            <DisplayName
              className="text-xs w-full text-center"
              text={expense.event?.name || "N/A"}
            />
          </td>
          <td onClick={view(expense.id)}>
            <div className="w-full text-right pr-2">
              <p className="text-right">
                {formatCurrency(expense.total, expense.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(expense.totalVat, expense.currency)}/
                {formatCurrency(expense.totalAmount, expense.currency)}
              </p>
            </div>
          </td>
          <td onClick={view(expense.id)}>
            <p className="flex 2xl:flex-row text-center flex-col w-full  pr-2 gap-1">
              {format(new Date(expense.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(expense.createdAt), "yyyy")}
              </span>
            </p>
          </td>
          <td onClick={view(expense.id)}>
            <p className="flex 2xl:flex-row flex-col  w-full text-center pr-2 gap-1">
              {format(new Date(expense.date), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(expense.date), "yyyy")}
              </span>
            </p>
          </td>
          <td style={{ zIndex: length - i }}>
            <AccountingStatus
              {...{
                params,
                status: expense.accountingStatus,
                expenseId: expense.id,
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default ExpensesListing;
