import { FC, HTMLAttributes, ReactNode } from "react";
import { FaCaretDown } from "react-icons/fa6";

export type DropdownProps = {
  title: ReactNode;
  content?: ReactNode[];
  footer?: ReactNode;
  header?: ReactNode;
  hover?: boolean;
  dropdownClass?: string;
  buttonClass?: string;
  isError?: any;
  open?: boolean;
  hidden?: boolean;
  listClass?: string;
  hideArrow?: boolean;
} & Pick<HTMLAttributes<HTMLDivElement>, "onClick">;

const Dropdown: FC<DropdownProps> = ({
  content = [],
  title,
  hover,
  dropdownClass = "",
  buttonClass,
  listClass = " w-full ",
  isError,
  open,
  footer,
  header,
  hideArrow,
  hidden,
  ...props
}) => {
  if (hidden) return null;
  const handleClick = (e: any) => {
    e.stopPropagation();
    const elem = document.activeElement;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    elem?.blur();
  };

  return (
    <div
      {...props}
      className={`dropdown z-10 ${dropdownClass} ${
        hover ? "dropdown-hover" : ""
      } ${open ? "dropdown-open" : ""} `}
    >
      <div
        tabIndex={0}
        // onClick={(e) => e.stopPropagation()}
        role="button"
        className={`btn z-10 w-full ${buttonClass} ${
          isError ? "btn-error" : ""
        } `}
      >
        <div className="min-w-max">{title}</div>
        {!hideArrow && (
          <>
            <span className="w-2" />
            <button className="bg-inherit z-10 btn btn-xs absolute shadow-none border-none right-0">
              <FaCaretDown size={12} />
            </button>
          </>
        )}
      </div>

      <ul
        className={`dropdown-content min-w-[160px] mx-auto z-10 menu  p-2 shadow bg-base-300 rounded-box gap-1 max-h-64 2xl:max-h-80 grid overflow-auto ${listClass} `}
      >
        {header && <li className="sticky z-20 top-0">{header}</li>}
        {content.filter(Boolean).map((c, i) => (
          <li key={i} onClick={handleClick}>
            {/* <a className="flex">{c}</a> */}
            {c}
          </li>
        ))}
        {footer && (
          <li
            onClick={handleClick}
            className="sticky bg-base-300 z-10 -bottom-2"
          >
            <a className="flex justify-center">{footer}</a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Dropdown;
