import { months } from "@/lib";
import { RouterOutputs } from "@/utils/trpc";
import { AreaChart } from "@tremor/react";
import { ChartDataCustomTypesPerDataset, ChartOptions } from "chart.js";
import { FC } from "react";
import NotFound from "../utils/notfound";

export const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    y: {
      ticks: {
        callback(tickValue) {
          return tickValue + "%";
        },
      },
      type: "linear",
      display: true,
      position: "left",
      grid: { display: false },
    },
  },
};

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["merchantCompletion"];
};
const MerchantCompletion: FC<Props> = ({ data }) => {
  if (!data) return <NotFound title="Data" />;

  const barData: ChartDataCustomTypesPerDataset<"bar" | "line"> = {
    labels: months.map((m) => m.name),
    datasets: [
      {
        label: "Merchant Completion percentage",
        data: months.map((m) => data[m.name]),
        backgroundColor: "orange",
        type: "line",
        yAxisID: "y",
        borderColor: "orange",
      },
    ],
  };

  return (
    <div className="w-full" style={{ height: "400px" }}>
      <AreaChart
        data={months
          .map((d) => {
            return {
              date: d.name,
              "Merchant Completion Percentage": data[d.name],
            };
          })
          .filter((d) => d["Merchant Completion Percentage"])}
        index="date"
        categories={["Merchant Completion Percentage"]}
        valueFormatter={(v) => `%${v}`}
        colors={["indigo"]}
      />
    </div>
  );
};

export default MerchantCompletion;
