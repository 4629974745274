import { FC, ReactNode, useEffect, useState } from "react";

type Props = {
  children?: ReactNode;
  hide?: boolean;
  title?: string;
  header?: ReactNode;
  className?: string;
  unMountOnClose?: boolean;
  defaultOpen?: boolean;
  render?: (toggle: (a?: boolean) => void, show: boolean) => ReactNode;
  hideArrow?: boolean;
};

const Collapsible: FC<Props> = ({
  children,
  title,
  header,
  className,
  unMountOnClose,
  defaultOpen = false,
  render = () => <></>,
  hide,
  hideArrow,
}) => {
  const [show, setShow] = useState(defaultOpen);

  useEffect(() => {
    setShow(defaultOpen);
  }, [defaultOpen]);

  if (hide) return null;

  return (
    <div
      className={`collapse border-base-300 bg-base-200 ${
        hideArrow ? "" : "collapse-arrow"
      } ${className} ${show ? "overflow-visible" : ""} `}
    >
      <input
        type="checkbox"
        checked={show}
        onChange={(e) => setShow(e.target.checked)}
      />
      <div className="collapse-title font-medium">
        {header ? (
          header
        ) : (
          <p className={show ? "font-bold text-primary" : ""}>{title}</p>
        )}
      </div>
      <div className="collapse-content overflow-visible">
        {!unMountOnClose ? children : show && children}
        {render((a) => setShow((p) => a || !p), show)}
      </div>
    </div>
  );
};

export default Collapsible;
