import SelectAccountingStatus from "@/components/SelectAccountingStatus";
import { useOrganization } from "@/context/OrganizationContext";
import { Enums } from "@/types";
import { api } from "@/utils/trpc";
import { FC } from "react";

type Props = {
  status: Enums["AccountingStatus"];
  transactionId: string;
};

const ManageAccountingStatus: FC<Props> = ({ status, transactionId }) => {
  const update = api.swan.transfers.accountingStatus.update.useMutation();
  const utils = api.useUtils();
  const { organizationId } = useOrganization<true>();

  const handleUpdate = async (status: Enums["AccountingStatus"]) => {
    const res = await update.mutateAsync({
      transactionId,
      accountingStatus: status,
    });

    utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
      if (!p) return p;
      return {
        ...p,
        pages: p.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((t) => {
              if (t.id === transactionId) return { ...t, ...res };
              return t;
            }),
          };
        }),
      };
    });
  };

  return (
    <SelectAccountingStatus
      {...{ status, setStatus: handleUpdate, isLoading: update.isLoading }}
    />
  );
};

export default ManageAccountingStatus;
