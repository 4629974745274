import Drawer from "@/components/drawer/Drawer";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import Pagination from "@/ui/Pagination";
import { formatCurrency, formatLabel } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { DatePeriod } from "../Accounting";
import SelectExpenseFilters from "./ExpenseFilters";
import ExpenseHeaders, { Sorting } from "./ExpenseHeaders";
import ExpensesListing from "./ExpenseListing";

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
};

export type ExpenseFilter =
  RouterInputs["expenses"]["expensesByOrgId"]["filters"];

const OrgExpenses: FC<Props> = ({
  setShowFilters,
  showFilters,
  selection,
  setSelection,
  period,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();

  const [filters, setFilters] = useState<ExpenseFilter>(null);
  const selectAll = api.expenses.selectAllExpenses.useMutation();
  const deleteExpenses = api.expenses.deleteExpenses.useMutation();

  const selectedArray: string[] = useMemo(
    () => Object.values(selection).map((a) => a.id),
    [selection]
  );

  const [sorting, setSorting] = useState<Sorting>({
    createdAt: "desc",
  });

  const params = {
    organizationId,
    currentPage,
    filters,
    sorting,
    userId,
    period,
  };

  const { data = [], isLoading } = api.expenses.expensesByOrgId.useQuery(
    params,
    { enabled: !!organizationId }
  );

  const { data: filtersData } = api.expenses.filtersData.useQuery(
    { organizationId, filters, period, userId, groupBy: "accountingStatus" },
    { enabled: !!organizationId }
  );

  const total = filtersData?.total ?? 0;

  const handleSelectAll = async () => {
    if (allSelected) {
      setSelection({});
      return;
    }
    const res = await selectAll.mutateAsync({
      organizationId,
      filters,
      sorting,
      period,
    });

    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const fullListSelected = data.length
    ? selectedArray.length === data.length
    : false;

  const hasMoreToSelect = fullListSelected && total > selectedArray.length;

  const allSelected = total > 0 && total === selectedArray.length;

  const statusFilters = useMemo(() => {
    return accountingStatuses
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData?.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={showFilters}
        onClose={setShowFilters}
        title={"Filters"}
        content={
          <SelectExpenseFilters
            {...{
              ranges: filtersData?.ranges ?? { max: 0, min: 0 },
              setFilters,
              setShowFilters,
              showFilters,
              unCategorized: filtersData?.unCategorized ?? 0,
            }}
          />
        }
      />

      <div className="lg:flex hidden gap-2 items-center my-4">
        <button
          onClick={() =>
            setFilters((p) =>
              p ? { ...p, accountingStatus: null } : { accountingStatus: null }
            )
          }
          className={`btn btn-outline ${
            filters?.accountingStatus ? "" : "btn-primary"
          } `}
        >
          <p>All</p>
          <p>{filtersData?.total}</p>
        </button>
        {statusFilters.map((s) => (
          <button
            onClick={() =>
              setFilters((p) =>
                p
                  ? { ...p, accountingStatus: s.status }
                  : { accountingStatus: s.status }
              )
            }
            className={`btn btn-outline btn-ghost h-full ${
              filters?.accountingStatus !== s.status ? "" : "btn-primary "
            } `}
          >
            <p className="capitalize flex items-center gap-2">
              {formatLabel(s.status)}
              <span className="text-xs">
                ({formatCurrency(s.totalAmount, organization?.defaultCurrency)})
              </span>
            </p>
            <p>{s.totalCount}</p>
          </button>
        ))}
      </div>
      <div className="flex items-center gap-4 h-12">
        {!allSelected && (
          <button
            className={`btn-secondary btn-sm text-xs btn-outline btn ${
              hasMoreToSelect || allSelected ? "" : "hidden"
            } `}
            onClick={handleSelectAll}
          >
            <LoadingSpin loading={selectAll.isLoading} />
            <p>Select all {total} expenses</p>
          </button>
        )}
      </div>

      <div className="overflow-x-auto overflow-auto h-full">
        <table className="table table-zebra">
          <ExpenseHeaders
            {...{
              setSorting,
              sorting,
              data: data,
              setSelection,
              selectedArray,
            }}
          />

          <ExpensesListing
            {...{
              list: data,
              params,
              isLoading,
              selection,
              setSelection,
              totalSelected: selectedArray.length,
            }}
          />
        </table>
        {isLoading && <Spinner />}
        {!isLoading && !data.length && <NotFound title="expenses" />}
        <Pagination {...{ currentPage, setCurrentPage, total: total }} />
      </div>
    </div>
  );
};

export default OrgExpenses;
