import { ReactNode, useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import Dropdown from "@/components/Dropdown";
import { PageTitle } from "@/ui/PageTitle";
import { GiBuyCard, GiSellCard } from "react-icons/gi";
import { HiPlus } from "react-icons/hi";
import Customers from "../customers/customers";
import Vendors from "../vendors/Vendors";

const tabs = [
  {
    name: "vendors" as const,
    icon: <GiBuyCard />,
  },
  {
    name: "customers" as const,
    icon: <GiSellCard />,
  },
];

type Tab = (typeof tabs)[number]["name"];

const routes: Record<Tab, string> = {
  vendors: "&modal=createVendor",
  customers: "&modal=createCustomer",
};

const Partners = () => {
  window.document.title = "Dynt | Partners";

  const l = useLocation();
  const nav = useNavigate();

  const [tab, setTab] = useState<Tab>("customers");

  const content: Record<Tab, ReactNode> = {
    customers: <Customers />,
    vendors: <Vendors />,
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(l.search);

    const _tab = queryParams.get("tab") as Tab;

    if (["vendors", "customers"].includes(_tab)) setTab(_tab);
    else nav("/partners?tab=vendors");
  }, [l.search]);

  return (
    <div className="">
      <PageTitle title="Partners" />

      <div className="flex gap-6 items-center justify-between">
        <div className="md:flex hidden gap-3 items-center">
          {tabs.map((t) => (
            <Link
              to={`/partners?tab=${t.name}`}
              key={t.name}
              className={`btn capitalize ${t.name == tab ? "btn-primary" : ""}`}
            >
              {t.icon}
              {t.name}
            </Link>
          ))}
        </div>

        <Dropdown
          dropdownClass="w-full max-w-sm z-50 md:hidden"
          title={tab}
          buttonClass="capitalize"
          content={tabs.map((t) => (
            <button
              className="capitalize"
              onClick={() => nav(`/partners?tab=${t.name}`)}
              onTouchEnd={() => nav(`/partners?tab=${t.name}`)}
              key={t.name}
            >
              {t.name}
            </button>
          ))}
        />

        <Link
          to={l.pathname + l.search + routes[tab]}
          className="btn btn-primary"
        >
          <HiPlus />
        </Link>
      </div>
      {content[tab]}
    </div>
  );
};

export default Partners;
