import { months } from "@/lib";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { AreaChart } from "@tremor/react";
import addDays from "date-fns/addDays";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import { FC } from "react";
import NotFound from "../utils/notfound";
import Spinner from "../utils/spinner";

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["balances"];
  currency: string;
  loading: boolean;
  year: number;
};

const Balances: FC<Props> = ({ data, currency, loading, year }) => {
  if (loading) return <Spinner />;
  if (!data) return <NotFound title="Data" />;

  console.log(data);
  return (
    <div className="mt-4 " style={{ width: "100%", height: "400px" }}>
      <AreaChart
        data={months
          .map((d) => {
            const date = endOfMonth(new Date(year, d.value, 1));

            let balance = 0;

            for (let i = 0; i < date.getDate(); i++) {
              const _date = addDays(date, -i);
              const _dateIndex = format(_date, "dd/MM/yyy");
              if (!data[_dateIndex]) continue;
              balance = data[_dateIndex];
              break;
            }

            return {
              date: format(date, "MMM"),
              "close balance": balance,
            };
          })
          .filter((d) => d["close balance"] !== 0)}
        index="date"
        categories={["close balance"]}
        valueFormatter={(v) => formatCurrency(v, currency)}
        colors={["indigo"]}
      />
    </div>
  );
};

export default Balances;
