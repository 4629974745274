import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";

type Categories = RouterOutputs["organizations"]["categories"];

type Props = {
  subCategory: RouterOutputs["accounting"]["categories"][number]["subCategories"][number];
  subCategories: RouterOutputs["organizations"]["categories"][number]["subCategories"];
  isRegular: boolean;
};

const MapSubCategories: FC<Props> = ({
  subCategories,
  subCategory,
  isRegular,
}) => {
  const map = api.accounting.mapSubCategories.useMutation();
  const unMap = api.accounting.unMapSubCategory.useMutation();

  const [mapping, setMapping] = useState(false);
  const utils = api.useUtils();
  const { organization } = useOrganization();

  const [mappedCategories, setMappedCategories] = useState<
    Record<string, boolean>
  >({});

  if (!organization?.accountingApp) return;

  const handleMap = async () => {
    if (!organization?.accountingApp) return;

    const res = await map.mutateAsync({
      subCategories: Object.keys(mappedCategories),
      subCategoryId: subCategory.id,
    });

    const setMap = (p?: Categories): Categories | undefined => {
      if (!p) return p;
      return p.map((c) => ({
        ...c,
        subCategories: c.subCategories.map((s) => {
          if (s.id === subCategory.id) {
            return { ...s, mappedTo: res.mappedTo };
          }
          return s;
        }),
      }));
    };

    utils.organizations.categories.setData(organization.id, setMap);
    utils.accounting.categories.setData(organization.accountingApp.id, setMap);

    setMappedCategories({});
    setMapping(false);
  };

  const handleUnMap = (id: string) => async () => {
    if (!organization?.accountingApp) return;

    const res = await unMap.mutateAsync({
      categoryA: subCategory.id,
      categoryB: id,
    });

    const setMap = (p?: Categories): Categories | undefined => {
      if (!p) return p;
      return p.map((c) => ({
        ...c,
        subCategories: c.subCategories.map((s) => {
          if (s.id === subCategory.id) {
            return { ...s, mappedTo: res.mappedTo };
          }
          return s;
        }),
      }));
    };

    utils.organizations.categories.setData(organization.id, setMap);
    utils.accounting.categories.setData(organization.accountingApp.id, setMap);
  };

  const mapped = Object.fromEntries(
    subCategory.mappedTo.map((c) => [c.id, true])
  );

  return (
    <div className="my-4 z-30">
      <p className="label">
        From {isRegular ? "Dynt" : organization.accountingApp.name}
      </p>

      <div className="flex items-center gap-2">
        {subCategory.mappedTo.map((c) => (
          <div
            key={c.id}
            className="badge status-item badge-neutral gap-2 p-4 overflow-hidden"
          >
            <span
              style={{ background: c.color }}
              className="w-4 h-4 rounded-full"
            />
            {c.name}

            <div className="max-w-0 overflow-hidden transition-all  status-item_json">
              <button
                onClick={handleUnMap(c.id)}
                className="btn btn-outline btn-circle btn-xs btn-error"
              >
                <LoadingSpin loading={unMap.isLoading} />
                <MdClose />
              </button>
            </div>
          </div>
        ))}

        {!subCategory.mappedTo.length && (
          <div className="badge status-item badge-neutral gap-2 p-4 overflow-hidden">
            N/A
          </div>
        )}
        <button
          onClick={() => setMapping(true)}
          className="btn btn-circle btn-secondary btn-xs"
        >
          <HiPlus />
        </button>
      </div>
      <Drawer title="Map categories" isOpen={mapping} onClose={setMapping}>
        <div className="grid gap-3">
          {subCategories
            .filter((c) => !mapped[c.id])
            .map((c) => (
              <button
                onClick={() => {
                  if (!mappedCategories[c.id]) {
                    setMappedCategories({ ...mappedCategories, [c.id]: true });
                  } else {
                    const { [c.id]: _, ...rest } = mappedCategories;
                    setMappedCategories(rest);
                  }
                }}
                key={c.id}
                className={`btn btn-sm justify-start ${
                  mappedCategories[c.id] ? "btn-neutral" : ""
                }`}
              >
                <span
                  className="w-4 h-4 rounded-full"
                  style={{ background: c.color }}
                />
                {c.name}
              </button>
            ))}
          <button onClick={handleMap} className="btn mt-4 btn-primary">
            <LoadingSpin loading={map.isLoading} />
            Done
          </button>
        </div>
      </Drawer>

      <p className="label label-text-alt">
        {subCategories.length} categories available
      </p>
    </div>
  );
};

export default MapSubCategories;
